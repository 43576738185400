/** @jsx jsx */
import { FC } from 'react';
import { jsx } from 'theme-ui';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumbs';

const ProductTopMenu: FC<any> = (props) => {
	let breadcrumbs = props?.breadcrumbs;

    return (
		<Breadcrumbs breadcrumbs={breadcrumbs} styles={styles} />
    );
};

const styles = {
	breadcrumbs: {
		marginLeft: 0,
		'.breadcrumb-item a': {
			textDecoration: 'none',
			fontSize: '14px',
			color: '#656766',
			fontWeight: 500,
		},
		'.breadcrumb-item+.breadcrumb-item::before':
		{
			color: '#685f52'
		},
		'.disabled': {
			color: '#00d4c5!important',
			fontWeight: 'unset!important'
		}
	}
};

export default ProductTopMenu
