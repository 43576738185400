import { getPastDate } from "./datetime-helper";
import { twoDecimalPlaces } from "./number-helper";

/**
   * @description Returns formatted price for given string to two decimal places, with currency symbol
 */
export const formatPrice = (price: number) => {
  const formattedPrice = new Intl.NumberFormat(undefined, {
    currency: "GBP",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(price || 0);

  return formattedPrice;
};


/**
  * @description Returns the VAT price applied to a given price
*/
export const getAppliedVatPrice = (price: number, vatRate: number): number => {
  return price * (vatRate / 100);
}
/**
 * Calculates the price including VAT given a price and a VAT rate.
 * @param price - The price to apply VAT to.
 * @param vatRate - The VAT rate as a percentage (e.g., "20").
 * @returns The price including VAT, rounded to 2 decimal places.
 */
export const applyVAT = (price: number, vatRate: string): number => {
  const rate = parseFloat(vatRate);

  if (isNaN(rate)) {
    throw new RangeError("VAT rate is not a valid number");
  }

  const total = price * (1 + rate / 100);
  
  // Round to the nearest penny (or the smallest currency unit)
  const roundedTotal = Math.round(total * 100) / 100;

  return roundedTotal;
};

/**
   * @description Returns boolean value to specify if product is on sale
 */
export const isOnSale = (salePrice: number, price: number): boolean => {
  if (!salePrice || salePrice === 0 || !price) return false;

  return salePrice < price;
}

/**
   * @description Returns boolean value to specify if product has been published within a given timeframe
 */
export const isPublishedWithinRange = (publicationDate: string, daysAgo: number) => {
  return publicationDate > getPastDate(daysAgo);
}
