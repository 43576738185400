import { Link } from 'gatsby';
import React from 'react';
import { Box } from 'theme-ui';
import defaultStyles from './breadcrumbs.style';

export interface Breadcrumb {
    label: string,
    pathname: string
}

const Breadcrumbs: React.FunctionComponent<{ breadcrumbs: Breadcrumb[], styles: any, offset?: number, fetch?: number }> = ({ breadcrumbs, styles, offset, fetch }) => {
    if (offset !== undefined && offset > 0) {
        breadcrumbs = breadcrumbs.splice(offset, fetch);
    }

    const isLast = (index: number) => {
        return index === breadcrumbs.length - 1;
    }
    return (
        <Box as="ul" sx={styles?.breadcrumbs ?? defaultStyles.breadcrumbs} className="breadcrumb" >
        {
            breadcrumbs.map((crumb: Breadcrumb, index: number) => {
                const disabled = isLast(index) ? 'disabled' : '';

                return (
                    <Box as="li" key={index} className="breadcrumb-item">
                        <Link to={crumb.pathname} className={`${disabled}`}>{crumb.label}</Link>
                    </Box>
                );
            })
        }
        </Box>
    );
}

export default Breadcrumbs;