/** @jsx jsx */
import React, { useEffect } from "react";
import { jsx } from "theme-ui";
import { GoogleMap, Marker } from "react-google-maps";

type Props = {
  geo?: Geo;
  placeId?: string;
  zoom?: number;
};

type Geo = {
  lat: number;
  lng: number;
};

const GoogleMapsLocationWrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    if (!props.geo) {
      console.log("No place passed");
    }
  });

  return (
    <GoogleMap
      id="map"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
      }}
      containerStyle={{
        height: "500px",
        overflow: "hidden",
        position: "relative",
      }}
      defaultZoom={props.zoom ?? 13}
      defaultCenter={props.geo}
    >
      <Marker position={ props.geo } />
    </GoogleMap>
  );
};

export default GoogleMapsLocationWrapper;
