import React from 'react';
import Wrapper from './container.style';

type Props = {
	fluid?: boolean;
	className?: string;
	paddingX?: string;
};

const Container: React.FunctionComponent<Props> = ({
	fluid,
	className,
	paddingX,
	children,
}) => {
	const classNames = [];
	if (fluid) {
		classNames.push('fluid');
	}
	if (className) {
		classNames.push(className);
	}
	// Fluid was passed to the wrapper class but this had no effect and caused a console error so I have removed 
	// the navbar and primary are the only components using this
	return (
		<Wrapper className={classNames.join(' ')} paddingX={paddingX}>
			{children}
		</Wrapper>
	);
};

export default Container;
