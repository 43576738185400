/** @jsx jsx */
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, jsx } from "theme-ui";
import { formatBlogDate, getLatestRandomPosts } from "../helper/blog-helper";

const LatestBlogPosts = (props: any) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicBlog( sort: {data: {date: DESC }}) {
        totalCount
        nodes {
          uid
          data {
            title
            category
            date
            author
            blog_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            tags {
              blog_tags {
                document {
                  ... on PrismicBlogTag {
                    data {
                      name
                    }
                  }
                }
              }
            }
            meta_description
          }
        }
      }
    }
  `);

  let blogs = data.allPrismicBlog.nodes;
  blogs = blogs.map((blog: any) => {
    blog.data = formatBlogDate(blog.data);
    return blog;
  });

  if (props.filterByAuthor) {
    blogs = blogs.filter(
      ({ data }: any) => data.author.person.name === props.filterByAuthor
    );
  }
  if (props.limitAmount) {
    blogs = blogs.slice(0, props.limitAmount);
  }
  if (props.returnRandom) {
    blogs = getLatestRandomPosts(blogs);
  }
  return (
    <Box sx={styles.recentPosts}>
      <div className="header">{props.title}</div>
      <div className="body">
        {blogs.map((node: any, index: number) => (
          <div key={index} className="container">
            <div className="row">
              <div className="col-md-5">
                <Link to={"/blog/" + node.uid}>
                  <GatsbyImage
                    image={node.data.blog_image.gatsbyImageData}
                    alt={node.data.blog_image.alt ?? ""}
                  />
                </Link>
              </div>

              <div className="col-md-6">
                <h3>
                  <Link to={"/blog/" + node.uid}>{node.data.title}</Link>
                </h3>
                <span className="date">{node.data.date}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

const styles = {
  recentPosts: {
    ".header": {
      width: "100%",
      padding: "15px 17px",
      background: "#00d4c5",
      color: "#fff",
      borderRadius: "5px 5px 0 0",
      fontSize: "14px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    ".body": {
      background: "white",
      border: "1px solid #E4E4E4",
      borderTop: 0,
      borderRadius: "0 0 5px 5px",
    },
    ".container": {
      paddingLeft: 10,
      paddingRight: 10,
    },
    ".row": {
      padding: "10px",
    },
    a: {
      textDecoration: "none",
    },
    h3: {
      fontWeight: 600,
      fontSize: "14px!important",
      color: "#685f52",
    },
    ".date": {
      color: "#9f9f9f",
      fontStyle: "italic",
      fontSize: "14px",
    },
    img: {
      borderRadius: "12px",
    },
  },
};

export default LatestBlogPosts;
