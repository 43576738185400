/** @jsx jsx */
import { useLocation } from "@reach/router";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import React, { FC, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { BiCookie } from "react-icons/bi";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";
import { Box, jsx, Button } from "theme-ui";
import BreadcrumbBanner from "../../../components/breadcrumb-banner/breadcrumb-banner";
import { Breadcrumb } from "../../../components/breadcrumbs/breadcrumbs";
import GoogleMapsLocationWrapper from "../../../components/google-maps-location-wrapper";
import HomeBanner from "../../../components/home-banner/home-banner";
import TopMenu, { TopMenuStyle } from "../../../components/top-menus/top-menu";
import Container from "../../container/container";
import Footer from "./footer/footer";
import Header from "./header/header";
import styles from "./primary.style";
import "../../../css/style.css";
import { getCountry } from "../../../helper/geo-helper";
import { getSessionItem, setSessionItem } from '../../../helper/session-storage';
import Modal from "react-bootstrap/Modal";

const WrappedMap = withScriptjs(withGoogleMap(GoogleMapsLocationWrapper));

export interface Coordinates {
  latitude?: number;
  longitude?: number;
}

const getCoordinates = (mapCoordinates?: Coordinates): Coordinates => {
  const liverpool: Coordinates = {
    latitude: 53.40303,
    longitude: -2.9904,
  };

  if (
    !mapCoordinates ||
    !mapCoordinates?.latitude ||
    !mapCoordinates?.longitude
  )
    return liverpool;

  return mapCoordinates;
};

const PrimaryLayout: React.FunctionComponent<{
  fluid?: boolean;
  paddingX?: string;
  bgColor?: string;
  homeLink?: string;
  pathPrefix?: string;
  showBanner?: boolean;
  topMenuStyle?: TopMenuStyle;
  topMenuChild?: FC<any>;
  showBackground?: boolean;
  breadcrumbTitle?: string;
  breadcrumbSubTitle?: string;
  breadcrumbBreadcrumbs?: Breadcrumb[];
  breadcrumbBgImage?: string;
  showMap?: boolean;
  mapCoordinates?: Coordinates;
  showScrollUpButton?: Boolean;
  promotionBanner?: any;
  location?: any;
}> = ({
  fluid,
  paddingX,
  bgColor,
  homeLink,
  pathPrefix,
  showBanner,
  topMenuStyle,
  topMenuChild,
  showBackground,
  breadcrumbTitle,
  breadcrumbSubTitle,
  breadcrumbBreadcrumbs,
  breadcrumbBgImage,
  showMap,
  mapCoordinates,
  children,
  showScrollUpButton = true,
  promotionBanner,
}) => {
    const [cartBar, setCartBar] = useState(false);
    const cartBtnProps = useSpring({
      config: { mass: 1, tension: 500, friction: 48 },
      bottom: cartBar ? 87 : 30,
    });
    const location = useLocation();
    const coordinates = getCoordinates(mapCoordinates);

    const internationalProductsOnly = getSessionItem("InternationProductsOnly");
    const [showInternationProductsModal, setShowInternationProductsModal] = useState(false);

    const handleUKCustomer = () => {
      setSessionItem("InternationProductsOnly", false);
      setShowInternationProductsModal(false);
    }

    const handleInternationalCustomer = () => {
      setSessionItem("InternationProductsOnly", true);
      setShowInternationProductsModal(false);
    }

    const handleCloseResultsModal = () => {
      setShowInternationProductsModal(true);
  }

    useEffect(() => {
      const setCountryData = async () => {

        if (internationalProductsOnly === undefined) {
          const country = await getCountry();

          if (country !== "GB") {
            setShowInternationProductsModal(true);
          }  else {
            handleUKCustomer();
          }
        }
      }

      setCountryData()
    });

    return (
      <Box
        as="main"
        sx={{
          backgroundColor: bgColor ? bgColor : "transparent",
          ...styles.main
        }}
        className={`${showBackground ? "section-background" : ""}`}
      >
        <Header fluid={false} homeLink={homeLink} pathPrefix={pathPrefix} />

          <Modal show={showInternationProductsModal} onHide={handleCloseResultsModal}>
            <Modal.Body>
              We have noticed you are an international user outside of the UK therefore you will only be able to purchase products available in your country
            </Modal.Body>
            <Modal.Footer>
              <Button sx={styles.button} onClick={handleInternationalCustomer}>OK</Button>
              <Button onClick={handleUKCustomer} sx={styles.buttonLink}>
                I am buying in the UK
              </Button>
            </Modal.Footer>
          </Modal>

        {topMenuChild && (
          <TopMenu topMenuStyle={topMenuStyle}>{topMenuChild({})}</TopMenu>
        )}

        {showBanner && 
          <HomeBanner />
        }
        {breadcrumbTitle !== undefined && (
          <BreadcrumbBanner
            title={breadcrumbTitle}
            bgImage={breadcrumbBgImage}
            subTitle={breadcrumbSubTitle}
            breadcrumbs={breadcrumbBreadcrumbs}
          />
        )}

        <Container fluid={fluid} paddingX={paddingX}>{children}</Container>
        <Waypoint
          onEnter={() => setCartBar(true)}
          onLeave={() => setCartBar(false)}
        />
        <animated.div
          style={{ ...cartBtnProps, position: "relative", zIndex: 9999 }}
        ></animated.div>



        {showMap && (
          <Box id="map">
            <WrappedMap
              zoom={13}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: "500px", marginTop: "-200px" }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              geo={{ lat: coordinates.latitude, lng: coordinates.longitude }}
            />
          </Box>
        )}

        <Footer promotionBanner={false} />
        <CookieConsent
          buttonText="Accept Cookies"
          disableButtonStyles={true}
          enableDeclineButton
          declineButtonText="Decline Cookies"
          declineButtonClasses="cookie-button-decline"
          style={{
            color: "#2E3E30",
            borderTop: "#808080 solid .5px",
            borderLeft: "60px solid #00d4c5",
            background: "#F5F5F5",
            justifyContent: "center",
            fontWeight: 400,
          }}
          buttonClasses="cookie-button"
          onAccept={() => {
            initializeAndTrack(location);
          }}
          cookieName="gatsby-gdpr-google-analytics"
        >
          <BiCookie sx={styles.cookieConsent.cookieIcon} />
          Cookies are used on this website to improve your user experience. Take a
          look at our website{" "}
          <Link to="/privacy-policy" sx={styles.cookieConsent.privacyPolicyLink}>
            Privacy policy
          </Link>{" "}
          for more info.
        </CookieConsent>
      </Box>
    );
  };

export default PrimaryLayout;