const styles = {
	breadcrumbs: {
		'.breadcrumb-item a': {
			textDecoration: 'none',
			fontSize: '15px',
			'@media only screen and (max-width: 400px)': {
				fontSize: '13px'
			},
			color: '#685f52',
			fontWeight: 500,
		},
		'.breadcrumb-item+.breadcrumb-item::before':
		{
			color: '#685f52'
		},
		'.disabled': {
			color: '#00d4c5!important',
			fontWeight: 'unset!important'
		}
	}
};

export default styles;
