export const getSessionItem = (key: string) => {

    if (typeof window !== 'undefined') {
        const itemKey = sessionStorage.getItem(key);
        if (itemKey === null) {
            return undefined;
        }
        return itemKey;
    }
};

export const setSessionItem = (key: string, value: any) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
};