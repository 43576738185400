const styles = {
	footer: {
		row: {
			marginTop: '-34px'
		},
		backgroundColor: '#23b3a3',
		boxShadow: '0 -5px 4px #23b3a3',
		transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s',
		backgroundSize: 'contain',
		backgroundPosition: 'bottom',
		section: {
			borderStyle: 'solid',
			borderWidth: '0 1px 0 0',
			borderColor: '#51baac',
			transition: 'background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s',
			padding: '40px 30px 60px 30px',	
			hr: {
				opacity: '.1',
				color: 'white'
			}, 
			'@media only screen and (max-width: 767px)': {
				padding: '20px 0px 0 20px'
			},
		},
		h2: {
			color: 'white',
			fontWeight: '700',
			textTransform: 'uppercase',
			lineHeight: '20px',
			fontSize: '16px',
			paddingBottom: 10
		},
		location: {
			color: 'white',
			fontSize: '15px!important',
			paddingTop: '5px',
			info: {
				listStyle: 'none',
				color: 'white',
				padding: 0,
				marginTop: 20,
				li: {
					padding: '5px 5px 5px 0',
					a: {
						fontWeight: '400',
						fontSize: '15px!important',
						color: 'white',
						textDecoration: 'none',
						paddingLeft: 15,
						'@media only screen and (min-width: 768px) and (max-width: 1227px)': {
							paddingLeft: 0
						},
					}
				}
			},
		},
		infomation: {
			listStyle: 'none',
			color: 'white',
			fontSzie: '15px!important',
			padding: 0,
			li: {
				padding: '5px 5px 5px 0',
				a: {
					fontWeight: '400',
					fontSize: '15px!important',
					color: 'white',
					textDecoration: 'none',
				}
			}
		},
		'@media only screen and (max-width: 1825px)': {
			backgroundRepeat: 'no-repeat',
		},
	},
	bottomBar: {
		backgroundColor: '#F5F5F5',
		copyright: {
			color: '#685f52',
			padding: '20px',
			fontSize: '15px!important',
			fontWeight: '400',
			lineHeight: '24px'
		},
		'@media only screen and (max-width: 767px)': {
			marginTop: '0 auto',
			textAlign: 'center'
		},
	},
	socialWrapper: {
		display: 'flex',
		float: 'right',
		marginTop: '1em',
		marginBottom: 0,
		a: {
			color: 'white !important',
		},
		'@media only screen and (max-width: 767px)': {
		    float: 'unset',
			margin: '0 auto',
			display: 'table',
			marginTop: '-10px',
			marginBottom: '10px'
		},
	},
	newsletter: {
		top: '-35px',
		position: 'relative',
		background: '#4D8052',
		borderRadius: '50px',
		padding: '18px',
		h1: {
			color: '#FFF',
			fontSize: '18px',
			fontWeight: '600',
			lineHeight: '32px',
			textTransform: 'uppercase',
			top: '50%',
			left: '80px',
			position: 'absolute',
			transform: 'translatey(-50%)',
			'@media only screen and (max-width: 767px)': {
				top: '27%',
			},
			'@media only screen and (max-width: 388px)': {
				fontSize: '17px',
			},
		},
		icon: {
			padding: '5px',
			backgroundColor: 'white',
			width: '48px',
			height: '48px',
			borderRadius: '50%',
			color: 'white',
			'@media only screen and (max-width: 767px)': {
				width: '40px',
				height: '40px',
				margin: '5px 0 0 6px'
			},
		},
		input: {
			flex: '1',
			padding: '11.5px 20px',
			marginRight: '8px',
			borderRadius: '2em',
			border: '0',
			width: '100%',
			'&:focus': {
				outline: 'none',
			}
		},
		button: {
			fontSize: '15px!important',
			color: 'white',
			fontWeight: '600',
			padding: '12.5px 25px',
			borderRadius: '2em',
			background: '#00d4c5',
			maxWidth: '150px'
		},
		headerWrapper: {
			display: 'flex'
		},
		inputWrapper: {
			display: 'flex',
			'@media only screen and (max-width: 767px)': {
				marginTop: '20px'
			},
		},
		
	},
	outible: {
		background: '#23b3a3',
		textAlign: "center",
		url: {
			margin: "0",
			fontSize: "12px!important",
			padding: "10px",
			textDecoration: 'none',
			a: {
				color: 'white'
			}
		}
	},
	iconWrapper: {
		padding: '5px',
		backgroundColor: '#F5F5F5',
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		color: '#685f52',
		'@media only screen and (min-width: 768px) and (max-width: 1227px)': {
			display: 'none'
		},
	}
}

export default styles;
