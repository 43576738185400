const styles = {
	container: {
		'@media only screen and (max-width: 1000px)': {
			maxWidth: '100%!important',
			margin: '0!important'
		},
	},
	thumnailImages: {
		padding: 0,
		display: 'flex',
		marginTop: 20,
		marginBottom: 20,
		listStyle: 'none',
		li: {
			paddingRight: 10
		}
	},
	thumbnailWrapper: {
		background: '#f5f5f5',
		borderRadius: 10,
		height: 100,
		width: 100,
		display: 'table-cell',
		verticalAlign: 'middle',
		border: '1.5px solid #e4e4e4',
		'&:hover': {
			border: '1.5px solid #685f52',
		},
		img: {
			width: '100%',
			padding: '8px',
		},
		'@media only screen and (max-width: 536px)': {
			height: 60,
		},
	},
	wrapper: {
		'#relatedProducts': {
			paddingLeft: 0,
			paddingRight: 0,
			marginTop: '20px',
		},
		marginTop: '40px',
	},
	product: {
		width: '100%',
		borderRadius: '12px',
		background: 'red',
		'@media only screen and (max-width: 768px)': {
			flexDirection: 'column',
		},
	},
	image: {
		width: '100%',
		display: 'block',
		flexWrap: 'wrap',
		border: `0`,
		'@media only screen and (max-width: 1280px)': {
			width: '60%',
		},
		'@media only screen and (max-width: 768px)': {
			width: '100%',
		},
	},
	content: {
		width: '100%',
		paddingLeft: '30px',
		'@media only screen and (max-width: 767px)': {
			width: '100%',
			paddingLeft: '0',
			marginTop: '-20px',
		},
		h1: {
			color: 'primary',
			fontSize: '22px',
			lineHeight: '1.4',
			fontWeight: 600,
			marginBottom: ['20px', '30px'],
			'@media only screen and (max-width: 480px)': {
				fontSize: '20px',
			},
		},
		p: {
			fontSize: ['14px', '15px'],
			lineHeight: '1.86',
		},
	},
	header: {
		position: 'relative',
		borderBottom: `1px solid #EBEBEB`,
		marginBottom: ['25px', '30px'],
		h1: {
			fontSize: '32px',
			lineHeight: '1.45',
			margin: 0,
			marginBottom: '20px',
			clear: 'none',
			color: '#3D3D3D',
			'@media only screen and (max-width: 500px)': {
				fontSize: '25px',
				marginTop: '10px!important',
			},
		}
	},
	description: {
		borderBottom: `1px solid #EBEBEB`,
		paddingBottom: ['15px'],
		marginBottom: ['25px', '20px'],
	},
	courier: {
		borderTop: `1px solid #EBEBEB`,
		paddingTop: ['15px'],
	},
	priceWrapper: {
		margin: '20px 0px 20px 0px'
	},
	price: {
		fontSize: '26px',
		fontWeight: 600,
		color: '#685f52',
		sale: {
			color: 'red',
			textDecoration: 'line-through',
			paddingRight: 10,
			fontSize: '16px',
			fontWeight: 'normal',
		},
		display: 'block'
	},
	noticeWrapper: {
		marginBottom: '10px',
	},
	cartArea: {
		float: 'right',
		marginTop: 20,
		alignItems: 'center',
		justifyContent: 'space-between',
		'.addToCart': {
			minWidth: '120px',
			fontSize: '15px',
			fontWeight: 600,
		},
		providers: {
			marginTop: 20,
			backgroundColor: '#f5f5f5',
			padding: '20px',
			p: {
				color: '#3D3E3D',
				fontSize: '12px',
				fontWeight: '600',
				marginBottom: '15px',
				display: 'block'
			},
			img: {
				'@media only screen and (max-width: 536px)': {
					width: '-webkit-fill-available'
				},
				'@media only screen and (min-width: 769px) and (max-width: 992px)': {
					width: '-webkit-fill-available'
				}
			}
		},
		postedIn: {
			fontSize: '13px',
			lineHeight: '27px',
			marginTop: 20
		}
	},
	cartBtn: {
		width: 'max-content',
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		transition: 'all 0.2s ease',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			backgroundColor: "#E6E6E6",
			borderColor: "#E6E6E6",
			color: "#878788",
			fontWeight: 500
		},
		backgroundColor: '#00d4c5',
		fontWeight: 'normal',
		fontSize: '13px',
		color: 'white',
		borderRadius: '10px',
		padding: '15px 20px 15px 20px',
		'@media only screen and (min-width: 768px) and (min-width: 1400px)': {
			minWidth: '0!important',
			marginTop: '0'

		},
		minWidth: '100%!important',
		minHeight: '40px!important',
		svg: {
			margin: 0,
			width: '23px',
			height: '23px',
			marginTop: '-8px',
		},
		link: {
			lineHeight: '40px',
			margin: '0 auto'
		}
	},
	cartCounter: {
		borderRadius: '10px',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		backgroundColor: 'white',
		boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
		transition: 'all 0.2s ease',
		padding: 0,
		background: '#00d4c5',
		div: {
			width: 50,
			color: 'white',
			textAlign: 'center',
			fontSize: 0,
			fontWeight: '700',
			paddingLeft: 0,
			paddingRight: 0,
			background: '#00d4c5',
		},
		button: {
			padding: 0,
			minWidth: [40, 50],
			minHeight: [40, 50],
			svg: {
				width: 24,
				height: 24,
				marginLeft: 0,
			},
		},
		decrement: {
			background: '#00d4c5',
			color: 'white'
		},
		increment: {
			background: '#00d4c5',
			color: 'white'
		},
	},
	tagArea: {
		margin: ['30px 0', '40px 0'],
		color: 'primary',
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		span: {
			display: 'inline-block',
			color: '#919191',
			margin: '2.5px',
			padding: '4px 12px',
			borderRadius: '6px',
			fontSize: '13px',
			fontWeight: 600,
			border: `1px solid #DBDBDB`,
		},
	},
	outerWrapper: {
		display: 'block',
		textDecoration: 'none',
		zIndex: '0',
		border: '1px solid white',
		width: '100%',
		padding: '10px',
		borderRadius: '12px',
		'&:hover': {
			transition: 'all 0.2s ease-out',
			boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
			top: '-4px',
			backgroundColor: 'white',
			'.add-to-cart-icon': {
				color: 'white',
			},
			'.add-to-cart': {
				color: 'white',
				backgroundColor: '#685f52'
			},
			'.product-image': {
				opacity: .7
			},
		},
		'&:hover:before': {
			transform: 'scale(2.15)'
		}
	},
	imageWrapper: {
		margin: '0 auto',
		transition: 'all 0.4s ease-in-out',
		borderSpacing: '5px',
		borderRadius: '12px',
		position: 'relative',
		alignItems: 'center',
		'@media only screen and (max-width: 1400px)': {
			minHeight: '296px',
			padding: '30px',
		},
	},
	productGrid: {
		marginTop: 50
	},
	zoomIn: {
		zIndex: 1,
		position: 'absolute',
		top: 10,
		right: 10,
		backgroundColor: 'white',
		display: 'block',
		lineHeight: '40px',
		textIndent: 0,
		color: '#000',
		fontSize: '18px',
		fontWeight: '400',
		fontFamily: "organey-icon",
		borderRadius: '10px',
		minWidth: '0!important',
		minHeight: '0!important',
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#00d4c5'
		},
		svg: {
			marginLeft: 0,
			width: '20px',
			height: '20px'
		},
		paddingTop: 0
	},
	subscriptionSelection: {
		'.react-select__control': {
			fontWight: '500',
			padding: '4px',
			fontSize: '18px'
		}
	},
	boxedItems: {
		listStyle: 'none',
		padding: 0,
		input: {
			marginRight: 10
		}
	},
	locationSelectBtn: {
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		transition: 'all 0.2s ease',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
		},
		backgroundColor: '#00d4c5',
		fontWeight: 'normal',
		fontSize: '13px',
		color: 'white',
		borderRadius: '10px',
		padding: '15px 20px 15px 20px',
		'@media only screen and (min-width: 768px) and (min-width: 1400px)': {
			minWidth: '0!important',
			marginTop: '4px'
		},
	},
	productImage: {
		margin: '0 auto',
		display: 'block'
	},
	input: {
		width: '100%',
		fontSize: '14px',
		fontWeight: '400',
		borderRadius: 6,
		padding: '13px',
		opacity: '.7',
		marginBottom: '10px',
		borderWidth: '1px',
		borderStyle: 'solid',
		boxShadow: 'none',
		transition: 'border-color 0.2s ease',
		borderColor: '#E4E4E4',
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
			borderColor: 'darkgray',
		},
		error: {
			fontSize: '13px',
			marginTop: '-22px',
			paddingBottom: '5px',
			color: '#dc3545',
			'@media only screen and (max-width: 767px)': {
				marginTop: '0'
			},
		},
		readOnly: {
			border: 0,
			backgroundColor: 'unset!important',
			'&:hover': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
			'&:focus': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
		}
	},
	form: {
        label: {
            fontWeight: '600',
            marginBottom: '0.5em',
            color: '#2F3E57'
        },
        control: {
            // padding: '6px 12px'
        },
        input: {
            width: '100%',
            fontSize: '14px!important',
            fontWeight: '400',
            borderRadius: 6,
            padding: '10px',
            opacity: '.7',
            marginBottom: '10px',
            borderWidth: '1px',
            borderStyle: 'solid',
            boxShadow: 'none',
            transition: 'border-color 0.2s ease',
            borderColor: '#E4E4E4',
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: 'darkgray',
            },
            error: {
                fontSize: '13px!important',
                marginTop: '-5px',
                paddingBottom: '5px',
                color: '#dc3545'
            },
            readOnly: {
                border: 0,
                backgroundColor: 'unset!important',
                '&:hover': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
                '&:focus': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
            }
        },
        group: {
            paddingLeft: 0,
            marginBottom: 10
        },
        subSection: {
            header: {
                fontWeight: '600',
                fontSize: '24px!important',
                marginBottom: 0,
                '@media only screen and (max-width: 674px)': {
                    fontSize: '20px!important',
                }
            },
            label: {
                fontSize: '14px!important',
                marginBottom: '0.5em'
            }
        },
    },
	spinner: {
		textAlign: 'center',
		display: 'block',
		'.spinner-border': { 
			height: '3em',
			width: '3em',
			color: '#00d4c5'
		}
	}
}

export default styles;
