/** @jsx jsx */
import Fuse from "fuse.js";
import { Link } from "gatsby";
import { useContext, useEffect, useState } from "react";
import { Box, Heading, Image, jsx, Text } from "theme-ui";
import { SearchContext } from "../../provider/search-provider";
import ProductGrid from "../product-grid/product-grid";
import styles from "./search.style";

type PropsType = {
  allProducts: any;
  allCategories: any;
  isMobile: boolean;
  clear: boolean;
};

const fuseOptions = {
  isCaseSensitive: false,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  useExtendedSearch: false,
  minMatchCharLength: 2,
  shouldSort: true,
  threshold: 0.3, // lower threshold value means more accurate results
  location: 0,
  distance: 100,
  keys: ["data.title1"],
};

const Search: React.FC<PropsType> = (props) => {
  const { allProducts, allCategories } = props;
  const isMobile = props.isMobile;

  const products = allProducts?.nodes || [];
  const [value, setValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const { setIsSearched } = useContext(SearchContext);
  const fuse = new Fuse(products, fuseOptions);

  const handleClear = () => {
    setValue("");
    setNoResult(false);
  };

  useEffect(() => {
    if (props.clear) {
      handleClear();
    }
  }, [props.clear]);

  useEffect(() => {
    if (value.length > 0) {
      setIsSearched(true);
    }
    return () => setIsSearched(false);
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      let searchData: any = [];

      if (value.length) {
        let category = allCategories.nodes.find(
          (x: any) =>
            x.data.title.text.toUpperCase() === value.toUpperCase()
        );
        if (category) {
          category.data.products.forEach(({ product }: any) => {
            searchData.push(product.document);
          });
        } else {
          const fuseData = fuse.search(value);
          if (fuseData && fuseData.length) {
            fuseData.forEach((data) => {
              searchData.push(data.item);
            });
          }
        }
      }

      setSearchData(searchData);
    }, 500);
    return () => clearTimeout(handler);
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value.length && !searchData.length) {
        setNoResult(true);
      } else {
        setNoResult(false);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [value, searchData]);

  return (
    <div className="container">
      <Box className="outibleSearch">
        <Box as="label" sx={styles.fieldWrapper} className="search">
          <svg
            className={isMobile ? "mobile-search-icon" : "search-icon"}
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
              transform="translate(-4 -1.525)"
              fillRule="evenodd"
            />
          </svg>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            autoComplete="off"
          />
           {value !== '' && (
            <button sx={styles.clearButton} onClick={handleClear}>Clear</button>
          )}
        </Box>

        {value && searchData.length ? (
          <Box sx={styles.searchResult} className="searchResult">
            <Heading as="h1">{value}</Heading>
            <hr
              className="bar-indent-center"
              sx={styles.searchDivider}
            ></hr>
            <ProductGrid
              id="searchResults"
              type=""
              gridTitle=""
              products={searchData}
              withLink={true}
            />
          </Box>
        ) : null}
        {value && noResult && (
          <Box sx={styles.searchResult} className="notFound searchResult">
            <Box sx={styles.notFoundCard}>
              <Text as="h2">No Items Found</Text>
              <Text as="p">
                Can't find the item you're looking for?{" "}
                <Link to="/contact">Get in touch</Link>
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default Search;
