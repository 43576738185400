/** @jsx jsx */
import { Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { Box, Button, jsx, Text, useThemeUI } from "theme-ui";
import { formatPrice, isOnSale, applyVAT } from "../../helper/product-helper";
import DrawerCart from "../drawer-cart/drawer-cart";
import styles from "./product-card.style";
import { LocalCartContext } from "../../provider/local-cart-provider";
import { BsFillEyeFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import { IoIosClose } from 'react-icons/io';
import { PrismicRichText } from '@prismicio/react'
import { getSessionItem } from '../../helper/session-storage';
import PackageModal from '../../components/modal/package-modal';

type PropsType = {
  path: string;
  title: string;
  thumbnail: any;
  imageSrc: any;
  variants: any;
  cmsId: string;
  withLink: boolean;
  description: any;
  options: any;
  vouchers: any;
  smokersProduct?: boolean;
  showProductPreview?: boolean;
  redirectToProduct?: boolean;
};

function ProductImage(props: any) {
  return (
    <GatsbyImage
      className="product-image"
      imgStyle={{ objectFit: "cover" }}
      image={props?.image}
      alt={props?.title ?? ""}
    />
  )
}

const ProductCard: React.FC<PropsType> = (product) => {
  const { theme } = useThemeUI();
  
  let {
    path,
    title,
    variants,
    cmsId,
    thumbnail,
    imageSrc,
    withLink,
    description,
    options,
    vouchers,
    smokersProduct,
    showProductPreview,
    redirectToProduct
  } = product;

  const { products, add, update } = useContext(LocalCartContext);
  const [cartVisible, setCartVisible] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);

  let initialVariant = variants.length > 0 ? variants[0] : null;
  let option = options?.length > 0 ? options[0] : null;
  let voucher = vouchers?.length > 0 ? vouchers[0] : null;

  const [variant, setVariant] = useState({ ...initialVariant });

  const onSale = isOnSale(+variant.sale_price, +variant.price1);

  const salePrice = +variant.sale_price;
  const price = onSale ? salePrice : +variant.price1;
  let originalPrice = +variant.price1;

 
  const availableForSale = variant.available_for_sale;
  const vatRate = variant.vat_rate;
  const availableToSmokers = option?.is_product_available_for_smokers;
  const availableToInternational = option?.is_an_international_product;
  const giftVoucher = option?.is_this_product_a_gift_voucher;

  const customGiftVoucher = voucher?.is_product_custom_gift_card;
  const startPrice = +voucher?.start_price;
  const endPrice = +voucher?.end_price;

  const productIndex = products.findIndex(
    (item) => item.uid === cmsId
  );

  const cartProduct = productIndex > -1 ? products[productIndex] : false;
  const cartProductQuantity = cartProduct ? cartProduct.quantity : 0;
  const [quantity, setQuantity] = useState(0);

    //is there a package product in basket?
  const isPackageProduct = option?.is_product_a_package;
  const packageProductsInBasket = products.filter(x => x.isPackageProduct);
  const isPackageProductsInBasket = packageProductsInBasket.length > 0;

  const internationalProductsOnly = getSessionItem("InternationProductsOnly");

  useEffect(() => {
    setQuantity(cartProductQuantity);
  }, [cartProductQuantity]);

  const handleQuantityChange = (quantity: number) => {
    update(cmsId, quantity);
  };

  const handleHideProductModal = () => {
    setShowProductModal(false)
  };

  const handleAddToCart = () => {
    if (!availableForSale) return false;

    if (redirectToProduct) {
      navigate('/product/' + cmsId);
      return;
    }

    //if adding smokers product remove all products not available to smokers
    //TODO: THIS MAY NEED ANOTHER FLAG WHAT IF ITS NOT THROUGH THE SMOKERS MEDICAL FORM ROUTE?
    if (smokersProduct) {
      products.filter(x => !x.availableToSmokers).map(x => {
        update(x.uid, 0)
      });
    }

    if (JSON.parse(internationalProductsOnly)) {
      products.filter(x => !x.availableToInternational).map(x => {
        update(x.uid, 0)
      });
    }

    if (!isPackageProductsInBasket && !isPackageProduct && !giftVoucher) {
      setShowPackageModal(true);
    }

    if (quantity < 1) {
      const item = {
        title,
        thumbnail,
        imageSrc: imageSrc,
        quantity: 1,
        price: applyVAT(onSale ? salePrice : price, vatRate),
        vatRate: vatRate,
        currency: "GBP",
        uid: cmsId,
        priceBeforeVat: price,
        availableToSmokers: availableToSmokers,
        availableToInternational: availableToInternational,
        isPackageProduct: isPackageProduct,
        isGiftVoucher: giftVoucher,
        isCustomGiftVoucher: customGiftVoucher,
        availableForSale: availableForSale        
      };
      add(item);
    }
  };
  
  const ProductAvailabilityLabel = (props: any) => {
    return (
      <React.Fragment>
        {!props.availableForSale && (
          <Text sx={theme.productLabel} variant={"productLabel.modes.danger"}>{"Sold Out"}</Text>
        )}
      </React.Fragment>
    );
  }

  const handleCardOnClick = () => {
    if (showProductPreview) {
      setShowProductModal(true)
    }
  }

  const Counter = (props: any) => {
    return (
      <Box sx={styles.cart}>
        {quantity > 0 ? (
          <Box sx={styles.cartCounter}>
            <Button
              sx={styles.cartCounter.decrement}
              title="Decrement"
              onClick={() => handleQuantityChange(quantity - 1)}
            >
              <IoIosRemove />
            </Button>
            <Box style={{fontSize: '15px'}}>{quantity}</Box>
            <Button
              sx={styles.cartCounter.increment}
              title="Increment"
              onClick={() => handleQuantityChange(quantity + 1)}
            >
              <IoIosAdd />
            </Button>
          </Box>
        ) : (
          <Box>
            {/* TODO: WHAT THIS WITH LINK REALLY DOING? */}
            {withLink && (
              <Box>
                {!availableForSale ? (
                  <Button
                    sx={props.cartButton}
                    title={"Sold Out"}
                    disabled={true}
                  >
                    <Text>Sold Out</Text>
                  </Button>
                ) : (
                  <Box>
                    {!customGiftVoucher ? (
                      <React.Fragment>
                      <Button
                        sx={props.cartButton}
                        className={quantity > 0 ? "active" : "product-card-cta"}
                        title="Add to Basket"
                        onClick={() => handleAddToCart()}
                      >
                        <Text>
                          <AiOutlineShopping
                            sx={props.cartButton.icon}
                            className="product-card-cta"
                          />{" "}
                          &nbsp; Add to Basket
                        </Text>
                      </Button>
                      </React.Fragment>
                        ) : (
                          <Link to={path} sx={styles.wrapper.a}>
                            <Button
                              sx={props.cartButton}
                              className={quantity > 0 ? "active" : "product-card-cta"}
                              onClick={() => handleAddToCart()}
                            >
                              <Text>
                                <AiOutlineShopping
                                  sx={props.cartButton.icon}
                                  className="product-card-cta"
                                />{" "}
                                &nbsp; View
                              </Text>
                            </Button>
                          </Link>
                        )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Box sx={styles.outerWrapper}>
        <Box sx={styles.wrapper} onClick={() => handleCardOnClick()}>
          <React.Fragment>
            <Box sx={styles.imageWrapper} >
              {thumbnail && (
                <React.Fragment>
                  <ProductAvailabilityLabel availableForSale={availableForSale} />
               
                  {!showProductPreview && (
                    <Link to={path}>
                      <ProductImage image={thumbnail} />
                    </Link>
                  )}

                  {showProductPreview && (
                    <div>
                      {availableForSale && (
                        <BsFillEyeFill sx={styles.iconWrapper} className='preview' onClick={() => setShowProductModal(true)} />
                      )}
                      <ProductImage image={thumbnail} />
                    </div>
                  )}
                </React.Fragment>
              )}
            </Box>

            <Box sx={styles.titleWrapper}>
              <span sx={styles.title}>
               {title}
              </span>
            </Box>

            <Text as="span" sx={styles.priceOuter}>
              <div>
                <p sx={styles.product.wrapper.price}>
                  {!customGiftVoucher ? (
                    <React.Fragment>
                      {onSale ? (
                        <React.Fragment>
                          <span>{formatPrice(applyVAT(originalPrice, vatRate))}</span>
                          {formatPrice(applyVAT(price, vatRate))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {formatPrice(applyVAT(price, vatRate))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {formatPrice(applyVAT(startPrice, vatRate))} - {formatPrice(applyVAT(endPrice, vatRate))}
                    </React.Fragment>
                  )}
                </p>
              </div>
            </Text>
          </React.Fragment>
        </Box>
        <Counter cartButton={styles.cartBtn} />
         <Modal
          show={showProductModal}
          onHide={handleHideProductModal}
          centered
          size='lg'
        >
          <Modal.Header>
            <Modal.Title>
              {title}
              <IoIosClose sx={styles.modal.close} onClick={handleHideProductModal} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div sx={styles.modal.image} className='col-md-6'>
                <span sx={styles.modal.productAvailabilityLabel}>
                  <ProductAvailabilityLabel availableForSale={availableForSale} />
                </span>

                <ProductImage image={thumbnail} />
              </div>
              <div sx={styles.modal.description} className='col-md-6'>
                <PrismicRichText field={description?.richText} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer sx={styles.modal.footer}>
            <Counter cartButton={styles.modal.button} />
          </Modal.Footer>
        </Modal>

        <PackageModal
          showModal={showPackageModal}
          setShowModal={setShowPackageModal}
          showCloseButton={true}
          description={<PackageDescription title={title} />} />
      </Box>

      <DrawerCart
        open={cartVisible}
        onClick={() => setCartVisible(false)}
        onClose={() => setCartVisible(false)}
        products={products}
      /></React.Fragment>
  );
};

const PackageDescription = (props: any) => {
  return (
    <React.Fragment>
      <p><b>{props.title}</b> has been added to your basket, however, checkout is not possible until a selection from our featured product categories is made.</p>
      <p>You can close this and continue shopping.</p>
    </React.Fragment>
  )
}


export default ProductCard;
