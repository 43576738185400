/** @jsx jsx */
import { graphql } from "gatsby";
import { jsx } from "theme-ui";
import { Breadcrumb } from "../components/breadcrumbs/breadcrumbs";
import PrimaryLayout from "../components/layout/primary/primary";
import ProductGrid from "../components/product-grid/product-grid";
import SEO from "../components/seo";
import { capitalizeFirstLetter } from "../helper/string-helper";
import { getSessionItem } from '../helper/session-storage';

const CategoryPage = ({ data, pageContext }: any) => {

  const { imageSharp: image, products } =
    data.prismicCategory?.data ?? data.prismicSubcategory?.data;

  const internationalProductsOnly = getSessionItem("InternationProductsOnly");

  if (internationalProductsOnly) {
    for (var i=0; i < products.length; i++){
      if (products[i].product.document.data.options[0] !== undefined){
        if (!products[i].product.document.data.options[0].is_an_international_product) {
          products.splice(i, 1); 
          i--; 
        }
      }
    }
  }

  let title: string = "";
  let description: string = data.prismicCategory?.data?.description;

  if (data.prismicCategory)
    title = capitalizeFirstLetter(
      data.prismicCategory.data.title.text
    );

  if (data.prismicSubcategory)
    title = capitalizeFirstLetter(
      data.prismicSubcategory.data.title.text
    );

  let breadcrumbs: Breadcrumb[] = [
    {
      label: "Home",
      pathname: "/",
    },
  ];

  if (data.prismicCategory) {
    let categoryBreadcrumb: Breadcrumb = {
      label: title,
      pathname: `/${pageContext.uid}`,
    };

    breadcrumbs.push(categoryBreadcrumb);
  }
  if (data.prismicSubcategory) {
    let categoryBreadcrumb: Breadcrumb = {
      label: data.prismicSubcategory.data.parent_category.document.data.title.text,
      pathname: `/${data.prismicSubcategory.data.parent_category.document.uid}`,
    };

    let subcategoryBreadcrumb: Breadcrumb = {
      label: title,
      pathname: `/${pageContext.uid}`,
    };

    breadcrumbs.push(categoryBreadcrumb);
    breadcrumbs.push(subcategoryBreadcrumb);

    description = data.prismicSubcategory?.data?.description;
  }
  return (
    <PrimaryLayout
      homeLink="/"
      pathPrefix=""
      breadcrumbTitle={title}
      breadcrumbBgImage={image}
      breadcrumbBreadcrumbs={breadcrumbs}
    >
      <SEO title={title} />
      <div sx={styles.container} className="container">
        <ProductGrid
          id="category"
          type=""
          products={products}
          withLink={true}
          isCategoryProduct={true}
          search={false}
          description={description}
          showFilter={true}
        />
      </div>
    </PrimaryLayout>
  );
};

export const query = graphql`
  query ($uid: String!) {
    prismicCategory(uid: { eq: $uid }) {
      data {
        title {
          text
          html
          richText
        }
        image {
          alt
          copyright
          url
          gatsbyImageData
        }
        description {
          text
          html
          richText
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                id
                uid
                tags
                data {
                  title1
                  description {
                    text
                    html
                    richText
                  }
                  variants {
                    available_for_sale
                    price1
                    sale_price
                    vat_rate
                  }
                  options {
                    is_an_international_product
                    is_product_available_for_smokers
                    show_hospital_location_selection
                    is_product_a_package
                    is_this_product_a_gift_voucher
                    is_product_visible_on_site
                  }
                  twin_options {
                    show_twins_option
                    twin_price
                  }
                  extras {
                    title
                  }
                  method_of_pills {
                    title
                  }
                  images {
                    image {
                      alt
                      copyright
                      url
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicSubcategory(uid: { eq: $uid }) {
      data {
        title {
          text
          html
          richText
        }
        description {
          text
          html
          richText
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                id
                uid
                tags
                data {
                  title1
                  description {
                    text
                    html
                    richText
                  }
                  variants {
                    available_for_sale
                    price1
                    sale_price
                    vat_rate
                  }
                  options {
                    is_an_international_product
                    is_product_available_for_smokers
                    show_hospital_location_selection
                    is_product_a_package
                    is_this_product_a_gift_voucher
                    is_product_visible_on_site
                  }
                  twin_options {
                    show_twins_option
                    twin_price
                  }
                  extras {
                    title
                  }
                  method_of_pills {
                    title
                  }
                  images {
                    image {
                      alt
                      copyright
                      url
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        parent_category {
          document {
            ... on PrismicCategory {
              data {
                title {
                  text
                  html
                  richText
                }
              }
              uid
            }
          }
        }
      }
    }
  }
`;

const styles = {
  container: {
    marginTop: "40px",
  },
};

export default CategoryPage;
