const styles = {
	outerWrapper: {
		display: 'block',
		textDecoration: 'none',
		zIndex: '0',
		borderRadius: '20px',
		marginBottom: '10px',
		transition: 'all 0.2s ease-out',
		boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
		backgroundColor: 'white',
		'.product-image': {
			borderRadius: '10px 10px 0 0!important',
			height: '289px'
		},
		'.product-card-cta': {			
			borderRadius: '0 0 10px 10px!important'
		},
		'&:hover': {
			'.product-card-cta': {
				color: 'white',
				backgroundColor: '#00d4c5',
				borderColor: '#00d4c5',
			},
			'&:hover': {
				'.product-image': {
					opacity: .7,
				},
				'.preview': {
					opacity: 1,
				},
				boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
			},
			'&:hover:before': {
				transform: 'scale(2.15)'
			}
		}
	},
	wrapper: {
		cursor: 'pointer',
		pointerEvents: 'all',
		'&.active': {
			cursor: 'default',
			'.gatsby-image-wrapper, .content': {
				opacity: 0.4,
			},
			h4: {
				textOverflow: 'initial',
				WebkitLineClamp: 'initial',
			},
		},
		'&.disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			pointerEvents: 'none',
		},
		a: {
			textDecoration: 'none',
		},
		textAlign: 'center',
		zIndex: 0
	},
	imageWrapper: {
		position: 'relative',
		margin: '0 auto',
		transition: 'all 0.4s ease-in-out',
		background: '#f5f5f5',
		borderRadius: '12px',
		height: '100%'
	},
	cart: {
		'button.active': {
			color: 'white',
			fontSize: 0,
			fontWeight: '700',
			backgroundColor: '#3a6201',
			':hover': {
				backgroundColor: '#3a6201',
			},
		},
	},
	packageButton: {
		width: '100%',
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		transition: 'all 0.2s ease',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			backgroundColor: "#E6E6E6",
			borderColor: "#E6E6E6",
			color: "#878788",
			fontWeight: 500
		},
		backgroundColor: '#00d4c5',
		fontWeight: 'normal',
		fontSize: '13px',
		color: 'white',
		borderRadius: '10px',
		padding: '15px 20px 15px 20px',
		'@media only screen and (min-width: 768px) and (min-width: 1400px)': {
			minWidth: '0!important',
			marginTop: '0'

		},
		minWidth: '100%!important',
		minHeight: '40px!important',
		svg: {
			margin: 0,
			width: '23px',
			height: '23px',
			marginTop: '-8px',
		},
		link: {
			lineHeight: '40px',
			margin: '0 auto'
		}
	},
	cartBtn: {
		padding: '15px',
		fontSize: '15px',
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		borderRadius: '10px',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			backgroundColor: "#E6E6E6",
			borderColor: "#E6E6E6",
			color: "#878788",
			fontWeight: 500
		},
		backgroundColor: '#00d4c5',
		borderColor: '#00d4c5',
		color: 'white',
		fontWeight: 'normal',
		border: '1px solid #00d4c5',
		minWidth: '100%!important',
		minHeight: '40px!important',
		icon: {
			marginTop: '-10px',
			color: 'white',
			height: '25px!important',
			width: '25px!important'
		},
		link: {
			lineHeight: '40px',
			margin: '0 auto'
		}
	},
	cartCounter: {
		padding: "7.8px",
		display: 'flex',
		fontSize: '15px',
		alignItems: 'center',
		minWidth: [106, 100],
		minHeight: [36, 40],
		overflow: 'hidden',
		backgroundColor: 'white',
		boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
		transition: 'all 0.2s ease',
		background: '#00d4c5',
		borderRadius: '0 0 10px 10px!important',
		height: '54px',
		div: {
			width: 50,
			color: 'white',
			textAlign: 'center',
			fontSize: 0,
			fontWeight: '700',
			paddingLeft: 0,
			paddingRight: 0,
			background: '#00d4c5',
		},
		button: {
			padding: 0,
			minWidth: [32, 45],
			minHeight: [36, 40],
			svg: {
				width: 30,
				height: 30,
				marginLeft: 0,
			},
			margin: '0 auto',
		},
		decrement: {
			background: '#00d4c5',
			color: 'white'
		},
		increment: {
			background: '#00d4c5',
			color: 'white'
		},
	},
	priceOuter: {
		color: 'primary',
		fontWeight: '700',
	},
	prevPrice: {
		color: 'darkgray',
		fontSize: 0,
		marginLeft: 11,
	},
	soldOut: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 67,
		minHeight: 24,
		borderRadius: "10px",
		fontSize: 11,
		fontWeight: '700',
		color: 'badge',
		backgroundColor: 'lightgray',
		marginLeft: 10,
		float: 'right',
		paddingLeft: '10px',
		paddingRight: '10px',
		marginBottom: '20px'
	},
	titleWrapper: {
		height: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		color: 'text',
		fontWeight: '400',
		lineHeight: 2,
		fontFamily: 'body',
		marginTop: '8px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		fontSize: '15px'
	},
	product: {
		wrapper: {
			position: 'relative',
			buildBoxPrice: {
				fontSize: 20
			},
			price: {
				fontSize: 20,
				color: '#685f52',
				span: {
					color: 'red',
					textDecoration: 'line-through',
					paddingRight: 10,
					fontSize: '16px',
					fontWeight: 'normal'
				}
			},
		},
		type: {
			margin: '10px auto',
			display: '-webkit-inline-box'
		}
	},
	modal: {
		image: {
			margin: '0 auto', 
			display: 'block', 
		},
		divider: {
			marginTop: '20px',
			 marginBottom: '20px'
		},
		description: {
			overflowY: 'scroll' 
		},
		footer: {
			width: '100%', 
			margin: '0 auto', 
			display: 'block'
		},
		button: {
			zIndex: '9999!important',
			width: '100%',
			textDecoration: 'unset',
			textTransform: 'uppercase',
			display: 'flex',
			opacity: 1,
			transform: 'scale(1)',
			'&:disabled': {
				opacity: 0.6,
				cursor: 'not-allowed',
				backgroundColor: "#E6E6E6",
				borderColor: "#E6E6E6",
				color: "#878788",
				fontWeight: 500
			},
			':hover': {
				backgroundColor: 'white',
				color: '#9a9b9a',
				fontSize: 0,
				border: '1px solid #ccc',
				'.product-card-cta': {
					color: '#5C9963'
				},
			},
			backgroundColor: '#00d4c5',
			fontWeight: '400',
			fontSize: '16px',
			color: 'white',
			borderRadius: '10px',
			padding: 0,
			icon: {
				marginTop: '-6px',
				color: 'white',
				fontSize: 15
			},
			link: {
				lineHeight: '40px',
				margin: '0 auto'
			}
		},
		close: {
			position: 'absolute',
			right: '3px',
			fontSize: '40px',
			top: '5px',
			cursor: 'pointer'
		},
		productAvailabilityLabel: {
			position: 'absolute',
			whiteSpace: 'nowrap',
			left: '130px',
			top: '25px'
		}
	},
	iconWrapper: {
		padding: '5px',
		backgroundColor: '#00d4c5',
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		color: 'white',
		float: 'right',
		position: 'absolute',
		zIndex: 1,
		right: '20px',
		top: '20px',
		opacity: '.5'
	},
	productGrid: {
		gridGap: '20px',
		gridTemplateColumns: [
			'1fr 1fr',
			'1fr 1fr 1fr',
			'1fr 1fr 1fr 1fr',
		],
		marginBottom: [-15, -30],
		'@media only screen and (max-width: 536px)': {
			display: 'contents',
			padding:0,
			margin: 0
		}
	}
}

export default styles
