/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { Box, Text, Button, jsx } from 'theme-ui';
import useWindowSize from '../../hooks/useWindowSize';
import get from 'lodash/get';
import Carousel from '../carousel/carousel';
import { PrismicRichText } from '@prismicio/react'
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const HomeBanner = () => (
	
	<StaticQuery<GatsbyTypes.Query>
		query={`${staticQuery}`}
		render={(data) => {
			const [barClassName, setBarClassName] = useState('bar-indent');

			const windowSize = useWindowSize();
			useEffect(() => {
				if (windowSize !== undefined && windowSize < 768) {
					setBarClassName('bar-indent-center');
				} else {
					setBarClassName('bar-indent');
				}
			}, [windowSize]);


			const node = get(data, 'allPrismicHome.nodes[0].data');
			const responsive = {
				custom: {
					breakpoint: { max: 1000000, min: 0 },
					items: 1
				},
			};

			const styles = {
				wrapper: {
					'p': {
						fontSize: '22px!important',
						// textAlign: 'justify',
						'@media only screen and (max-width: 768px)': {
							fontSize: '16px!important',
						},
					},
					display: 'grid',
					'.carousel-button-group': {
						opacity: 0,
						visibility: 'hidden',
						transition: 'all 0.25s ease',
					},
					'&:hover': {
						'.carousel-button-group': {
							opacity: 1,
							visibility: 'visible',
						},
					},
					img: {
						width: '100%',
						minHeight:'600px',
						objectFit: 'cover',
					},
				},
				banner: {
					width: '100%',
					minHeight:'750px',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover!important',
					position: 'relative',
					bar: {
						width:'150px',
					},
					'@media only screen and (max-width: 768px)': {
						height: '100%',
						maxHeight: '100%',
						minHeight:'536px',
					},
					content: {
						maxWidth: '1400px',
						margin: '0 auto'
					}
				},
				bannerContent: {
					textAlign: 'center',
					position: 'relative',
					zIndex: 1,
					h1: {
						p: {
							fontSize: [3, 4],
							lineHeight: [1.6, 'initial'],
							marginBottom: [0, 25, 30],
						},
						fontSize:'3.7em'
					},
					button: {
						svg: {
							position: 'relative',
							top: [0, '1px'],
						},
					},
				},
				slide1: {
					'h1':{
						fontSize: '50px!important',
						'@media only screen and (max-width: 768px)': {
							fontSize: '38px!important',
						},
					},
					position: 'absolute',
					top: '50%',
					left: '55%',
					transform: 'translate(-50%, -50%)',
					title1: {
						lineHeight: '36px',
						letterSpacing: '0px',
						fontWeight: '300',
						paddingBottom: '20px',
						maxWidth: '800px',
						color: `${node.banners[0].title1_colour} !important`
					},
					title2: {
						lineHeight: '68px',
						letterSpacing: '0px',
						paddingBottom: '20px',
						display: 'block',
						'@media only screen and (max-width: 768px)': {
							// padding: '15px'
						},
						fontSize: '22px',
						color: `${node.banners[0].title2_colour} !important`
					},
					title3: {
						textTransform: 'uppercase',
						lineHeight: '36px',
						letterSpacing: '0px',
						fontWeight: '500',
						fontSize: '29px',
						paddingBottom: '20px',
						color: `${node.banners[0].title3_colour} !important`
					},
					'@media only screen and (max-width: 768px)': {
						textAlign: 'center',
						left: '50%',
					},
					callToActionLink: {
						textDecoration: 'none',
						 display: 'inline-block',
						 '@media only screen and (max-width: 768px)': {
							width: '100%',
						},
					},
					callToActionButton: {
						backgroundColor: 'white', 
						color: '#000' ,
						borderRadius: '10px',
						marginTop: 20,
						span: {
							padding: '12px'
						},
						'@media only screen and (max-width: 768px)': {
							margin: '0 auto'
						},
						fontWeight: 'normal',
						padding: '20px 60px',
						fontSize: '19px',
						svg: {
							width: '22px',
							height: '22px',
							marginTop: '-4px',
						},
					},
				},
				slide2: {
					maxWidth: '800px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					title1: {
						lineHeight: '36px',
						letterSpacing: '0px',
						fontWeight: '300',
						paddingBottom: '20px',
						color: `${node.banners[1].title1_colour} !important`
					},
					title2: {
						lineHeight: '68px',
						letterSpacing: '0px',
						fontWeight: '900',
						paddingBottom: '20px',
						color: `${node.banners[1].title2_colour} !important`
					},
					title3: {
						textTransform: 'uppercase',
						lineHeight: '36px',
						letterSpacing: '0px',
						fontWeight: '500',
						fontSize: '29px',
						paddingBottom: '20px',
						color: `${node.banners[1].title3_colour} !important`
					},
					'@media only screen and (max-width: 768px)': {
						textAlign: 'center'
					},
					callToActionLink: {
						textDecoration: 'none',
						 display: 'inline-block',
						 '@media only screen and (max-width: 768px)': {
							width: '100%',
						},
					},
					callToActionButton: {
						backgroundColor: '#00d4c5', 
						borderRadius: '30px',
						marginTop: 40,
						span: {
							padding: '12px'
						},
						margin: '20px auto',
						fontWeight: 'normal',
						padding: '20px 60px',
						fontSize: '19px',
						svg: {
							width: '22px',
							height: '22px',
							marginTop: '-4px',
						},
					},
				}
			};


			return (
				<Box sx={styles.wrapper} className="banner-carousel">
					<Carousel ssr={true} responsive={responsive} autoPlay={false} gutter="10px">
						{node.banners.map((item: any, index: number) => (
							<Box as="section" key={index} sx={{ ...styles.banner }} style={{ background: `url(${item.banner.url})` }}>
								
								<div className="col-lg-12" sx={styles.banner.content}>
									<div className="row">
										<div sx={index == 0 ? styles.slide1 : styles.slide2}>
											<span sx={index == 0 ? styles.slide1.title1 : styles.slide2.title1}>
												<PrismicRichText style={{ color: `${item.title1_colour} !important` }} field={item.title1.richText} />
												{index == 0 && (
													<React.Fragment>
														<hr className={barClassName} style={{ background: 'none' }}></hr>
													</React.Fragment>
												)}
											</span>

											<span className="col-md-8 col-lg-5 col-sm-12" sx={index == 0 ? styles.slide1.title2 : styles.slide2.title2}>
												<PrismicRichText field={item.title2.richText} />
											</span>

											<PrismicRichText sx={index == 0 ? styles.slide1.title3 : styles.slide2.title3} field={item.title3.richText} />
											<CallToActionButton node={node} styles={index == 0 ? styles.slide1 : styles.slide2} banner={item} />
										</div>
									</div>
								</div>
							</Box>
						))}
					</Carousel>
				</Box>
			);
		}}
	/>
);

const CallToActionButton = (props: any) => {
	const styles = props.styles;
	const banner = props.banner;
	const bannerButtonText = banner.button_text;
	const bannerButtonSlug = banner.button_slug
	const bannerButtonColour = banner.button_background_colour;
	const bannerButtonTextColour = banner.banner_button_text_colour;

	const windowSize = useWindowSize();
	const [offset, setOffset] = useState(60);
	useEffect(() => {
		if (windowSize !== undefined
			&& windowSize < 1081) {
			setOffset(80);
		} else {
			setOffset(60);
		}
	}, [windowSize]);

	return (
		<Box as="span">
			{bannerButtonSlug && (
				<Button
					onClick={() => { navigate(bannerButtonSlug) }}
					sx={styles.callToActionButton}
					style={{ backgroundColor: bannerButtonColour }}
				>
					<Text as="span" style={{ color: bannerButtonTextColour }}>{bannerButtonText ? bannerButtonText : 'Order Now'}
						<FaRegArrowAltCircleRight />
					</Text>
				</Button>
			)}
		</Box>
	)
}

const staticQuery = graphql`
{
	allPrismicHome {
	  nodes {
		data {
		banners {
			banner {
				url
			}
			title1 {
				richText
			}
			title2 {
				richText
			}
			title3 {
				richText
			}
			button_text
			button_slug
			title1_colour
			title2_colour
			title3_colour
			button_background_colour
			banner_button_text_colour
			}
		}
	  }
	}
  }
`;

export default HomeBanner;
