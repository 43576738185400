import { Elements } from 'prismic-reactjs';

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer = function (type, element, content, children, key) {

  if (children[0] != null && children[0][0] != null) {
    switch (type) {
      // Replace non breaking space with white space
      case Elements.paragraph:
        return nsbp(children[0][0]);

      case Elements.heading1:
        return nsbp(children[0][0]);

      case Elements.heading3:
        return nsbp(children[0][0]);

      case Elements.heading5:
        return nsbp(children[0][0]);

      default:
        return null;
    }
  }
};

// Replace non breaking space with white space
export const nsbp = function (value: string) {
  return value.replace(/\u00a0/g, " ");
};