const styles = {
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: 20,
		position: 'relative',
		'@media only screen and (max-width: 480px)': {
			padding: '10px 20px',
		},
	},
	title: {
		"@media only screen and (max-width: 768px)": {
			fontSize: '30px!important'
		},
		fontWeight: '600',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 750,
		marginX: 'auto',
		button: {
			marginRight: -18,
		},
		color: '#685f52',
	},
	totalVat: {
		fontSize: '15px!important',
		color: '#999999'
	},
	totalPrice: {
		display: 'contents',
		width: 'calc(100% + 60px)',
		padding: '15px 30px',
		marginTop: 0,
		marginLeft: '-30px',
		position: 'relative',
		'@media only screen and (max-width: 480px)': {
			marginTop: 18,
			padding: '0px 30px',
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: -30,
			width: '100%',
			height: '30px',
			background:
				'linear-gradient(to bottom, rgba(255, 255, 255, 0.7) , rgba(255, 255, 255, 0))',
			zIndex: 1,
			pointerEvents: 'none',
			'@media only screen and (max-width: 480px)': {
				display: 'none',
			},
		},
		'>div': {
			width: '100%',
			maxWidth: 750,
			marginX: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		h4: {
			fontFamily: 'body',
			fontSize: '25px',
			color: '#685f52',
			'@media only screen and (max-width: 480px)': {
				fontSize: '20px'
			},
		},
		div: {
			fontSize: '25px',
			'@media only screen and (max-width: 480px)': {
				fontSize: '20px'
			},
		},
	},
	cartItems: {
		width: '100%',
		maxWidth: 780,
		marginX: 'auto',
		overflow: 'hidden',
		flexGrow: '1',
		height: 'calc(100% - 240px)',
		'@media only screen and (max-width: 480px)': {
			height: 'calc(100% - 206px)',
		},
		'> div': {
			height: '100%',
			overflow: 'auto',
			overflowX: 'hidden',
			msOverflowStyle: 'none',
  			scrollbarWidth: 'none',
			  '::-webkit-scrollbar': {
				  display: 'none'
			  }
		},
	},
	buttonStyle: {
		width: '100%',
		maxWidth: 750,
		marginX: 'auto',
		backgroundColor: 'white',
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			right: 0,
			top: -30,
			width: '100%',
			height: '30px',
			background:
				'linear-gradient(to top, rgba(255, 255, 255, 0.7) , rgba(255, 255, 255, 0))',
			zIndex: 1,
			pointerEvents: 'none',
			'@media only screen and (max-width: 480px)': {
				display: 'none',
			},
		},
		button: {
			width: '100%',
			svg: {
				position: 'relative',
				top: '1px',
				marginRight: '10px',
			},
		},
	},
	checkout: {
		textDecoration: 'none',
		button: {
			backgroundColor: '#00d4c5',
			borderRadius: '10px',
			color: 'white',
			border: '1px solid #00d4c5'
		},
	},
	addToCart: {
		textDecoration: 'none',
		button: {
			marginTop: 10,
			backgroundColor: 'white',
			borderRadius: '10px',
			color: 'primary',
			border: '1px solid #CCC',
			'&:hover': {
				border: '2px solid #CCC',
				color: '#685f52'
			},
		},
	}
};

export default styles;
