import { size } from "lodash";

const styles = {
	searchButton: {
		fontWeight: '400',
		backgroundColor: '#00d4c5',
		fontSize: '14px',
		right: '4px',
		top: '4px',
		bottom: '4px',
		height: 'unset',
		padding: '10px 24px',
		width: 'unset',
		border: 'none',
		color: 'white',
		position: 'absolute',
		borderRadius: '10px',
		textDecoration: 'none!important',
		'@media only screen and (max-width: 992px)': {
			display: 'none'
		},
	},
	accordionDescription: {
		'@media only screen and (max-width: 768px)': {
			fontSize: '16px!important'
		},
	},
	clearButton: {
		fontWeight: '400',
		backgroundColor: '#92310b',
		fontSize: '14px',
		right: '4px',
		top: '4px',
		bottom: '4px',
		height: 'unset',
		padding: '10px 24px',
		width: 'unset',
		border: 'none',
		color: 'white',
		position: 'absolute',
		borderRadius: 30,
		textDecoration: 'none!important',
		'@media only screen and (max-width: 992px)': {
			padding: '0 15px',
		}
	},
	fieldWrapper: {
		alignItems: 'center',
		position: 'relative',
		width: '100%',
		input: {
			width: '100%',
			fontSize: 1,
			fontWeight: '400',
			borderRadius: '10px',
			padding: ['14px 20px 14px 50px', '14px 25px 14px 62px'],
			backgroundColor: 'white',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: 'muted',
			boxShadow: 'none',
			transition: 'border-color 0.2s ease',
			'&:focus': {
				outline: 'none',
				boxShadow: 'none',
				borderColor: 'darkgray',
			},
		},
		svg: {
			fill: 'text',
			position: 'absolute',
			'&.grid-search-icon': {
				marginLeft: '25px',
				top: '19px',
				pointerEvents: 'none',
			}
		}
	},
	wrapper: {
		marginBottom: 60,
		borderRadius: '0 0 6px 6px',
		':last-child': {
			marginBottom: 0,
		},
		h3: {
			color: 'primary',
			fontSize: ['17px', 2],
		},
		paddingBottom: 30,
		'@media only screen and (max-width: 767px)': {
			paddingBottom: 0,
		},
	},
	header: {
		width: '100%',
		justifyContent: ['space-between', 'flex-start'],
		marginBottom: [20, 30],
		button: {
			textDecoration: 'underline',
			marginLeft: [0, '5px'],
		},
		h2: {
			fontSize: '30px',
			lineHeight: '1.45',
			color: '#3D3D3D',
			fontWeight: 600,
			marginBottom: 20
		},
		titleIcon: {
			display: 'block',
			margin: '0 auto',
			paddingBottom: '8px'
		},
	},
	productGrid: {
		gridGap: '20px',
		gridTemplateColumns: [
			'1fr 1fr',
			'1fr 1fr 1fr',
			'1fr 1fr 1fr 1fr',
			//'1fr 1fr 1fr 1fr 1fr',
		],
		marginBottom: [-15, -30],
		'@media only screen and (max-width: 536px)': {
			display: 'contents',
			padding:0,
			margin: 0
		},
		
		'@media only screen and (min-width: 536px)': {
			float: 'left'
		},
	},
	filterOption: {
		backgroundColor: '#dae8db',
		borderRadius: '20px',
		color: '#00d4c5',
		
		textDecoration: 'none!important',
		fontWeight: 'normal',
		'&:hover': {
			backgroundColor: '#00d4c5',
			color: 'white' 

		},
		'&.active': {
			backgroundColor: '#00d4c5',
			color: 'white'
		},
	},
	productFilter: {
		'@media only screen and (min-width: 930px)': {
			overflowX: "initial",
		},
		button: {
			margin: '5px',
			fontSize: '14px',
			paddingLeft: '30px',
			paddingRight: '30px',
			minHeight: 0,
			minWidth: 0,
			'@media only screen and (max-width: 767px)': {
				minWidth: "max-content",
			},
		},
		inner: {
			display: 'flex',
			'@media only screen and (max-width: 767px)': {
				margin: "0 auto",
				marginBottom: '20px',	
			},
		}
	},
	filterSelect: {
		'@media only screen and (min-width: 536px)': {
			float: 'right'	
		},
		
	}
};

export default styles;
