import Currency from "currency.js";
import { twoDecimalPlaces } from "../helper/number-helper";
import React, { useState } from "react";
import {
  getLocalState,
  setLocalState,
  removeLocalStateItem,
} from "../helper/local-storage";
import { applyVAT, getAppliedVatPrice } from "../helper/product-helper";
import Spinner from "react-bootstrap/Spinner";

export const CART_PRODUCTS = "cartProducts";
export const SMOKERS_PRODUCTS = "smokersProducts";
export const TOTAL_PRICE = "totalPrice";
export const TOTAL_TWIN_PRICE = "totalTwinPrice";
export const TOTAL_VAT_PRICE = "totalVatPrice";
export const CURRENCY = "currency";

/**
 * @interface CartProduct
 * @field {price} Sale and VAT applied
 */
export interface CartProduct {
  title: string;
  price: number;
  priceBeforeVat: number;
  salePriceBeforeVat: number;
  vatRate: number;
  currency: string;
  thumbnail: any;
  imageSrc: string;
  uid: string | number;
  quantity: number;
  boxItems: CartProduct[];
  subscriptionOption: any;
  availableToSmokers: boolean;
  availableToInternational: boolean;
  isPackageProduct: boolean;
  isGiftVoucher: boolean;
  isCustomGiftVoucher: boolean;
  expectingTwins: boolean;
  expectingTwinsPrice: number;
  availableForSale: boolean;
  description: string;
  onSale: boolean;
  salePrice: number;
  twinOptions: any;
  showTwinsOption: boolean;
  extraItem: string;
  methodItem: string;
}

interface LocalCartContextProps {
  products: CartProduct[];
  smokersProducts:  CartProduct[];
  add: Function;
  addSmokerProduct: Function;
  updateSmokersProduct: Function;
  update: Function;
  clearCart: Function;
  totalPrice: number;
  totalVatPrice: number;
  totalTwinPrice: number;
  currency: string;
  calculateSingleProductVatPrice: Function;
  updateExtraItem: Function;
  updateMethodItem: Function;
}

export const LocalCartContext = React.createContext(
  {} as LocalCartContextProps
);

const getCartProducts = () => {
  const products = getLocalState(CART_PRODUCTS);
  return products ? products : [];
};

const getSmokersCartProducts = () => {
  const products = getLocalState(SMOKERS_PRODUCTS);
  return products ? products : [];
};

const getTotalPrice = () => {
  const price = getLocalState(TOTAL_PRICE);
  return price ? price : 0;
};

const getTotalTwinPrice = () => {
  const price = getLocalState(TOTAL_TWIN_PRICE);
  return price ? price : 0;
};

const getTotalVatPrice = () => {
  const price = getLocalState(TOTAL_VAT_PRICE);
  return price ? price : 0;
};

const getCurrency = () => {
  const currency = getLocalState(CURRENCY);
  return currency ? currency : "GBP";
};

const calculateTotalPrice = (products: CartProduct[]): number => {
  let total: number = 0;
  products.forEach((product) => {
    total += calculateSingleProductPrice(product);
  });

  return total;
};

const calculateTotalVatPrice = (products: CartProduct[]): number => {
  let total: number = 0;
  products.forEach((product) => {
    total += calculateSingleProductVatPrice(product);
  });
  return total;
};

const calculateTotalTwinPrice = (products: CartProduct[]): number => {
  let total: number = 0;
  products.forEach((product) => {
    total += applyVAT(product.expectingTwinsPrice, product.vatRate)
  });
  return total;
};
const calculateSingleProductPrice = (product: CartProduct): number => {
  const price = product.price * product.quantity;
  return price;
};

const calculateSingleProductVatPrice = (product: CartProduct): number => {
  let total = Currency(0);
  let appliedVat = 0;
  let finalVat = 0;
  var productPrice = product.salePriceBeforeVat?  product.salePriceBeforeVat : product.priceBeforeVat;
  
  if (product.vatRate) {
    const twinPrice = product?.expectingTwinsPrice == undefined ? 0 : product?.expectingTwinsPrice;

    appliedVat = twoDecimalPlaces(
      getAppliedVatPrice(productPrice + twinPrice, product.vatRate)
    );
  }
  const quantity = product.quantity ? product.quantity : 1;
  const itemVat = Currency(quantity).multiply(appliedVat);

  total = Currency(total).add(itemVat);
  finalVat = Number(total.value);

  return finalVat;
};

const LocalCartProvider = ({ children }: any) => {
  const [products, setProducts] = useState([]);
  const [smokersProducts, setSmokersProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalVatPrice, setTotalVatPrice] = useState(0);
  const [totalTwinPrice, setTotalTwinPrice] = useState(0);
  const [currency, setCurrency] = useState("GBP");
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    setProducts(getCartProducts());
    setSmokersProducts(getSmokersCartProducts());
    setTotalPrice(getTotalPrice());
    setTotalTwinPrice(getTotalTwinPrice());
    setTotalVatPrice(getTotalVatPrice());
    setCurrency(getCurrency());
    setLoading(false);
  }, []);

  const setPrice = () => {
    const price = calculateTotalPrice(products);
    setLocalState(TOTAL_PRICE, price);
    setTotalPrice(price);
  };

  const setVatPrice = () => {
    const vatPrice = calculateTotalVatPrice(products);
    setLocalState(TOTAL_VAT_PRICE, vatPrice);
    setTotalVatPrice(vatPrice);
  };

  const setTwinPrice = () => {
    const twinPrice = calculateTotalTwinPrice(products);
    setLocalState(TOTAL_TWIN_PRICE, twinPrice);
    setTotalTwinPrice(twinPrice);
  };

  const setCurrencyData = () => {
    const currency = products && products.length ? products[0].currency : null;
    setLocalState(CURRENCY, currency);
    setCurrency(currency);
  };

  const add = (item: CartProduct) => {  
    if (products.length) {
      const index = products.findIndex(
        (product: CartProduct) => product.uid === item.uid
      );

      // do not allow products to be added
      if (index !== -1) {
        // if product already available in the basket

        const product = products[index];
        const quantity = product.quantity ? product.quantity : 0;
        products[index] = { ...product, ...item, quantity: quantity + 1 }; // just increase the quantity
      } else {
        // if this product is not available in the basket
        products.push({ ...item, quantity: 1 });
      }
    } else {
      // if the cart is empty
      products.push({ ...item, quantity: 1 });
    }
    setLocalState(CART_PRODUCTS, products);
    setProducts([...products]); // have to use the shallow copy [...products]
    setPrice();
    setVatPrice();
    setCurrencyData();
    setTwinPrice();
  };

  const addSmokerProduct = (item: CartProduct) => {
    if (smokersProducts.length) {
      const index = smokersProducts.findIndex(
        (product: CartProduct) => product.uid === item.uid
      );

      // do not allow products to be added
      if (index !== -1) {
        // if product already available in the basket

        const product = smokersProducts[index];
        const quantity = product.quantity ? product.quantity : 0;
        smokersProducts[index] = { ...product, ...item, quantity: quantity + 1 }; // just increase the quantity
      } else {
        // if this product is not available in the basket
        smokersProducts.push({ ...item, quantity: 1 });
      }
    } else {
      // if the cart is empty
      smokersProducts.push({ ...item, quantity: 1 });
    }
    setLocalState(SMOKERS_PRODUCTS, smokersProducts);
    setSmokersProducts([...smokersProducts]); // have to use the shallow copy [...products]
  };

  const updateExtraItem = (uid: string, extraItem: string) => {
    const index = products.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    if (index > -1) {
      const product = products[index];
      product.extraItem = extraItem;

      setLocalState(CART_PRODUCTS, products);
      setProducts([...products]);

      updateSmokersExtraItem(uid, extraItem)
    }
  };

  const updateSmokersExtraItem = (uid: string, extraItem: string) => {
    const index = smokersProducts.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    if (index > -1) {
      const product = smokersProducts[index];
      product.extraItem = extraItem;

      setLocalState(SMOKERS_PRODUCTS, smokersProducts);
      setProducts([...smokersProducts]);
    }
  };

  const updateMethodItem = (uid: string, methodItem: string) => {
    const index = products.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    if (index > -1) {
      const product = products[index];
      product.methodItem = methodItem

      setLocalState(CART_PRODUCTS, products);
      setProducts([...products]);

      updateSmokersMethodItem(uid, methodItem);
    }
  };

  const updateSmokersMethodItem = (uid: string, methodItem: string) => {
    const index = smokersProducts.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    if (index > -1) {
      const product = smokersProducts[index];
      product.methodItem = methodItem

      setLocalState(SMOKERS_PRODUCTS, smokersProducts);
      setProducts([...smokersProducts]);
    }
    
  };

  const updateSmokersProduct = (uid: string, quantity: number, price: number = 0, expectingTwins: boolean = false, expectingTwinsValue: number = 0) => {
    const index = smokersProducts.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    const product = smokersProducts[index];

    if (quantity < 1 && index > -1) {
      // delete if quantity, 0
      smokersProducts.splice(index, 1);
    } else {
      // update quantity
      smokersProducts[index] = { ...product, quantity };
    }

    if (price > 0) {
      const expectingTwinsPrice = !expectingTwins ? 0 : expectingTwinsValue;
      smokersProducts[index] = { ...product, price, expectingTwins, expectingTwinsPrice };
    }

    setLocalState(SMOKERS_PRODUCTS, smokersProducts);
    setSmokersProducts([...smokersProducts]);    
  };


  const update = (uid: string, quantity: number, price: number = 0, expectingTwins: boolean = false, expectingTwinsValue: number = 0) => {
    const index = products.findIndex(
      (product: CartProduct) => product.uid === uid
    );

    const product = products[index];

    if (quantity < 1 && index > -1) {
      // delete if quantity, 0
      products.splice(index, 1);
    } else {
      // update quantity
      products[index] = { ...product, quantity };
    }

    if (price > 0) {
      const expectingTwinsPrice = !expectingTwins ? 0 : expectingTwinsValue;
      products[index] = { ...product, price, expectingTwins, expectingTwinsPrice };
    }

    setLocalState(CART_PRODUCTS, products);
    setProducts([...products]);
    setPrice();
    setVatPrice();
    removeGiftcardFromLocalStorage(products);
    setTwinPrice();
  };

  const removeGiftcardFromLocalStorage = (products: any) => {
    if (
      products.filter(function (item: any) {
        return item.uid === "0-gift-voucher";
      }) < 1
    ) {
      removeLocalStateItem("giftcard");
    }
  };

  const clearCart = () => {
    setLocalState(CART_PRODUCTS, []);
    setLocalState(SMOKERS_PRODUCTS, []);
    setLocalState(TOTAL_PRICE, 0);
    setLocalState(TOTAL_VAT_PRICE, 0);
    setLocalState(TOTAL_TWIN_PRICE, 0);
    setProducts([]);
    setSmokersProducts([]);
    setTotalPrice(0);
    setTotalVatPrice(0);
    setTotalTwinPrice(0);
  };

  return loading ? (
    <div className="w-100 h-100 text-center align-middle p-5">
      <Spinner animation="border" role="status">
        <span className="sr-only"></span>
      </Spinner>
    </div>
  ) : (
    <LocalCartContext.Provider
      value={{
        products,
        smokersProducts,
        add,
        addSmokerProduct,
        updateSmokersProduct,
        update,
        clearCart,
        totalPrice,
        totalVatPrice,
        totalTwinPrice,
        currency,
        calculateSingleProductVatPrice,
        updateExtraItem,
        updateMethodItem
      }}
    >
      {children}
    </LocalCartContext.Provider>
  );
};

export default LocalCartProvider;
