/** @jsx jsx */
import "bootstrap/dist/css/bootstrap.min.css";
import { Box, jsx } from "theme-ui";
import styles from "./top-menu.style";

export interface TopMenuStyle {
    backgroundColor?: string,
    minHeight?: number,
    textAlign?: string,
    lineHeight?: string
    childStyles?: TopMenuChildStyle
}

export interface TopMenuChildStyle {
    width?: string,
    fontSize? : string
}

const TopMenu: React.FunctionComponent<{topMenuStyle?:TopMenuStyle}> = ({ children, topMenuStyle}) => {
    return (
        <Box sx={topMenuStyle ?? styles.topMenu}>
            <div className="container">
                <Box sx={topMenuStyle?.childStyles ?? styles.topMenuChild}>
                    {children}
                </Box>
            </div>
        </Box>
    );
};

export default TopMenu;
