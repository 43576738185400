const styles = {
    header: {
		position: 'relative',
		borderBottom: `1px solid #EBEBEB`,
		marginBottom: ['25px', '30px'],
		h1: {
			fontSize: '32px',
			lineHeight: '1.45',
			margin: 0,
			marginBottom: '20px',
			clear: 'none',
			color: '#3D3D3D',
			'@media only screen and (max-width: 500px)': {
				fontSize: '25px',
				marginTop: '10px!important',
			},
		}
	},
    priceWrapper: {
		margin: '0 0px 20px 0px'
	},
	price: {
		fontSize: '26px',
		fontWeight: 600,
		color: '#685f52',
		sale: {
			color: 'red',
			textDecoration: 'line-through',
			paddingRight: 10,
			fontSize: '16px',
			fontWeight: 'normal',
		},
		display: 'block'
	},
    outerWrapper: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'muted',
        marginBottom: 10,
        paddingBottom: '10px',
        '@media only screen and (max-width: 500px)': {
            paddingBottom:'36px!important'
        },
    },
    wrapper: {
        position: 'relative',
        pointerEvent: 'all',
        '&.disabled': {
            opacity: 0.6,
            cursor: 'not-allowed',
            pointerEvents: 'none',
        },
        '&:hover': {
            button: {
                opacity: 1,
                visibility: 'visible',
            },
        },
        '@media only screen and (max-width: 500px)': {
			paddingLeft: '0!important',
			paddingRight: '0!important',
		}
    },
    imageWrapper: {
        '.gatsby-image-wrapper, .content': {
            width: '100px',
            height: '100px'
        },
        width: '100px',
        height: '100px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 6,
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        button: {
            opacity: 0,
            visibility: 'hidden',
            position: 'absolute',
            top: 0,
            width: '100px',
            height: '100px',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            color: 'white',
            transition: 'all 0.3s ease',
        },
        '@media only screen and (max-width: 500px)': {
            display: 'none'
		},
    },
    boxImage: {
        width: [45, 45],
        height: [45, 45],
        overflow: 'hidden',
        borderRadius: 6,
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        display: 'inline-block',
        verticalAlign: 'top'
    },
    contentWrapper: {
        justifyContent: 'space-between',
        a: {
            fontSize: '14px',
            fontWeight: '600',
            color: '#685f52',
            cursor: 'pointer'
        }
    },
    boxItemWrapper: {
        marginBottom: 10,
    },
    boxDetails: {
        display: 'inline-block',
        marginLeft: 10
    },
    title: {
        lineHeight: 2,
    },
    titleOnly: {
        lineHeight: 2,
        fontSize: '14px',
        fontWeight: '600',
        color: '#528959',
    },
    subtitle: {
        fontSize: 0,
        lineHeight: 2,
    },
    productInfo: {
        color: 'darkgray',
        fontSize: 0,
        fontWeight: 'normal',
        marginTop: '10px',
        display:'block'
    },
    totalPrice: {
        color: 'primary',
        fontWeight: '700',
        fontSize: '15px',
        float: 'right',
        display: 'inline-table'
    },
    counterWrapper: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: [20, 0],
        '@media only screen and (max-width: 767px)': {
            display: 'contents'
        },
    },
    cartCounter: {
        position: 'absolute',
        top: '70px',
        right: 0,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        minHeight: [36, 40],
        overflow: 'hidden',
        backgroundColor: 'white',
        transition: 'all 0.2s ease',
        padding: 0,
        background: '#00d4c5',
        div: {
            width: 50,
            color: 'white',
            textAlign: 'center',
            fontSize: 0,
            fontWeight: '700',
            paddingLeft: 0,
            paddingRight: 0,
            background: '#00d4c5',
        },
        button: {
            padding: 0,
            minWidth: [32, 20],
            minHeight: [36, 40],
            svg: {
                width: 20,
                height: 20,
                marginLeft: '5px',
                marginRight: '5px'
            },
            margin: '0 auto'
        },
        decrement: {
            background: '#00d4c5',
            color: 'white'
        },
        increment: {
            background: '#00d4c5',
            color: 'white'
        },
        '@media only screen and (max-width: 300px)': {
            width: '83px',
        },
        '@media only screen and (max-width: 501px)': {
            marginTop: '-25px'
        }
    },
    productTypes: {
        display: 'block',
        marginBottom: '5px',
        marginTop: ' 5px'
    },
    item: {
        maxWidth: '120px',
        '@media only screen and (min-width: 522px)': {
            width: '130px',
        }
    },
    removeButton: {
        background: '#dc3545',
        color: 'white',
        svg: {
            width: 20,
            height: 20,
            marginLeft: '5px',
            marginRight: '5px'
        },
        padding: '10px!important'
    },
    addButton: {
        background: '#00d4c5',
        color: 'white',
        svg: {
            width: 20,
            height: 20,
            marginLeft: '5px',
            marginRight: '5px'
        },
        padding: '10px!important'
    },
    button: {
		fontSize: '14px',
		color: 'white',
		fontWeight: '600',
		padding: '12.5px 25px',
		borderRadius: '10px',   
		background: '#00d4c5',
		width: '100%',
		textTransform: 'uppercase'
	},
    modal: {
		image: {
			margin: '0 auto', 
			display: 'block', 
		},
		divider: {
			marginTop: '20px',
			 marginBottom: '20px'
		},
		description: {
			overflowY: 'scroll' 
		},
		footer: {
			width: '100%', 
			margin: '0 auto', 
			display: 'block'
		},
		button: {
			zIndex: '9999!important',
			width: '100%',
			textDecoration: 'unset',
			textTransform: 'uppercase',
			display: 'flex',
			opacity: 1,
			transform: 'scale(1)',
			'&:disabled': {
				opacity: 0.6,
				cursor: 'not-allowed',
				backgroundColor: "#E6E6E6",
				borderColor: "#E6E6E6",
				color: "#878788",
				fontWeight: 500
			},
			':hover': {
				backgroundColor: 'white',
				color: '#9a9b9a',
				fontSize: 0,
				border: '1px solid #ccc',
				'.product-card-cta': {
					color: '#5C9963'
				},
			},
			backgroundColor: '#00d4c5',
			fontWeight: '400',
			fontSize: '16px',
			color: 'white',
			borderRadius: '10px',
			padding: 0,
			icon: {
				marginTop: '-6px',
				color: 'white',
				fontSize: 15
			},
			link: {
				lineHeight: '40px',
				margin: '0 auto'
			}
		},
		close: {
			position: 'absolute',
			right: '3px',
			fontSize: '40px',
			top: '5px',
			cursor: 'pointer'
		},
		productAvailabilityLabel: {
			position: 'absolute',
			whiteSpace: 'nowrap',
			left: '130px',
			top: '25px'
		}
	},
    cartBtn: {
		width: 'max-content',
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		transition: 'all 0.2s ease',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			backgroundColor: "#E6E6E6",
			borderColor: "#E6E6E6",
			color: "#878788",
			fontWeight: 500
		},
		backgroundColor: '#00d4c5',
		fontWeight: 'normal',
		fontSize: '13px',
		color: 'white',
		borderRadius: '10px',
		padding: '15px 20px 15px 20px',
		'@media only screen and (min-width: 768px) and (min-width: 1400px)': {
			minWidth: '0!important',
			marginTop: '0'

		},
		minWidth: '100%!important',
		minHeight: '40px!important',
		svg: {
			margin: 0,
			width: '23px',
			height: '23px',
			marginTop: '-8px',
		},
		link: {
			lineHeight: '40px',
			margin: '0 auto'
		},
        float: 'right'
	},
    input: {
		width: '100%',
		fontSize: '14px',
		fontWeight: '400',
		borderRadius: 6,
		padding: '13px',
		opacity: '.7',
		marginBottom: '10px',
		borderWidth: '1px',
		borderStyle: 'solid',
		boxShadow: 'none',
		transition: 'border-color 0.2s ease',
		borderColor: '#E4E4E4',
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
			borderColor: 'darkgray',
		},
		error: {
			fontSize: '13px',
			marginTop: '-22px',
			paddingBottom: '5px',
			color: '#dc3545'
		},
		readOnly: {
			border: 0,
			backgroundColor: 'unset!important',
			'&:hover': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
			'&:focus': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
		}
	},
    form: {
        label: {
            fontWeight: '600',
            marginBottom: '0.5em',
            color: '#2F3E57'
        },
        control: {
            // padding: '6px 12px'
        },
        input: {
            width: '100%',
            fontSize: '14px!important',
            fontWeight: '400',
            borderRadius: 6,
            padding: '10px',
            opacity: '.7',
            marginBottom: '10px',
            borderWidth: '1px',
            borderStyle: 'solid',
            boxShadow: 'none',
            transition: 'border-color 0.2s ease',
            borderColor: '#E4E4E4',
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: 'darkgray',
            },
            error: {
                fontSize: '13px!important',
                marginTop: '-5px',
                paddingBottom: '5px',
                color: '#dc3545'
            },
            readOnly: {
                border: 0,
                backgroundColor: 'unset!important',
                '&:hover': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
                '&:focus': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
            },
        },
        group: {
            paddingLeft: 0,
            marginBottom: 10
        },
        subSection: {
            header: {
                fontWeight: '600',
                fontSize: '24px!important',
                marginBottom: 0,
                '@media only screen and (max-width: 674px)': {
                    fontSize: '20px!important',
                }
            },
            label: {
                fontSize: '14px!important',
                marginBottom: '0.5em'
            }
        },
    },
};

export default styles;
