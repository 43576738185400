/** @jsx jsx */
import { graphql, Link, StaticQuery } from "gatsby";
import get from "lodash/get";
import { useEffect, useRef,useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Box, jsx, Button } from "theme-ui";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { getJwtToken, getJwtTokenIdentifier } from "../../../../../services/auth-service";
import { Logout } from "../../../../../services/user-service";
import styles from "./navbar.style";
import Spinner from "react-bootstrap/Spinner";

const menuStaticQuery = graphql`
  {
    allCategoryData: allPrismicCategory {
      totalCount
      nodes {
        uid
        data {
          title {
            text
            html
            richText
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
    subCategoryData: allPrismicSubcategory {
      totalCount
      nodes {
        uid
        data {
          redirects_to_product {
            url
          }
          title {
            text
            html
            richText
          }
          parent_category {
            document {
              ... on PrismicCategory {
                data {
                  title {
                    text
                    html
                    richText
                  }
                }
                uid
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
    allPrismicCommon {
      nodes {
        data {
          contact_telephone {
            text
            html
            richText
          }
        }
      }
    }
  }
`;

const getMenuData = (categories: any[], subCategories: any[]) => {
  const data: any[] = [];
  // fill up first parent
  categories.forEach((category) => {
    if (category?.data) {
      const uid = category.uid;
      const text = category.data?.title?.text;
      if (uid && text) {
        data.push({ path: uid, title: text, pathPrefix: "" });
      }
    }
  });

  // order categories
  data.sort((a, b) => a.title.localeCompare(b.title));

  // fill up child
  subCategories.forEach((category) => {
    if (category?.data) {
      const parent = category.data.parent_category?.document?.uid;
      const uid = category.uid;
      const text = category.data.title?.text;
      const index = data.findIndex((item) => item.path === parent);
      const pathPrefix =
        category?.data?.redirects_to_product?.url != null ? "/product" : "";
      if (index > -1) {
        if (data[index].submenu && data[index].submenu.length) {
          data[index].submenu.push({
            path: uid,
            title: text,
            pathPrefix: pathPrefix,
          });
        } else {
          data[index].submenu = [];
          data[index].submenu.push({
            path: uid,
            title: text,
            pathPrefix: pathPrefix,
          });
        }
      }
    }
  });

  data.forEach(({ submenu }: any) => {
    if (submenu !== undefined)
      submenu.sort((a: any, b: any) => a.title.localeCompare(b.title));
  });
  return data;
};

const MainMenu: React.FC<{
  onClick?: () => void;
  showAccountControl: boolean;
}> = ({ onClick, showAccountControl }) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${menuStaticQuery}`}
    render={(data) => {
      const { allCategoryData, subCategoryData } = data;
      const categories = [...allCategoryData.nodes];
      const subCategories = [...subCategoryData.nodes];
      const menuData = getMenuData(categories, subCategories);
      const mainMenu = useRef(null);
      const windowSize = useWindowSize();
      const footerData = get(data, "allPrismicCommon.nodes[0].data");
      const telphoneNumber = footerData.contact_telephone[0]?.text;
      const [loading, setLoading] = useState(false)

      useEffect(() => {
        if (windowSize && windowSize < 1081) {
          const menu = mainMenu.current.children;
          for (let index = 0; index < menu.length; index++) {
            const element = menu[index];
            element.addEventListener("click", function () {
              element.classList.toggle("active");
            });
          }
        }
      }, [windowSize]);

      const logout = async () => {
        setLoading(true);
        await Logout(getJwtTokenIdentifier()).then(async () => {
          setLoading(false);
        });
      };

      const token = getJwtToken();

      return (
        <Box as="ul" ref={mainMenu} sx={styles.mainMenu} className="mainMenu">
          <Box as="li" sx={styles.mainMenuItem}>
            <Link
              activeClassName="active"
              to={`/`}
              onClick={onClick}
            >Home
            </Link>

            <Box as="ul" sx={styles.submenu}>
              <Box as="li" >
                <Link
                  to='/why-do-it'
                  onClick={onClick}
                >
                  Why Do It
                </Link>
              </Box>
              <Box as="li" >
                <Link
                  to='/lab'
                  onClick={onClick}
                >
                  Lab and Collection
                </Link>
              </Box>
            </Box>
            <IoIosArrowDown />
          </Box>

          <Box as="li" sx={styles.mainMenuItem}>
            <Link
              activeClassName="active"
              to={`/about-us`}
              onClick={onClick}
            >
              About
            </Link>
          </Box>

          <Box as="li" sx={styles.mainMenuItem}>
            <Link
              activeClassName="active"
              to={`/faq`}
              onClick={onClick}
            >
              FAQ's
            </Link>
          </Box>

          <Box as="li" sx={styles.mainMenuItem}>
            <Link
              activeClassName="active"
              to={`/testimonials`}
              onClick={onClick}
            >
              Testimonials
            </Link>
          </Box>

          <Box as="li" sx={styles.mainMenuItem}>
            <Link
              activeClassName="active"
              to={`/shop`}
              onClick={onClick}
            >
              Shop
            </Link>

            <Box as="ul" sx={styles.submenu}>
              {menuData.map((item) => (
                <Box as="li" key={item.title}>
                  <Link
                    activeClassName="active"
                    to={`/${item.path}`}
                    onClick={onClick}
                  >
                    {item.title}
                  </Link>
                </Box>
              ))}
            </Box>
            <IoIosArrowDown />
          </Box>

          {showAccountControl && (
            <Box as="span" style={{ width: "100%" }}>
              <hr />
             
              {!token ? (
                <Box
                  as="li"
                  key={" "}
                  sx={styles.mainMenuItem}
                  style={{ fontWeight: 600 }}
                >
                 <Link activeClassName="active" to="/login" onClick={onClick} sx={styles.login}>
                    <Button sx={styles.accountControlButton}>
                      Sign In
                    </Button>
                  </Link>
                </Box>
              ) : (
                <span>
                  <Box as="li" sx={styles.mainMenuItem}
                    style={{ fontWeight: 600 }} >
                    <Link activeClassName="active" to="/account/orders">
                      My Orders
                    </Link>
                  </Box>

                  <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account/email">
                      My Email
                    </Link>
                  </Box>

                  <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account/password">
                      My Password
                    </Link>
                  </Box>
                  <hr />
                  <Button sx={styles.accountControlButton}
                    onClick={() => logout()}
                    onKeyDown={() => logout()}>
                    {!loading && <span id="signout">Sign Out</span>}

                    {loading && (
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    )}
                  </Button>
                </span>
              )}
            </Box>
          )}
        </Box>
      );
    }}
  />
);

export default MainMenu;
