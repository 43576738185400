/** @jsx jsx */
import { graphql, Link, StaticQuery } from "gatsby";
import { Image, jsx } from "theme-ui";
import styles from "./logo.style";

type PropsType = {
  imgSrc?: string;
  imgAlt?: string;
  path?: string;
};

const logoStaticQuery = graphql`
{
	allPrismicCommon {
	  nodes {
		data {
		  logo {
			alt
			copyright
			url
			gatsbyImageData
		  }
		}
	  }
	}
  }
`;

const Logo: React.FC<PropsType> = ({ imgSrc, imgAlt, path = "/" }) => {
  return (
    <StaticQuery<GatsbyTypes.Query>
      query={`${logoStaticQuery}`}
      render={({ allPrismicCommon }) => {
        const logo = allPrismicCommon?.nodes[0].data?.logo;

        return (
          <div sx={styles.wrapper} className="logo">
            <Link to={path}>
              <Image
                sx={styles.logo}
                src={imgSrc ? imgSrc : logo.url}
                alt={imgAlt ? imgAlt : logo.alt}
              />
            </Link>
          </div>
        );
      }}
    />
  );
};

export default Logo;
