/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import SocialLink from './social-link';
import styles from './social-links.style';

export interface SocialLink {
	type: string;
	link: string;
}

type PropsType = {
	items: SocialLink[];
};

const SocialLinks: React.FC<PropsType> = ({items}: PropsType) => {
	return (
		<Box sx={styles.socialLinks} className="socialLinks">
			{items && items.map((item) => <SocialLink key={item.type} item={item} />)}
		</Box>
	);
};

export default SocialLinks;
