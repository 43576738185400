/** @jsx jsx */
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from '@prismicio/react'
import { useState } from "react";
import { FiCalendar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { Box, Button, jsx } from "theme-ui";
import { Breadcrumb } from "../components/breadcrumbs/breadcrumbs";
import LatestBlogPosts from "../components/latest-blog-posts";
import PrimaryLayout from "../components/layout/primary/primary";
import Seo from "../components/seo";
import BlogTopMenu from "../components/top-menus/blog/blog-top-menu";
import "../css/blog.css";
import {
  formatBlogDate,
  getUniqueMonths,
  getUniqueTags,
} from "../helper/blog-helper";

const BlogPost = ({ data }: any) => {
  const post = formatBlogDate(data.prismicBlog.data);
  const uniqueMonths = getUniqueMonths(data);
  const uniqueTags = getUniqueTags(data);
  const [searchTerm, setSearch] = useState("");

  const breadcrumbs: Breadcrumb[] = [
    {
      label: "Home",
      pathname: "/",
    },
    {
      label: "Blog",
      pathname: "/blog",
    },
    {
      label: post.title,
      pathname: `/blog/${data.prismicBlog?.uid}`,
    },
  ];

  const handleSearch = () => {
    navigate("/blog/?search=" + searchTerm);
  };

  return (
    <PrimaryLayout
      topMenuChild={BlogTopMenu.bind(null, { breadcrumbs: breadcrumbs })}
    >
      <Seo title={post.title} description={post.description} />

      <div sx={styles.container} className="container container-sm">
        <div className="row">
          <div sx={styles.sideMenuWrapper} className="col-md-4">
            <aside className="widget-area">
              <Box as="label" sx={styles.fieldWrapper} className="search">
                <Button onClick={() => handleSearch()}>
                  <svg
                    className="mobile-search-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
                      transform="translate(-4 -1.525)"
                      fillRule="evenodd"
                    />
                  </svg>
                </Button>

                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                  autoComplete="off"
                  sx={styles.search.input}
                />
              </Box>

              <LatestBlogPosts limitAmount={15} title="Latest Posts" />
              <section className="widget widget_categories">
                <Box sx={styles.sideMenu}>
                  <div className="header">Categories</div>
                  <div className="body">
                    <table sx={styles.sideMenuTable}>
                      <tbody>
                        {uniqueTags
                          .slice(0, 20)
                          .map((item: any, index: number) => (
                            <tr key={index}>
                              <td>
                                <Link to={`/blog/?category=${item.tag}`}>
                                  {item.tag}
                                </Link>
                              </td>
                              <td sx={styles.sideMenuTable.count}>
                                <span className="count">{item.count}</span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Box>
              </section>

              <section className="widget widget_archive">
                <Box sx={styles.sideMenu}>
                  <div className="header">Archives</div>
                  <div className="body">
                    <table sx={styles.sideMenuTable}>
                      <tbody>
                        {uniqueMonths.slice(0, 7).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={`/blog/?date=${item.date.replace(
                                    " ",
                                    "-"
                                  )}`}
                                >
                                  {item.date}
                                </Link>
                              </td>
                              <td sx={styles.sideMenuTable.count}>
                                <span className="count">{item.count}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Box>
              </section>
            </aside>
          </div>

          <div className="col-sm-12 col-md-8 col-lg-8">
            <div sx={styles.header}>
              <div sx={styles.header.tags}>
                {post.tags.map(({ blog_tags }: any, i: number) => {
                  return (
                    <Link
                      to={`/blog/?tag=${blog_tags.document?.data.name.replace(
                        " ",
                        "-"
                      )}`}
                      key={i}
                    >
                      {blog_tags.document?.data.name}
                    </Link>
                  );
                })}
              </div>
              <ul>
                <li>
                  <FiCalendar /> {post.date}
                </li>
                <span>|</span>
                <li>
                  <FaRegUser /> by {post.author}
                </li>
              </ul>
              <h1>{post.title}</h1>
            </div>
            <div className="blog-details-desc">
              <div sx={styles.articleImage}>
                <GatsbyImage
                  image={post.blog_image.gatsbyImageData}
                  alt={post.blog_image.alt ?? ""}
                />
              </div>

              <div className="article-content">
                <hr className="bar-indent-center" />
                <PrismicRichText field={post.content.richText} />
                <div className="article-footer">
                  <div className="article-tags">
                    {post.tags.map(({ blog_tags }: any, index: number) => {
                      return (
                        <Link
                          to={`/blog/?tag=${blog_tags.document?.data.name.replace(
                            " ",
                            "-"
                          )}`}
                          key={index}
                          className="mb-1"
                        >
                          {blog_tags.document?.data.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};

const styles = {
  container: {
    marginTop: 40,
    "@media only screen and (max-width: 992px)": {
      // padding: 0,
      ".container": {
        // maxWidth: "100%",
      },
      "@media only screen and (max-width: 536px)": {
        marginTop: 20,
      },
    },
  },
  header: {
    h1: {
      fontSize: "32px",
      fontweight: 600,
      marginBottom: "12px",
      "@media only screen and (max-width: 536px)": {
        paddingTop: "15px",
        paddingBottom: "15px",
        fontSize: "25px",
      },
    },
    ul: {
      display: "flex",
      listStyle: "none",
      margin: 0,
      padding: 0,
      paddingTop: "5px",
      paddingBottom: "5px",
      li: {
        fontSize: "14px",
        color: "#9f9f9f",
        svg: {
          marginTop: "-6px",
          color: "#685f52",
          fontSize: "16px",
          marginRight: "9px",
        },
      },
      span: {
        paddingRight: 10,
        paddingLeft: 10,
        color: "#9f9f9f",
      },
    },
    tags: {
      a: {
        fontSize: "10px",
        textTransform: "uppercase",
        display: "inline-block",
        padding: "4px 12px",
        borderRadius: "10px",
        fontWeight: "500",
        letterSpacing: "0.5px",
        background: "#00d4c5",
        color: "#fff",
        marginRight: "5px",
        textDecoration: 0,
        ":hover": {
          opacity: ".9",
          color: "white",
        },
      },
    },
  },
  search: {
    input: {
      backgroundColor: "#F9F9F9",
      marginBottom: "30px",
      "@media only screen and (min-width: 766px) and (max-width: 1001px)": {
        marginBottom: 0,
      },
    },
  },
  fieldWrapper: {
    alignItems: "center",
    position: "relative",
    width: "100%",
    input: {
      width: "100%",
      fontSize: 1,
      fontWight: "400",
      borderRadius: "6px",
      padding: "14px 25px 14px 10px",
      backgroundColor: "#f5f5f5",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#E4E4E4",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "#685f52",
      },
    },
    button: {
      border: "0",
      background: "unset",
      margin: 0,
      minWidth: 0,
      minHeight: 0,
      width: "30px",
      position: "absolute",
      right: 20,
      padding: 0,
      top: 18,
      svg: {
        fill: "text",
        "&.mobile-search-icon": {
          pointerEvents: "none",
        },
      },
    },
  },
  articleImage: {
    img: {
      borderRadius: "10px",
    },
  },
  sideMenuWrapper: {
    paddingRight: "30px",
    "@media only screen and (max-width: 766px)": {
      display: "none",
    },
    "@media (width: 766px)": {
      display: "none!important",
    },
  },
  sideMenu: {
    marginBottom: 30,
    ".header": {
      width: "100%",
      padding: "15px 17px",
      background: "#00d4c5",
      color: "#fff",
      borderRadius: "5px 5px 0 0",
      fontSize: "14px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    ".body": {
      background: "white",
      border: "1px solid #E4E4E4",
      borderTop: 0,
      borderRadius: "0 0 5px 5px",
    },
  },
  sideMenuTable: {
    width: "100%",
    td: {
      width: "100%",
      color: "#656766",
      fontWeight: 500,
      cursor: "pointer",
      padding: "10px 20px 10px 20px",
      "&:hover": {
        color: "#528959",
      },
      a: {
        width: "100%",
        color: "#656766",
        fontWeight: 500,
        textDecoration: "none",
        "&:hover": {
          color: "#528959",
        },
      },
    },
    count: {
      float: "right",
      span: {
        fontSize: "12px",
        padding: "7px 10px 7px 10px",
        backgroundColor: "#E4E4E4",
        borderRadius: "50%",
        color: "#656766",
        "&:hover": {
          backgroundColor: "#685f52",
          color: "white",
        },
      },
    },
  },
};

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicBlog(uid: { eq: $slug }) {
      uid
      data {
        content {
          text
          html
          richText
        }
        title
        category
        meta_description
        author
        date
        updated_date
        tags {
          blog_tags {
            document {
              ... on PrismicBlogTag {
                data {
                  name
                }
              }
            }
          }
        }
        blog_image {
          alt
          copyright
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allPrismicBlog(sort: { data: { date: DESC } }) {
      totalCount
      nodes {
        data {
          category
          date
          tags {
            blog_tags {
              document {
                ... on PrismicBlogTag {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
