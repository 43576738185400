
/** @jsx jsx */
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useState, useEffect } from "react";
import { IoIosAdd, IoIosCloseCircle, IoIosRemove, IoMdAdd, IoIosClose } from "react-icons/io";
import { IoTrashBinOutline } from "react-icons/io5";
import { Box, Button, jsx, Text, useThemeUI } from "theme-ui";
import { formatPrice, applyVAT } from "../../../helper/product-helper";
import { LocalCartContext } from "../../../provider/local-cart-provider";
import styles from "./line-item.style";
import { removeLocalStateItem } from '../../../helper/local-storage';
import Modal from "react-bootstrap/Modal";
import { BsFillEyeFill } from "react-icons/bs";
import { PrismicRichText } from "@prismicio/react";
import { AiOutlineShopping } from "react-icons/ai";
import Select from "react-select";

const LineItem = (props: any) => {
	const { theme } = useThemeUI();
	const { update, add, smokersProducts, products, updateSmokersProduct, updateExtraItem, updateMethodItem, } = useContext(LocalCartContext);
	const [item, setItem] = useState(props.item);

	const cartProduct = products.find(x => x.uid == item.uid)
	const [showDelete, setShowDelete] = useState(props.showDelete);
	const [itemRemoved, setItemRemoved] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [quantity, setQuantity] = useState(props.item.quantity);
	const [cartItem, setCartItem] = useState(true);
	const [twinsValue, setTwinsValue] = useState(item.expectingTwins);
	const [extraMessage, setExtraMessage] = useState("");
	const [twinMessage, setTwinMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [extraItem, setExtraItem] = useState(item?.extraItem != undefined ? item.extraItem : cartProduct?.extraItem);
	const [methodItem, setMethodItem] = useState(item?.methodItem != undefined ? item.methodItem : cartProduct?.methodItem);
	const [price, setPrice] = useState(+item.price);
	const [unitPrice, setUnitPrice] = useState(+item.unitPrice);
	const [title, setTitle] = useState(item.title);
	const [initialPrice, setinitialPrice] = useState(+item.priceBeforeVat);
	const [itemStatus, setItemStatus] = useState("Awaiting Removal");
	const [showNoticationModal, setShowNoticationModal] = useState(false);
	const [expectingTwinsLabel, setExpectingTwinsLabel] = useState(item.expectingTwinsPrice > 0);
	const [twinsPrice, setTwinPrice] = useState(0);
	const [methodMessage, setMethodMessage] = useState("");
	let showLineItem = item ? quantity : 0;

	useEffect(() => {	
		setTwinPrice(getTwinPrice());
	}, []);

	useEffect(() => {		
		setQuantity(0);
		products?.map((product: any) => {
			if (product.uid === item.uid && product.availableToSmokers) {
				setQuantity(product.quantity);
				setTwinsValue(getTwinPrice());
				setExpectingTwinsLabel(product.expectingTwins);
			}

			if (product.uid === item.uid) {
				const price = Math.round(applyVAT(product.expectingTwinsPrice, product.vatRate) * product.quantity);
				setTwinPrice(price);
				setQuantity(product.quantity);
				setPrice((product.price));

			}
		})

		smokersProducts?.map((product: any) => {
			//if item is not in products the user has removed this item via the smokers screen

			const item = products.find(x => x.uid == product.uid);

			if (!item) {
				setItemRemoved(true);
				setItemStatus("Removed from basket");
				//setQuantity(item.quantity);
			}
		});

	}, [update]);

	if (props.smokersPage) {
		showLineItem = true;
	}

	const handleQuantityChange = (value: number) => {
		update(item.uid, value);
		updateSmokersProduct(item.uid, value);

		// Set the new quantity in the state
		setQuantity(value);

		// If the quantity is set to zero, reset some other state variables
		if (value === 0) {
			//setPrice(applyVAT(+initialPrice, item.vatRate));
			setTwinsValue('');
			setExtraItem('');
			setMethodItem('');
			setExpectingTwinsLabel(false);
		}
	};

	const handleRemove = () => {
		setQuantity(0);
		removeLocalStateItem("giftcard");
		update(item.uid, 0);
		updateSmokersProduct(item.uid, 0);
	};

	const handleRemoveFromBasket = () => {
        update(item.uid, 0);
		setItemRemoved(true);
		setShowDeleteModal(false);
		setCartItem(false);
		setItemStatus("Removed from basket");
	};

	const handleItemReAdded = () => {		
		props.setShowSmokingModal(true);
		setItemRemoved(false);
		setQuantity(1);
		add(item);		
		setItem(item);
		setCartItem(false);
		setItemStatus("Awaiting Removal");
	};

	const validateOptions = (): boolean => {
		const error = "Required";
		let valid = true;
		const hasExtraItem = cartProduct?.extraItem || item.extraItem || extraItem;
		const hasExtrasList = item.extras?.length > 0 && item.extras[0].title != null;
	  
		if (hasExtrasList && item.showTwinsOption) {
		  if (!twinsValue) {
			setTwinMessage(error);
			valid = false;
		  }
		  if (!hasExtraItem) {
			setExtraMessage(error);
			valid = false;
		  }
		} else if (item.showTwinsOption && !twinsValue) {
		  setTwinMessage(error);
		  valid = false;
		} else if (hasExtras() && !hasExtraItem) {
		  setExtraMessage(error);
		  valid = false;
		}
	  
		return valid;
	  };

	const handleItemAdd = () => {
		if (!validateOptions()) {
			setErrorMessage("Please fix any errors above");
			return;
		}

		setQuantity(1);
		item.quantity = 1;
		item.price = price;
		item.extraItem = extraItem;
		item.methodItem = methodItem;
		
		add(item);
		setItem(item);
	};

	const showProductItemModal = () => {
		setShowProductModal(true);
	};

	const handleHideProductModal = () => {
		setShowProductModal(false);
	};

	const handleShowDeleteModal = () => {
		setShowDeleteModal(true);
	};

	if (showDelete == undefined) {
		setShowDelete(true);
	}

	const getExtraOptions = () => {
		const extraOptions: any[] = [];
		item?.extras?.map((x: any) =>
		  extraOptions.push({
			value: x.title, 
			label: x.title
		  })
		)
		return extraOptions;
	  }

	  const handleExpectingTwins = (selected: any) => {
		setTwinMessage('');
		
		const originalPrice = applyVAT(item.priceBeforeVat, item.vatRate) + getTwinPrice();
		const twinsPrice = item.onSale ? item.salePrice + getTwinPrice() : originalPrice;
		const initialPrice = item.onSale ? item.salePrice : item.price;
		
		const price = selected.value === 'yes' ? twinsPrice : initialPrice;
		setPrice(price);
		setTwinsValue(selected.value);
		
		update(item.uid, quantity, twinsPrice, selected.value === 'yes');
	  };

	const handleExtraItem = (selected: any) => {
		setExtraMessage('');
		setExtraItem(selected.label);
	
		updateExtraItem(item.uid, selected.label);
	  }
	
	  const handleMethodItem = (selected: any) => {
		setMethodMessage('');
		setMethodItem(selected.label);
	
		updateMethodItem(item.uid, selected.label);
	  }

	const twinOptions = () => {
		return [
			{ value: 'yes', label: 'Yes +' + formatPrice(getSingleTwinPrice()) },
			{ value: 'no', label: 'No' }
		];
	  }

	const ProductAvailabilityLabel = () => {
		return (
			<React.Fragment>
				{!item.availableForSale && (
					<Text sx={theme.productLabel} variant={"productLabel.modes.danger"}>{"Sold Out"}</Text>
				)}
			</React.Fragment>
		);
	}

	const getTwinPrice = () => {
		if (!item?.expectingTwinsPrice) {
		  return 0;
		}
		
		const twinPrice = applyVAT(item.expectingTwinsPrice, item.vatRate);
		const totalPrice = item.quantity ? Math.round(twinPrice * item.quantity) : Math.round(twinPrice);
		
		return totalPrice;
	  };

	  const getSingleTwinPrice = () => {
		if (item.quantity > 0) {
			return getTwinPrice() / item.quantity;
		}
		else {
			return getTwinPrice()
		}
	}

	  const getTwinOptionValue = () => {
		const options = twinOptions();
		if (item.availableToSmokers && cartProduct?.expectingTwinsPrice != undefined) {
			return options.filter(x => x.value === (cartProduct.expectingTwinsPrice > 0 ? 'yes' : 'no'));
		}

		if (!item.availableToSmokers) {
			return options.filter(x => x.value === (item.expectingTwinsPrice > 0 ? 'yes' : 'no'));
		}
	}

	const hasExtras = () => {
		if (cartProduct?.extraItem || item.extraItem) {
		  return true;
		}
	  
		if (item.extras?.length > 0 && item.extras[0].title !== null) {
		  return true;
		}
	  
		return false;
	  };
	

	const getExtraOptionValue = () => {
		if (item.availableToSmokers && cartProduct?.extraItem) {
			return [{
				value: cartProduct.extraItem,
				label: cartProduct.extraItem
			}]
		}

		if (!item.availableToSmokers) {
			return [{
				value: item.extraItem,
				label: item.extraItem
			}]
		}
	}

	const getMethodOptionValue = () => {
		if (item.availableToSmokers && cartProduct?.methodItem) {
			return [{
				value: cartProduct.methodItem,
				label: cartProduct.methodItem
			}]
		}

		if (!item.availableToSmokers) {
			return [{
				value: item.methodItem,
				label: item.methodItem
			}]
		}
	}
	
	 const getMethodOptions = () => {
	   const methodOptions: any[] = [];
	   item?.methods?.map((x: any) =>
	   methodOptions.push({
		   value: x, 
		   label: x.title
		 })
	   )
	  return methodOptions;
	 }

	const Counter = () => {
		return (
			<React.Fragment>
				<Button
					type="button"
					sx={styles.cartCounter.decrement}
					title="Decrement"
					onClick={() => handleQuantityChange(quantity - 1)}
				>
					<IoIosRemove />
				</Button>
				<Box>{quantity}</Box>
				<Button
					type="button"
					sx={styles.cartCounter.increment}
					title="Increment"
					onClick={() => handleQuantityChange(quantity + 1)}
				>
					<IoIosAdd />
				</Button>
			</React.Fragment>
		)
	}

	return (
		<Box>
			{showLineItem && (
				<Box sx={styles.outerWrapper}>
					<Box sx={styles.wrapper}>
						<div className="row">
							<table style={{ borderCollapse: 'unset' }}>
								<thead>
									<th></th>
									<th></th>
								</thead>
								<tbody>
									<td sx={styles.item}>
										<Box sx={styles.imageWrapper}>
											{item.thumbnail && (
												<GatsbyImage image={item.thumbnail} alt={title} imgStyle={{ objectFit: "cover" }} />
											)}

											{!props.smokersPage ? (
												<Button type="button" variant="text" title="Delete" onClick={handleRemove}>
													<IoIosCloseCircle />
												</Button>
											) : (
												<Button type="button" variant="text" title="View" onClick={() => showProductItemModal()}>
													<BsFillEyeFill />
												</Button>
											)}
										</Box>
									</td>
									<td>
										<Box sx={styles.contentWrapper}>
											<React.Fragment>
												<Link sx={styles.title} to={`/product/${item.uid}`}>{title}</Link>
												<br />
												<Text sx={styles.productInfo}>
													
													Unit Price: {formatPrice(Math.round(unitPrice))}

													{twinsPrice > 0 && quantity > 0 && (
														<span>
															<br />
															Twins: {formatPrice(twinsPrice)}</span>
													)}

													{extraItem && (
														<span>
															<br />
															Extra: {extraItem}</span>
													)}

													{methodItem && (
														<span>
															<br />
															Method of Pills: {methodItem}</span>
													)}
												</Text>
												{!item.availableToSmokers && props.smokersPage && itemStatus && (
													<Text sx={styles.productInfo}>
														<b>Status: {itemStatus}</b>
													</Text>
												)}
											</React.Fragment>
										</Box>

										{!item.availableToSmokers && cartItem && quantity > 0 && !props.showDelete ? (
											<div className="col-md-6">
												<Box sx={styles.counterWrapper}>
													<Box sx={styles.cartCounter}>
														<Counter />
													</Box>
												</Box>
											</div>
										) : (
											!item.availableToSmokers ? (
												<div className="col-md-6">
													<Box sx={styles.counterWrapper}>
														<Box sx={styles.cartCounter}>
															{!itemRemoved ? (
																<Button
																	type="button"
																	sx={styles.removeButton}
																	title="Remove"
																	onClick={() => handleShowDeleteModal()}
																>
																	<IoTrashBinOutline />
																</Button>
															) : (
																<Button
																	type="button"
																	sx={styles.addButton}
																	title="Add"
																	onClick={() => handleItemReAdded()}
																>
																	<IoMdAdd />
																</Button>
															)}
														</Box>
													</Box>
												</div>
											) :
												(
													<div className="col-md-6">
														<Box sx={styles.counterWrapper}>
															<Box sx={styles.cartCounter}>
																{quantity > 0 ? (
																	<Counter />
																) : (
																	<Button
																		type="button"
																		sx={styles.addButton}
																		title="Add"
																		onClick={() => setShowProductModal(true)}
																	>
																		<IoMdAdd />
																	</Button>
																)}
															</Box>
														</Box>
													</div>
												)
										)}
									</td>
									<Text sx={styles.totalPrice}>
										{formatPrice(price * quantity == 0 ? price : price * quantity)}
									</Text>
								</tbody>
							</table>
						</div>
					</Box>
				</Box>
			)}

			<Modal
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(true)}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header>
					<Modal.Title>
						What this means
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<b>{title}</b> will be removed from your basket.
				</Modal.Body>
				<Modal.Footer>
					<Button sx={styles.button} style={{ background: '#dc3545' }} onClick={() => handleRemoveFromBasket()}>Remove from basket</Button>{' '}
					<hr className="bar-indent-center" />
					<Button sx={styles.button} onClick={() => setShowDeleteModal(false)}>Keep in Basket</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showNoticationModal}
				onHide={() => setShowNoticationModal(true)}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header>
					<Modal.Title>
						Information
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Please select an item from the products available to smokers list before removing items from your basket
				</Modal.Body>
				<Modal.Footer>
					<Button sx={styles.button} onClick={() => setShowNoticationModal(false)}>OK</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showProductModal}
				onHide={handleHideProductModal}
				centered
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>
						{title}
						<IoIosClose sx={styles.modal.close} onClick={handleHideProductModal} />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div sx={styles.modal.image} className='col-md-6'>
							<span sx={styles.modal.productAvailabilityLabel}>
								<ProductAvailabilityLabel />
							</span>

							<ProductImage image={item.thumbnail} />
						</div>
						<div sx={styles.modal.description} className='col-md-6'>
							<Box sx={styles.header}>

							<Box sx={styles.priceWrapper}>
									<Text sx={styles.price}>
										<span>
											{item.onSale ? (
												<div>
													<span sx={styles.price.sale}>
													{formatPrice(price)}
													</span>

													<span sx={styles.price}>
														{formatPrice(item.salePrice)}
													</span>
												</div>
											) : (
												<span sx={styles.price}>
													{formatPrice(price)}
												</span>
											)}
										</span>

									</Text>
								</Box>
							</Box>

							<PrismicRichText field={item.description?.richText} />

							{item.showTwinsOption && (
								<React.Fragment>
									<div className="row">
										<div className="col-md-5">
											<b>Twins</b>
										</div>
										<div className="col-md-6">
											<span sx={styles.form.control}>
												<Select
													value={getTwinOptionValue()}
													name="expectingTwins"
													id="expectingTwins"
													classNamePrefix="react-select"
													options={twinOptions()}
													onChange={(x) => handleExpectingTwins(x)}
													isDisabled={!item.availableToSmokers}
												/>
											</span>
										</div>
										{twinMessage && (
											<p sx={styles.input.error}>{twinMessage}</p>
										)}
									</div>
								</React.Fragment>
							)}

							{hasExtras() && (
								<React.Fragment>
									<br />
									<div className="row">
										<div className="col-md-5">
											<b>Extras</b>
										</div>

										<div className="col-md-6">
											<span sx={styles.form.control}>
												<Select
													value={getExtraOptionValue()}
													name="extras"
													id="extras"
													classNamePrefix="react-select"
													options={getExtraOptions()}
													onChange={(x) => handleExtraItem(x)}
													isDisabled={!item.availableToSmokers}
												/>
											</span>
										</div>
										{extraMessage && (
											<p sx={styles.input.error}>{extraMessage}</p>
										)}
									</div>
								</React.Fragment>
							)}
							<br />
							{methodItem && (
								<React.Fragment>
									<div className="row">
										<div className="col-md-5">
											<b>Method of Pills</b>
										</div>

										<div className="col-md-6">
											<span sx={styles.form.control}>
												<Select
													value={getMethodOptionValue()}
													name="methods"
													id="methods"
													classNamePrefix="react-select"
													options={getMethodOptions()}
													onChange={(x) => handleMethodItem(x)}
													isDisabled={!item.availableToSmokers}
												/>
											</span>
										</div>
										{extraMessage && (
											<p sx={styles.input.error}>{extraMessage}</p>
										)}
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer sx={styles.modal.footer}>
					{item .availableToSmokers && (
						quantity == 0 ? (
							<Button
								sx={styles.cartBtn}
								title={item.availableForSale ? "Add to Basket" : "Not Available"}
								onClick={item.availableForSale ? () => handleItemAdd() : undefined}
								disabled={item.availableForSale ? false : true}
							>
								{item.availableForSale ? (
									<Text>
										<AiOutlineShopping className="product-card-cta" />{" "}
										Add to Basket
									</Text>
								) : (
									<Text>
										Not Available
									</Text>
								)}
							</Button>

						) : (
							<div className="col-md-6" style={{ float: 'right' }}>
								<Box sx={styles.counterWrapper}>
									<Box sx={styles.cartCounter} style={{ position: 'initial' }}>
										<Counter />
									</Box>
								</Box>
							</div>
						)
					)}
				</Modal.Footer>
			</Modal>
		</Box>
	);
};

const ProductImage = (props: any) => {
	return (
		<GatsbyImage
			className="product-image"
			imgStyle={{ objectFit: "cover" }}
			image={props?.image}
			alt={props?.title ?? ""}
		/>
	)
}

export default LineItem;