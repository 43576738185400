/** @jsx jsx */
import { ErrorMessage, Field, Form, Formik } from "formik"
import { jsx, Button, Text } from 'theme-ui'
import * as Yup from "yup"
import styles from './gift-vouchers.style'
import { useContext, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { formatPrice } from "../../helper/product-helper";
import DatePicker from "../../components/date-picker/datepicker";
import { AiOutlineShopping } from "react-icons/ai";
import { LocalCartContext } from "../../provider/local-cart-provider";
import { setLocalState, getLocalState } from '../../helper/local-storage';

const validationSchema = () =>
    Yup.object().shape({
        voucherDeliveryDate: Yup.string().required('Voucher delivery date is required').nullable(),
        voucherRecipientName: Yup.string().required('Name is required'),
        voucherRecipientEmail: Yup.string()
            .email('Email is invalid')
            .required('Please enter your email address'),
        voucherMessage: Yup.string().required('Message is required'),
        currencyInput: Yup.number().required('Amount is required')
            .max(1000.00, "The maximum amount is £1,000")
            .min(25, "Minimum amount is £25.00")
         
    });

const GiftVoucherForm = (props: any) => {

    const initialFormValues = {
        voucherDeliveryDate: props.voucherDeliveryDateValue,
        voucherRecipientName: props.voucherRecipientName,
        voucherRecipientEmail: props.voucherRecipientEmail,
        voucherMessage: props.voucherMessage,
        currencyInput: props.price
    };

    const { add, update } = useContext(LocalCartContext);

    const handleCurrencyInput = (value: any, formik: any) => {
        if (isNaN(value)) value = 0;
        formik.setFieldValue('currencyInput', +value);
        props.setPaymentAmount(+value);
        props.setCurrencyValue(+value);
    };

    let buttonTitle = "Add to Basket";
    const giftcard = getLocalState("giftcard");
    if (giftcard !== undefined) {
        buttonTitle = "Update Voucher"
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema()}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
                const product = props.product.data;
                const option = product.options?.length > 0 ? product.options[0] : null;

                const availableToSmokers = option?.is_product_available_for_smokers;
                const availableToInternational = option?.is_an_international_product;
                const packageProduct = option?.is_product_a_package;

                const item: any = {
                    title: product.title1,
                    thumbnail: product?.images[0]?.image?.gatsbyImageData,
                    imageSrc: product?.images[0]?.image?.gatsbyImageData.images.fallback.src,
                    quantity: 1,
                    price: props.price,
                    currency: "GBP",
                    uid: props.product.uid,
                    vatRate: 0,
                    priceBeforeVat: props.price,
                    availableToSmokers: availableToSmokers,
                    availableToInternational: availableToInternational,
                    isPackageProduct: packageProduct
                };

                if (giftcard !== undefined) {
                    update(item.uid, 0);
                    add(item);
                }
                else {
                    add(item);
                }

                setLocalState('giftcard', {
                    voucherDeliveryDate: props.voucherDeliveryDateValue,
                    voucherRecipientName: props.voucherRecipientName,
                    voucherRecipientEmail: props.voucherRecipientEmail,
                    voucherMessage: props.voucherMessage,
                    voucherPrice: props.price
                });

                props.setCartVisible(true);
                props.setShowVoucherModal(false);
                props.setCustomGiftCardButtonTitle("Edit Voucher");
                props.setCustomGiftCardReadOnly(true);
            }}
        >
            {(formik) => {
                const { setFieldTouched } = formik;
                const setVoucherDeliveryDateCallback = (date: string) => {
                    if (date)
                        props.setVoucherDeliveryDateValue(new Date(date).getTime());
                }
                return (
                    <Form id="voucherForm">
                        <div style={{ marginTop: '10px' }}>
                            <div>
                                <label htmlFor="currencyInput" sx={styles.form.label}>Amount</label>
                                <CurrencyInput
                                    intlConfig={{ locale: "en-US", currency: "GBP" }}
                                    prefix="£"
                                    type="text"
                                    name="currencyInput"
                                    sx={styles.input}
                                    className="form-control"
                                    decimalsLimit={2}
                                    value={props.price > 0 ? props.price : ""}
                                    placeholder={`Enter custom amount. Max allowed ${formatPrice(props.endPrice)}`}
                                    onValueChange={(value, name) => handleCurrencyInput(value, formik)}
                                    maxLength={4}
                                    disableAbbreviations={true}
                                />
                                 <ErrorMessage
                                        name="currencyInput"
                                        component="div"
                                        sx={styles.input.error}
                                    />
                                <div className="form-group">
                                    <label htmlFor="voucherDeliveryDate" sx={styles.form.label}>When should we send it?</label>
                                    <DatePicker
                                        name="voucherDeliveryDate"
                                        id="voucherDeliveryDate"
                                        className="form-control"
                                        sx={styles.form.input}
                                        dateChangeCallback={setVoucherDeliveryDateCallback}
                                        setFieldTouchedCallback={setFieldTouched}
                                        minDate={new Date()}
                                    />
                                    <ErrorMessage
                                        name="voucherDeliveryDate"
                                        component="div"
                                        sx={styles.input.error}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="voucherRecipientName" sx={styles.form.label}>Who is it for?</label>
                                    <Field
                                        name="voucherRecipientName"
                                        id="voucherRecipientName"
                                        className="form-control"
                                        sx={styles.form.input}
                                        placeholder="Enter recipient name"
                                        value={props.voucherRecipientName}
                                        onChange={(x) => props.setVoucherRecipientName(x.currentTarget.value)} />
                                    <ErrorMessage name="voucherRecipientName" component="div" sx={styles.form.input.error} />

                                    <Field
                                        name="voucherRecipientEmail"
                                        id="voucherRecipientEmail"
                                        className="form-control"
                                        sx={styles.form.input}
                                        placeholder="Enter recipient email"
                                        value={props.voucherRecipientEmail}
                                        onChange={(x) => props.setVoucherRecipientEmail(x.currentTarget.value)} />
                                    <ErrorMessage name="voucherRecipientEmail" component="div" sx={styles.form.input.error} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="voucherMessage" sx={styles.form.label}>Message</label>
                                    <Field 
                                    as="textarea" 
                                    name="voucherMessage" 
                                    id="voucherMessage" 
                                    className="form-control" 
                                    sx={styles.form.input} 
                                    placeholder="Enter personalised message"
                                    value={props.voucherMessage}
                                    onChange={(x) => props.setVoucherMessage(x.currentTarget.value)} />
                                    <ErrorMessage name="voucherMessage" component="div" sx={styles.form.input.error} />
                                </div>
                            </div>
                        </div>
                        <hr className="bar-indent-center" />
                        <Button
                            sx={styles.cartBtn}
                            title={buttonTitle}
                            type="submit"
                        >
                            <Text>
                                <AiOutlineShopping className="product-card-cta" />{" "}
                                {buttonTitle}
                            </Text>
                        </Button>
                    </Form>
                )
            }}
        </Formik>
    );
}
export default GiftVoucherForm
