import React from 'react';
import { FiFacebook, FiTwitter, FiLinkedin, FiGithub, FiYoutube} from "react-icons/fi";
import { IoLogoTiktok } from "react-icons/io5";
import { AiOutlineInstagram } from "react-icons/ai";

type PropsType = {
	item: {
		type: string;
		link: string;
	};
};

const SocialLink: React.FC<PropsType> = ({ item }) => {
	const type = item.type?.toLowerCase();

	const LinkItem = () => {
		switch (type) {
			case 'facebook':
				return (
					<a
						className={type}
						href={item.link}
					>
						<FiFacebook />
					</a>
				);
			case 'twitter':
				return (
					<a
						className={type}
						href={item.link}
					>
						<FiTwitter />
					</a>
				);
			case 'youtube':
				return (
					<a
						className={type}
						href={item.link}
					>
						<FiYoutube />
					</a>
				);
			case 'github':
				return (
					<a
						className={type}
						href={item.link}
					>
						<FiGithub />
					</a>
				);
			case 'instagram':
				return (
					<a
						className={type}
						href={item.link}
					>
						<AiOutlineInstagram />
					</a>
				);
			case 'linkedin':
				return (
					<a
						className={type}
						href={item.link}
					>
						<FiLinkedin />
					</a>
				);
			case 'tiktok':
				return (
					<a
						className={type}
						href={item.link}
					>
						<IoLogoTiktok />
					</a>
				);
			default:
				return null;
		}
	};

	return (
		<LinkItem />
	);
};

export default SocialLink;