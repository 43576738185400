const styles = {
    form: {
        label: {
            fontWeight: '600',
            marginBottom: '0.5em',
            color: '#2F3E57'
        },
        control: {
            padding: '6px 12px'
        },
        input: {
            width: '100%',
            fontSize: '14px',
            fontWeight: '400',
            borderRadius: 6,
            padding: '10px',
            opacity: '.7',
            marginBottom: '10px',
            borderWidth: '1px',
            borderStyle: 'solid',
            boxShadow: 'none',
            transition: 'border-color 0.2s ease',
            borderColor: '#E4E4E4',
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: 'darkgray',
            },
            error: {
                fontSize: '13px',
                marginTop: '-5px',
                paddingBottom: '5px',
                color: '#dc3545'
            },
            readOnly: {
                border: 0,
                backgroundColor: 'unset!important',
                '&:hover': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
                '&:focus': {
                    borderColor: 'unset!important',
                    boxShadow: 'unset!important'
                },
            }
        },
        subSection: {
            header: {
                fontWeight: '600',
                fontSize: '24px',
                marginBottom: 0,
                '@media only screen and (max-width: 674px)': {
                    fontSize: '20px',
                }
            },
            label: {
                fontSize: '14px',
                marginBottom: '0.5em'
            }
        },
        buttonWrapper: {
            button: {
                height: '20px!important',
                minHeight: '40px'
            },
            buttonDisabled: {
                button: {
                    background: 'c##'
                }
            }
        },
        button: {
            fontSize: '14px',
            color: 'white',
            fontWeight: '600',
            padding: '12.5px 25px',
            borderRadius: '10px',
            background: '#00d4c5',
            width: '100%',
            '@media only screen and (max-width: 767px)': {
                marginBottom: '10px'
            }
        },
    },
    input: {
		width: '100%',
		fontSize: '14px',
		fontWeight: '400',
		borderRadius: 6,
		padding: '13px',
		opacity: '.7',
		marginBottom: '10px',
		borderWidth: '1px',
		borderStyle: 'solid',
		boxShadow: 'none',
		transition: 'border-color 0.2s ease',
		borderColor: '#E4E4E4',
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
			borderColor: 'darkgray',
		},
		error: {
			fontSize: '13px',
			marginTop: '-5px',
			paddingBottom: '5px',
			color: '#dc3545'
		},
		readOnly: {
			border: 0,
			backgroundColor: 'unset!important',
			'&:hover': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
			'&:focus': {
				borderColor: 'unset!important',
				boxShadow: 'unset!important'
			},
		}
	},
    cartBtn: {
		width: '100%',
		textDecoration: 'unset',
		textTransform: 'uppercase',
		display: 'flex',
		opacity: 1,
		transform: 'scale(1)',
		transition: 'all 0.2s ease',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
			backgroundColor: "#E6E6E6",
			borderColor: "#E6E6E6",
			color: "#878788",
			fontWeight: 500
		},
		backgroundColor: '#00d4c5',
		fontWeight: 'normal',
		fontSize: '13px',
		color: 'white',
		borderRadius: '10px',
		padding: '15px 20px 15px 20px',
		'@media only screen and (min-width: 768px) and (min-width: 1400px)': {
			minWidth: '0!important',
			marginTop: '0'

		},
		minWidth: '100%!important',
		minHeight: '40px!important',
		svg: {
			margin: 0,
			width: '23px',
			height: '23px',
			marginTop: '-8px',
		},
		link: {
			lineHeight: '40px',
			margin: '0 auto'
		}
	},
}
export default styles