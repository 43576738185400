const styles = {
	topbar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: 80,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: 'muted',
		backgroundColor: 'white',
		'@media only screen and (min-width: 1024px)': {
			'.container.fluid': {
				'.grid': {
					gridTemplateColumns: '2fr 2fr 2fr',
					'.topbar-search': {
						'label.search': {
							marginLeft: 'auto',
							marginRight: 'auto',
							maxWidth: '100%',
						},
					},
					'.topbar-links': {
						marginLeft: 'auto',
					},
				},
			},
		},
		'@media only screen and (max-width: 1080px)': {
			borderBottom: 0,
			boxShadow: '0 2px 4px rgba(41,41,41,0.06)',
		},
		'.logo a': {
			position: 'relative',
			marginTop:'20px',
			marginBottom: '20px',
			'@media only screen and (max-width: 1080px)': {
				marginLeft: '10px',
				marginRight: '0',
			},
		},
		rightBannerLogo: {
			position: 'realive',
			top: '5px',
			right: '0',
			height: 'inherit',
			opacity: '0.7',
			'@media only screen and (max-width: 1286)': {
				display: 'none'
			},
		},
		leftBannerLogo: {
			position: 'realive',
			top: '5px',
			left: '0',
			opacity: '0.7',
		},
	},
	grid: {
		gridGap: [10, 30, 40],
		alignItems: 'center',
		gridTemplateColumns: '1fr 1.5fr 1fr',
		'.search': {
			display: 'flex',
			maxWidth: 600,
			margin: '0 auto',
		},
		'@media only screen and (max-width: 590px)': {
			display: 'flex'
		},
	},
	hamburgBtn: {
		display: 'none',
		'@media only screen and (max-width: 1080px)': {
			display: 'inline-flex',
		},
		'@media only screen and (max-width: 768px)': {
			position: 'absolute',
			right: '10px',
			top: '-8px'
		},
	},
	searchBtn: {
		display: 'none',
		svg: {
			width: 17.5,
			height: 17.5,
		},
		'@media only screen and (max-width: 667px)': {
			display: 'inline-flex',
		},
	},
	rightPart: {
		display: 'flex',
		alignItems: 'center',
		div: {
			marginTop: '2px',
		},
		a: {
			display: 'flex',
			color: 'primary',
			textDecoration: 'none',
			transition: 'color 0.2s',
			svg: {
				width: [19.2, 19],
				height: [19.2, 19],
			},
			':hover': {
				color: 'menu',
			},
		},
	},
	drawerTitle: {
		width: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		paddingY: 15,
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'background',
		zIndex: 10,
		h2: {
			color: 'primary',
			fontSize: 17,
		},
		textAlign: 'center',
		close: {
			position:'absolute',
			right: 20
		}
	},
	icons: {
		flex: '1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		a: {
			color: '#4A4A49',
			padding: ['8px 2px', '8px'],
		},
		svg: {
			width: '32px',
			height: '32px',
			color: '#685f52',

		},
		button: {
			position: 'relative',
		},
	},
	badge: {
		minWidth: '18px',
		minHeight: '20px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: '700',
		backgroundColor: '#00d4c5',
		position: 'absolute',
		top: '9px',
		right: '7px',
		paddingLeft: '2px',
		paddingRight: '2px',
	},
	totalPrice: {
		fontWeight: '600',
		fontSize: '17px',
		color: '#685f52',
		'&:hover': {
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		'@media only screen and (max-width: 320px)': {
			display: 'none',
		},
		'@media only screen and (max-width: 375px)': {
			fontSize: '14px',
		},
	},
	container: {
		'@media only screen and (max-width: 768px)': {
			maxWidth: '100%!important',
			margin: '0!important'
		},
	},
	search: {
		'@media only screen and (max-width: 590px)': {
			display: 'none',
		},
	}
};

export default styles;
