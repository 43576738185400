/** @jsx jsx */
import { Link } from 'gatsby';
import { FaRegUser } from 'react-icons/fa';
import { Box, jsx } from 'theme-ui';
import { getJwtToken, getJwtTokenIdentifier } from "../../../../../services/auth-service";
import { Logout } from "../../../../../services/user-service";
import styles from './navbar.style';

const TopMenu: React.FC<any> = () => {
	const token = getJwtToken()
	let pathname = ""

	if (typeof window !== `undefined`) {
		pathname = window?.location?.pathname.replace('/', '');
	}
	return (
		<Box as="ul" sx={styles.topMenu}>
			<Box sx={styles.rightPart}>
				<div sx={styles.topBarContainer} className="container">
					<div className="row">
						<div sx={styles.notice} className="col-md-6">
							<span sx={styles.topLeftMenu}>
								<ul sx={styles.accountControl}>
									<li>
										<Link to="/contact" style={{marginLeft: 0}}>
											Contact Us
										</Link>
									</li>
									|
									<li>
										<Link to="/blog" >
											Blog
										</Link>
									</li>
								</ul>
							</span>
						</div>

						<div className="col-md-6">
							<span sx={styles.account}>
								<FaRegUser sx={styles.account.icon} />
								{token !== null ? (
									<ul sx={styles.accountControl}>
										<li>
											<Link to="/account/orders" >
												My Account
											</Link>
										</li>
										|
										<li>
											<button
												sx={styles.logout}
												className="btn-none"
												onClick={logout}
												onKeyDown={logout}
											>
												Sign out
											</button>
										</li>
									</ul>

								) : (
									<ul sx={styles.accountControl}>
										<li>
											<Link to="/login">
												My Account
											</Link>
										</li>
										|
										<li>
											<Link to="/register">
												Book Now
											</Link>
										</li>
									</ul>
								)}
							</span>
						</div>
					</div>
				</div>
			</Box>
		</Box>
	);
};

async function logout() {
	await Logout(getJwtTokenIdentifier());
}


export default TopMenu
