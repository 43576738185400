/** @jsx jsx */
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from '@prismicio/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Box, Button, Heading, jsx, Text, useThemeUI } from "theme-ui";
import { Breadcrumb } from "../components/breadcrumbs/breadcrumbs";
import DrawerCart from "../components/drawer-cart/drawer-cart";
import PrimaryLayout from "../components/layout/primary/primary";
import ProductGrid from "../components/product-grid/product-grid";
import styles from "../components/product-single.style";
import SEO from "../components/seo";
import ProductTopMenu from "../components/top-menus/product/product-top-menu";
import { formatPrice, isOnSale, applyVAT } from "../helper/product-helper";
import { capitalizeFirstLetter } from "../helper/string-helper";
import useOnClickOutside from "../hooks/useOutsideClick";
import { CartProduct, LocalCartContext } from "../provider/local-cart-provider";
import LocationInput from "../components/location-input/location-input";
import Modal from "react-bootstrap/Modal";
import GiftVoucherForm from "../components/gift-vouchers/gift-vouchers-form";
import { GetHospitals } from "../services/hospital-service";
import PackageModal from '../components/modal/package-modal';
import CurrencyInput from "react-currency-input-field";
import { IoIosClose } from 'react-icons/io';
import { getLocalState, setLocalState } from '../helper/local-storage';
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

function getProductCategories(categories: any[], uid: string): any[] {
  let productCategories: any[] = [];

  categories.nodes.forEach((node: any) => {
    function checkForProductOnCategory(uid: string, item: any) {
      return item.product.document.uid === uid;
    }

    if (node.data.products.some(checkForProductOnCategory.bind(null, uid)))
      productCategories.push(node);
  });

  return productCategories;
}

function flattenCategoryProducts(categories: any[]): any[] {
  var flat: any[] = [];

  categories.forEach((category: any) => {
    flat = flat.concat(category.data.products);
  });

  return flat;
}

function getProductSubcategories(subcategories: any[], uid: string): any[] {
  let productSubcategories: any[] = [];

  subcategories.nodes.forEach((node: any) => {
    function checkForProductOnSubctegory(uid: string, item: any) {
      return item.product.document.uid === uid;
    }

    if (node.data.products.some(checkForProductOnSubctegory.bind(null, uid)))
      productSubcategories.push(node);
  });

  return productSubcategories;
}

function createBreadcrumbs(product: any, category: any, subcategory?: any) {
  let title = !category?.data?.title.text
    ? "Product"
    : category.data.title.text;

  let breadcrumbs: Breadcrumb[] = [
    {
      label: "Home",
      pathname: "/",
    },
    {
      label: capitalizeFirstLetter(title),
      pathname: `/${category?.uid}`,
    },
    {
      label: capitalizeFirstLetter(product.data.title1),
      pathname: `/product/${product.uid}`,
    },
  ];

  if (subcategory && product.data.title1 !== subcategory.data.title.text) {
    const subcategoryBreadcrumb: Breadcrumb = {
      label: capitalizeFirstLetter(subcategory.data.title.text),
      pathname: `/${subcategory.uid}`,
    };

    breadcrumbs.splice(2, 0, subcategoryBreadcrumb);
  }

  return breadcrumbs;
}

const ProductPage: React.FC<any> = ({
  data: {
    prismicProduct,
    allPrismicCategory,
    allPrismicSubcategory,
    prismicCourierInformation,
    allPrismicProduct,
    allPrismicCheckout
  },
}) => {

  const { theme } = useThemeUI();

  let product = prismicProduct;
  const productCategories = getProductCategories(
    allPrismicCategory,
    product.uid
  );

  const productSubcategories = getProductSubcategories(
    allPrismicSubcategory,
    product.uid
  );

  const relatedProducts = flattenCategoryProducts(productCategories).filter(
    ({ product: x }: any) => {
      // omit current product
      return x.document.uid !== product.uid;
    }
  );

  const categoryProducts = relatedProducts.slice(0, 10) || [];
  const packageProducts = allPrismicProduct.nodes.filter(x => x.data.options[0]?.is_product_a_package);

  const breadcrumbs = createBreadcrumbs(
    product,
    productCategories[0],
    productSubcategories[0]
  );

  const {
    title1,
    variants,
    options,
    vouchers,
    extras,
    twin_options,
    method_of_pills
  } = product.data;

  let hasExtras = extras.length > 0 && extras[0].title != null;
  let hasMethods = method_of_pills.length > 0 && method_of_pills[0].title != null;

  let variant = variants.length > 0 ? variants[0] : null;
  let option = options?.length > 0 ? options[0] : null;
  let voucher = vouchers?.length > 0 ? vouchers[0] : null;
  let twinOptionsValues = twin_options?.length > 0 ? twin_options[0] : null;

  const availableForSale = variant.available_for_sale;
  const vatRate = variant.vat_rate;
  const onSale = isOnSale(+variant.sale_price, +variant.price1);

  const startPrice = +voucher?.start_price;
  const endPrice = +voucher?.end_price;
  //TODO: REMOVE HARDCODED DEFAULT COURIER PRICE
  const standardCourierServicePrice = allPrismicCheckout.nodes[0].data.standard_courier_service_price + allPrismicCheckout.nodes[0].data.standard_courier_service_vat;
  debugger;
  const availableToSmokers = option?.is_product_available_for_smokers;
  const availableToInternational = option?.is_an_international_product;
  const showhospitalSelection = option?.show_hospital_location_selection;
  const giftVoucher = option?.is_this_product_a_gift_voucher;
  const customGiftVoucher = voucher?.is_product_custom_gift_card;
  const showTwinsOption = twinOptionsValues?.show_twins_option;
  const expectingTwinsPrice = +twinOptionsValues?.twin_price;
  const { products, add, addSmokerProduct, update, updateSmokersProduct, updateExtraItem, updateMethodItem } = useContext(LocalCartContext);

  const isPackageProduct = option?.is_product_a_package;
  const packageProductsInBasket = products.filter(x => x.isPackageProduct);
  const isPackageProductsInBasket = packageProductsInBasket.length > 0;

  const cartProduct = products.find(x => x.uid == product.uid)
  const cartProductQuantity = cartProduct ? cartProduct.quantity : 0;
  const expectingTwinsValue = cartProduct ? cartProduct.expectingTwins : "";

  const [originalPrice, setOriginalPrice] = useState(applyVAT(+variant.price1, vatRate));
  const [quantity, setQuantity] = useState(0);
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [addresses, setAddresses] = useState();
  let [price, setPrice] = useState(onSale ?  applyVAT(+variant.sale_price, vatRate) :applyVAT(+variant.price1, vatRate));
  let [twinPrice, setTwinPrice] = useState(0);
  let [salePrice, setSalePrice] = useState(applyVAT(+variant.sale_price, vatRate));
  const [extraItem, setExtraItem] = useState();
  const [methodItem, setMethodItem] = useState();
  const [showCounter, setShowCounter] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [productImages, setProductImages] = useState(product?.data?.images.slice(1));
  const [productImage, setProductImage] = useState(product?.data?.images[0]?.image?.gatsbyImageData);
  const [voucherRecipientName, setVoucherRecipientName] = useState(giftCard?.voucherRecipientName);
  const [voucherRecipientEmail, setVoucherRecipientEmail] = useState(giftCard?.voucherRecipientEmail);
  const [voucherMessage, setVoucherMessage] = useState(giftCard?.voucherMessage);
  const [currencyValue, setCurrencyValue] = useState(giftCard?.voucherPrice);
  const [customGiftVoucherAmount, setCustomGiftVoucherAmount] = useState(+giftCard?.voucherPrice);
  const [customGiftCardButtonTitle, setCustomGiftCardButtonTitle] = useState(giftCard !== undefined ? "Edit Voucher" : "Continue");
  const [voucherDeliveryDateValue, setVoucherDeliveryDateValue] = useState<number | undefined>(giftCard?.voucherDeliveryDate);
  const [customGiftCardReadOnly, setCustomGiftCardReadOnly] = useState(giftCard !== undefined ? true : false);
  const [twinsValue, setTwinsValue] = useState("");
  const [extraMessage, setExtraMessage] = useState("");
  const [methodMessage, setMethodMessage] = useState("");
  const [twinMessage, setTwinMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const hospital = getLocalState("hospital");
  const courierFee = +getLocalState("courierPrice");

  const courierService = getLocalState("courierService") == "standard";
  const [standardCourierService, setStandardCourierService] = useState(courierService ?? true);
  const [premiumCourierService, setPremiumCourierService] = useState(!courierService);
  const [hospitalNameValue, setHospitalNameValue] = useState(hospital?.name);
  const [courierPrice, setCourierPrice] = useState(premiumCourierService ? hospital?.charge ?? 0 : courierFee);

  const counterRef = useRef(null!);
  const courierDisclaimer = prismicCourierInformation.data.disclaimer.richText;

  useOnClickOutside(counterRef, () => setShowCounter(false));

  var giftCard = getLocalState('giftcard')

  useEffect(() => {
    if (cartProduct) {
      setTwinPrice(getTwinPrice());
    }
  }, []);

  useEffect(() => {
    setQuantity(cartProductQuantity);
    if (showhospitalSelection) {
      GetHospitals().then((result) => {
        setAddresses(result);
        setLoadingAddresses(false);
      });
    }
  }, [cartProductQuantity]);

  const getTwinPrice = () => {
    const twinPrice = applyVAT(cartProduct.expectingTwinsPrice, cartProduct.vatRate);
    const totalPrice = product.quantity ? Math.round(twinPrice * cartProduct.quantity) : Math.round(twinPrice);

    return totalPrice + applyVAT(price, vatRate);
  };

  const handleQuantityChange = (quantity: number) => {
    update(product.uid, quantity);
    updateSmokersProduct(product.uid, quantity);

    if (quantity < 1) {
      setShowCounter(false);
    }
  };

  const handleVoucherModal = () => {
    setShowVoucherModal(false);
  }

  const handleFixedAmount = () => {
    setCustomGiftVoucherAmount(startPrice);
  }

  const validateOptions = (): boolean => {
    const error = "Required";
    let valid = true;

    if (!extraItem && !twinsValue && hasExtras && showTwinsOption) {
      setExtraMessage(error);
      setTwinMessage(error);
      valid = false;
    }

    if (!twinsValue && showTwinsOption) {
      setTwinMessage(error);
      valid = false;
    }

    if (!extraItem && hasExtras) {
      setExtraMessage(error);
      valid = false;
    }
    return valid;
  }

  const handleAddToCart = () => {
    let drawerVisible = true;
    
    setErrorMessage('');

    if (!validateOptions()) {
      setErrorMessage("Please fix any errors above");
      return;
    }

    if (!isPackageProductsInBasket
      && !isPackageProduct
      && !giftVoucher
      && !customGiftVoucher) {
      setShowPackageModal(true);
      drawerVisible = false;
    }

    setShowCounter(true);

    let uid = product.uid;
    let title = title1;

    if (quantity < 1) {
      const item: any = {
        title: title,
        thumbnail: product?.data?.images[0]?.image?.gatsbyImageData,
        imageSrc: product?.data?.images[0]?.image?.gatsbyImageData.images.fallback.src,
        quantity: 1,
        price: getPrice(),
        currency: "GBP",
        uid: uid,
        vatRate: vatRate,
        priceBeforeVat: +variant.price1,
        salePriceBeforeVat: +variant.sale_price,
        availableToSmokers: availableToSmokers,
        availableToInternational: availableToInternational,
        isPackageProduct: isPackageProduct,
        isGiftVoucher: giftVoucher, 
        isCustomGiftVoucher: customGiftVoucher,
        expectingTwins: twinsValue == 'yes',
        expectingTwinsPrice: twinsValue == 'yes' ? expectingTwinsPrice : 0,
        availableForSale: availableForSale,
        description: product?.data?.description,
        onSale: onSale,
        salePrice: +salePrice,
        unitPrice: getUnitPrice(),
        showTwinsOption: showTwinsOption,
        twinOptions: twinOptions(),
        extraItem: extraItem,
        methodItem: methodItem
      };

      if (!availableToSmokers) {
        addSmokerProduct(item);
      }

      add(item);
      setCartVisible(drawerVisible);
    }
  };

  const getPrice = () => {
    if (customGiftVoucherAmount) {
      return customGiftVoucherAmount;
    }
    else {
      return price;
    }
  }

  const addClass = ["productCard"];
  if (quantity > 0 && showCounter) {
    addClass.push("active");
  }

  const setMainProductImage = (image: string) => {
    setProductImage(image);
    setProductImages(product?.data?.images);
  };

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const handleCourierPrice = (selected: any) => {
    if (selected.length > 0) {
      setCourierPrice(selected[0].charge);
      setLocalState("hospital", selected[0]);
      setHospitalNameValue(selected[0].name);
    };
  }

  const getUnitPrice = () => {
    const unitPrice = onSale ? +variant.sale_price : +variant.price1;
    return applyVAT(unitPrice, vatRate);
  }

  const handleExpectingTwins = (selected: any,) => {
    setTwinMessage('');

    const price = onSale ? +variant.sale_price : +variant.price1;
    const priceWithVat =  applyVAT(price, vatRate);

    let newPrice = 0;

    const expectingTwins = selected.value == 'yes';


    if (selected.value == 'yes') {
      const twinPriceWithVat =  applyVAT(expectingTwinsPrice, vatRate);
      newPrice = priceWithVat + twinPriceWithVat;
      setOriginalPrice(applyVAT(+variant.price1, vatRate) + twinPriceWithVat);
    }
    else {
      newPrice = priceWithVat;
    }

    setPrice(newPrice);
    setTwinsValue(selected.value);


    update(product.uid, quantity, newPrice, expectingTwins, expectingTwinsPrice);
    updateSmokersProduct(product.uid, quantity, newPrice, expectingTwins, expectingTwinsPrice);
  };

  const twinOptions = () => {
    return [
      { value: 'yes', label: 'Yes +' + formatPrice(Math.round(applyVAT(expectingTwinsPrice, vatRate))) },
      { value: 'no', label: 'No' }
    ];
  }

  const getExtraOptions = () => {
    const extraOptions: any[] = [];
    extras.map((x: any) =>
      extraOptions.push({
        value: x.title,
        label: x.title
      })
    )
    return extraOptions;
  }

  const getTwinOptionValue = () => {
    if (cartProduct) {
      const options = twinOptions();
      return options.filter(x => x.value === (cartProduct.expectingTwinsPrice ? 'yes' : 'no'));
    }
  }

  const getExtraOptionValue = () => {
    if (cartProduct) {
      return [{
        value: cartProduct.extraItem,
        label: cartProduct.extraItem
      }]
    }
  }

  const getMethodOptionValue = () => {
    if (cartProduct) {
      return [{
        value: cartProduct.methodItem,
        label: cartProduct.methodItem
      }]
    }
  }

  const getMethodOptions = () => {
    const methodOptions: any[] = [];
    method_of_pills.map((x: any) =>
      methodOptions.push({
        value: x,
        label: x.title
      })
    )
    return methodOptions;
  }

  const handleExtraItem = (selected: any) => {
    setExtraMessage('');
    setExtraItem(selected.label);

    updateExtraItem(product.uid, selected.label);
  }

  const handleMethodItem = (selected: any) => {
    setMethodMessage('');
    setMethodItem(selected.label);

    updateMethodItem(product.uid, selected.label);
  }

  const handleStandardCourierService = () => {
    setPremiumCourierService(false);
    setStandardCourierService(true);

    setLocalState("courierPrice", standardCourierServicePrice);
    setLocalState("courierService", "standard");

  };

  const handlePremiumCourierService = () => {
    setPremiumCourierService(true);
    setStandardCourierService(false);

    const hospital = getLocalState("hospital");
    setCourierPrice(hospital?.charge ?? 0);
    setLocalState("courierService", "premium");
  };

  return (
    <PrimaryLayout
      bgColor="#ffffff"
      fluid={true}
      homeLink="/"
      pathPrefix=""
      topMenuChild={ProductTopMenu.bind(null, { breadcrumbs: breadcrumbs })}
    >
      <div sx={styles.container} className="container">
        <Box sx={styles.wrapper}>
          <SEO title={title1} />
          <div className="row">
            <div className="col-md-6">
              <Box sx={styles.imageWrapper}>
                <Button
                  sx={styles.zoomIn}
                  onClick={() => {
                    handleZoomChange(true);
                  }}
                >
                  <BsArrowsAngleExpand />
                </Button>
                <Box sx={styles.productImage}>
                  <ControlledZoom
                    isZoomed={isZoomed}
                    onZoomChange={handleZoomChange}
                    openText={"Zoom product image"}
                    closeText={"Close zoomed product image"}
                    wrapStyle={{ width: '100%' }}
                  >
                    <GatsbyImage
                      alt={product.data.title1 ?? ""}
                      image={productImage}
                      imgStyle={{ objectFit: "scale-down" }}
                      className="w-100"
                      imgClassName="text-center"
                    />
                  </ControlledZoom>
                </Box>
              </Box>
              <ul sx={styles.thumnailImages}>
                {productImages.map((item: any, index: any) => (
                  <span key={index}>
                    <li>
                      <Box
                        sx={styles.thumbnailWrapper}
                        onClick={() => {
                          setMainProductImage(item?.image?.gatsbyImageData);
                        }}
                      >
                        <GatsbyImage
                          key={item?.image?.id}
                          image={item?.image?.gatsbyImageData}
                          alt={title1 ?? ""}
                          imgStyle={{ height: "initial" }}
                        />
                      </Box>
                    </li>
                  </span>
                ))}
              </ul>
            </div>

            <div className="col-md-6">
              <Box sx={styles.content}>
                <Box sx={styles.header}>
                  <Heading as="h1">{title1}</Heading>
                  <Box sx={styles.priceWrapper}>
                    <Text sx={styles.price}>
                      {!customGiftVoucher ? (
                        <span>
                          {onSale ? (
                            <div>
                              <span sx={styles.price.sale}>
                                {formatPrice(originalPrice)}
                              </span>

                              <span sx={styles.price}>
                                {formatPrice(price)}
                              </span>
                            </div>
                          ) : (
                            <span sx={styles.price}>
                              {formatPrice(price)}
                            </span>
                          )}
                        </span>
                      ) : (
                        <React.Fragment>
                          {formatPrice(applyVAT(startPrice, vatRate))} - {formatPrice(applyVAT(endPrice, vatRate))}
                        </React.Fragment>
                      )}
                    </Text>
                  </Box>

                  {!availableForSale && (
                    <Box sx={styles.noticeWrapper}>
                      <Box sx={theme.notice} variant={"notice.modes.danger"}>
                        <Text sx={theme.notice.text}>
                          <p sx={theme.notice.title}>
                            Sorry I’m not available right now, I’ll be back soon!
                          </p>
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box sx={styles.description}>
                  <PrismicRichText field={product?.data?.description.richText} />

                  {showTwinsOption && expectingTwinsPrice > 0 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-5">
                          <b>Are you expecting twins?</b>
                        </div>
                        <div className="col-md-6">
                          <span sx={styles.form.control}>
                            <Select
                              value={getTwinOptionValue()}
                              name="expectingTwins"
                              id="expectingTwins"
                              classNamePrefix="react-select"
                              options={twinOptions()}
                              onChange={(x) => handleExpectingTwins(x)}
                            />
                          </span>
                        </div>
                        {twinMessage && (
                          <p sx={styles.input.error}>{twinMessage}</p>
                        )}
                      </div>
                      <br />
                      {twinsValue == 'yes' && (
                        <span>This adds to the process and there is a small additional charge of {formatPrice(Math.round(applyVAT(expectingTwinsPrice, vatRate)))} <br /><br /></span>
                      )}
                    </React.Fragment>
                  )}

                  {hasExtras && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-5">
                          <b>Extra cosmetic option</b>
                        </div>

                        <div className="col-md-6">
                          <span sx={styles.form.control}>
                            <Select
                              value={getExtraOptionValue()}
                              name="extras"
                              id="extras"
                              classNamePrefix="react-select"
                              options={getExtraOptions()}
                              onChange={(x) => handleExtraItem(x)}
                            />
                          </span>
                        </div>
                        {extraMessage && (
                          <p sx={styles.input.error}>{extraMessage}</p>
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  {hasMethods && (
                    <React.Fragment>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <b>Method of Pills</b>
                        </div>

                        <div className="col-md-6">
                          <span sx={styles.form.control}>
                            <Select
                              value={getMethodOptionValue()}
                              name="methods"
                              id="methods"
                              classNamePrefix="react-select"
                              options={getMethodOptions()}
                              onChange={(x) => handleMethodItem(x)}
                            />
                          </span>
                        </div>
                        {extraMessage && (
                          <p sx={styles.input.error}>{extraMessage}</p>
                        )}
                      </div>
                      <br />
                    </React.Fragment>
                  )}
                  {customGiftVoucher && (
                    <Box className="row" style={{ marginTop: '20px' }}>
                      <br />

                      <div className="col-md-2">
                        <Button
                          sx={styles.cartBtn}
                          title={`Buy ${formatPrice(startPrice)} Voucher`}
                          onClick={() => handleFixedAmount()}
                        >
                          <Text>{formatPrice(startPrice)}</Text>
                        </Button>
                      </div>
                      <div className="col-md-10">
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "GBP" }}
                          prefix="£"
                          type="text"
                          name="input-name"
                          sx={styles.input}
                          className="form-control"
                          decimalsLimit={2}
                          value={customGiftVoucherAmount > 0 ? customGiftVoucherAmount : ""}
                          placeholder={`Enter custom amount. Max allowed ${formatPrice(endPrice)}`}
                          onValueChange={(value, name) => setCustomGiftVoucherAmount(+value)}
                          disableAbbreviations={true}
                          maxLength={4}
                          readOnly={customGiftCardReadOnly}
                        />
                      </div>
                    </Box>
                  )}

                  {showhospitalSelection && (
                    !loadingAddresses ? (
                      <Box sx={styles.courier}>
                        <p>
                          <b>Please select you preferred Placenta collection option.</b>
                        </p>
                        <p>
                          <label>
                            <input
                              type="radio"
                              id="standard"
                              checked={standardCourierService}
                              onChange={handleStandardCourierService}
                            />{" "}Standard Service
                          </label>

                          <label style={{ marginLeft: '20px' }}>
                            <input
                              type="radio"
                              id="standard"
                              checked={premiumCourierService}
                              onChange={handlePremiumCourierService}
                            />{" "}Premium Service
                          </label>
                          <br />
                          <hr className="bar-indent" />
                          {standardCourierService && (
                            <React.Fragment>
                              This option is not a on call 24/7 service. We understand that mum’s want their
                              placenta processed in a lab setting but don’t want to pay the courier fee.
                              With this service your placenta is collected from your home address between the hours of 9am-5pm.
                              It is not collected within hour’s of birth from hospital like our premium service.
                              <br />
                              <br />
                              <Accordion allowZeroExpanded={true}>
                                <AccordionItem uuid="accordionCourierItem">
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      YOUR COURIER FEE WILL BE {" "}
                                      <b>{formatPrice(standardCourierServicePrice)}</b> {" "}
                                      <i>(VAT Included)</i>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <PrismicRichText field={courierDisclaimer} />
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>

                            </React.Fragment>
                          )}

                          {premiumCourierService && (
                            <React.Fragment>
                              Our premium service is a stress free seamless service.
                              With this option your placenta is collect within hour’s from hospital or home birth.
                              Our dedicated team are on hand 24/7 waiting for your call. Our courier’s are all trained and
                              licensed to transport human tissue ensuring the highest level of safety start to finish.

                              Enter the name of your hospital below to find the courier cost which
                              will be added at the checkout.

                              If you are having a home birth please select the hospital you go for your scans.
                              <br />
                              <br />
                              <LocationInput
                                onChange={handleCourierPrice}
                                addresses={addresses}
                                defaultInputValue={hospitalNameValue} />

                              {courierPrice > 0 && (
                                <Accordion allowZeroExpanded={true}>
                                  <AccordionItem uuid="accordionCourierItem">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        YOUR COURIER FEE WILL BE {" "}
                                        <b>{formatPrice(courierPrice)}</b> {" "}
                                        <i>(VAT Included)</i>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <PrismicRichText field={courierDisclaimer} />
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>

                              )}
                            </React.Fragment>
                          )}
                        </p>
                      </Box>
                    ) : (
                      <Box sx={styles.courier}>
                        <span sx={styles.spinner}>
                          <Spinner animation="border" role="status">
                            <span className="sr-only"></span>
                          </Spinner>
                        </span>
                      </Box>
                    )
                  )}
                </Box>

                <div className="col-md-12">
                  <div className="row">
                    {!isPackageProductsInBasket && !isPackageProduct && (
                      <PackageModal
                        showModal={showPackageModal}
                        setShowModal={setShowPackageModal}
                        showCloseButton={true}
                        description={<PackageDescription title={title1} />} />

                    )}
                    <div className="col-md-6 offset-md-6">
                      <div
                        sx={styles.cartArea}
                        className={
                          quantity > 0 && showCounter ? "isActive" : ""
                        }
                      >
                        {!quantity ? (
                          <Box>
                            {!availableForSale ? (
                              <Button
                                sx={styles.cartBtn}
                                title="Not Available"
                                disabled={true}
                              >
                                <Text>Not Available</Text>
                              </Button>
                            ) : (
                              <Box>
                                {!customGiftVoucher ? (
                                  <React.Fragment>
                                    {errorMessage && (
                                      <p sx={styles.input.error}>{errorMessage}</p>
                                    )}
                                    <Button
                                      sx={styles.cartBtn}
                                      className={addClass.join(" ")}
                                      title="Add to Basket"
                                      onClick={() => handleAddToCart()}
                                    >
                                      <Text>
                                        <AiOutlineShopping className="product-card-cta" />{" "}
                                        Add to Basket
                                      </Text>
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <GiftVoucherButton title={customGiftCardButtonTitle} setShowVoucherModal={setShowVoucherModal} />
                                )}
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Box>
                            {!customGiftVoucher ? (
                              <Box ref={counterRef} sx={styles.cartCounter}>
                                <Button
                                  sx={styles.cartCounter.decrement}
                                  title="Decrement"
                                  onClick={() => handleQuantityChange(quantity - 1)}
                                >
                                  <IoIosRemove />
                                </Button>
                                <Box>{quantity}</Box>
                                <Button
                                  sx={styles.cartCounter.increment}
                                  title="Increment"
                                  onClick={() => handleQuantityChange(quantity + 1)}
                                >
                                  <IoIosAdd />
                                </Button>
                              </Box>
                            ) : (
                              <GiftVoucherButton title={customGiftCardButtonTitle} setShowVoucherModal={setShowVoucherModal} />
                            )}
                          </Box>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>

          {!isPackageProduct && !giftVoucher && !customGiftVoucher ? (
            <Box id="product-grid" sx={styles.productGrid}>
              <ProductGrid
                id="Our Packages"
                type="package"
                gridTitle="Our Packages"
                products={packageProducts}
                withLink={true}
                isCategoryProduct={false}
                showProductPreview={true}
                showFilter={true}
              />
            </Box>
          ) : (
            <Box sx={styles.productGrid}>
              <ProductGrid
                id="relatedProducts"
                type="related"
                gridTitle="Related Products"
                products={categoryProducts}
                withLink={true}
                isCategoryProduct={true}
              />
            </Box>
          )}
        </Box>

        <Modal
          show={showVoucherModal}
          onHide={handleVoucherModal}
          keyboard={false}
          centered
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>
              Personalise Voucher
            </Modal.Title>
            <Button title="Close"
              variant="text"
              onClick={() => setShowVoucherModal(false)}
              style={{ float: 'right' }}>
              <IoIosClose />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <GiftVoucherForm
              product={product}
              price={customGiftVoucherAmount}
              endPrice={endPrice}
              setPaymentAmount={setCustomGiftVoucherAmount}
              setShowVoucherModal={setShowVoucherModal}
              setCartVisible={setCartVisible}
              setVoucherRecipientName={setVoucherRecipientName}
              setVoucherRecipientEmail={setVoucherRecipientEmail}
              setVoucherMessage={setVoucherMessage}
              setCurrencyValue={setCurrencyValue}
              setVoucherDeliveryDateValue={setVoucherDeliveryDateValue}
              voucherRecipientName={voucherRecipientName}
              voucherRecipientEmail={voucherRecipientEmail}
              voucherMessage={voucherMessage}
              voucherDeliveryDateValue={voucherDeliveryDateValue}
              setCustomGiftCardButtonTitle={setCustomGiftCardButtonTitle} />
          </Modal.Body>
        </Modal>
      </div>

      <DrawerCart
        open={cartVisible}
        onClick={() => setCartVisible(false)}
        onClose={() => setCartVisible(false)}
        products={products}
      />
    </PrimaryLayout>
  );
};

const PackageDescription = (props: any) => {
  return (
    <React.Fragment>
      <p><b>{props.title}</b> has been added to your basket, however, checkout is not possible until a selection from our featured product categories is made.</p>
      <p>You can close this and continue shopping.</p>
    </React.Fragment>
  )
}

const GiftVoucherButton = (props: any) => {
  return (
    <Button
      sx={styles.cartBtn}
      title={props.title}
      onClick={() => props.setShowVoucherModal(true)}
    >
      <Text>
        {props.title}
      </Text>
    </Button>
  )
}

export const pageQuery = graphql`
  query ($uid: String!) {
    prismicProduct(uid: { eq: $uid }) {
      uid
      id
      tags
      data {
        title1
        description {
          text
          html
          richText
        }
        variants {
          available_for_sale
          price1
          sale_price
          vat_rate
        }
        description {
          text
          html
          richText
        }
        images {
          image {
            alt
            copyright
            url
            gatsbyImageData
          }
          image {
            gatsbyImageData
          }
        }
        options {
          is_an_international_product
          is_product_available_for_smokers
          show_hospital_location_selection
          is_product_a_package
          is_this_product_a_gift_voucher
          is_product_visible_on_site
        }
        twin_options {
          show_twins_option
          twin_price
        }
        extras {
          title
        }
        method_of_pills {
          title
        }
        vouchers {
          is_product_custom_gift_card
          start_price
          end_price
        }
      }
    }
    allPrismicSubcategory {
      nodes {
        uid
        data {
          title {
            text
            html
            richText
          }
          products {
            product {
              document {
                ... on PrismicProduct {
                  data {
                    title1
                  }
                  uid
                }
              }
            }
          }
        }
      }
    }
    allPrismicCategory {
      nodes {
        uid
        data {
          title {
            text
            html
            richText
          }
          products {
            product {
              document {
                ... on PrismicProduct {
                  uid
                  id
                  tags
                  data {
                    images {
                      image {
                        gatsbyImageData
                      }
                    }
                    title1
                    variants {
                      available_for_sale
                      price1
                      sale_price
                      vat_rate
                    }
                    description {
                      text
                      html
                      richText
                    }
                    options {
                      is_an_international_product
                      is_product_available_for_smokers
                      show_hospital_location_selection
                      is_product_a_package
                      is_this_product_a_gift_voucher
                      is_product_visible_on_site
                    }
                    twin_options {
                      show_twins_option
                      twin_price
                    }
                    extras {
                      title
                    }
                    method_of_pills {
                      title
                    }
                    vouchers {
                      is_product_custom_gift_card
                      start_price
                      end_price
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicCourierInformation {
      data {
        disclaimer {
          richText
        }
      }
    }
    allPrismicCheckout {
          nodes {
            data {
              standard_courier_service_price
              standard_courier_service_vat
            }
          }
        }
    allPrismicProduct {
      nodes {
        uid
        data {
          title1
          images {
            image {
              alt
              gatsbyImageData
            }
          }
          variants {
            available_for_sale
            price1
            sale_price
            vat_rate
          }
          options {
            is_an_international_product
            is_product_available_for_smokers
            show_hospital_location_selection
            is_product_a_package
            is_this_product_a_gift_voucher
            is_product_visible_on_site
          }
          twin_options {
            show_twins_option
            twin_price
          }
          extras {
            title
          }
          method_of_pills {
            title
          }
          vouchers {
            is_product_custom_gift_card
            start_price
            end_price
          }
          description {
            richText
          }
        }
      }
    }
  }
`;

export default ProductPage;
