/** @jsx jsx */
import React, { FC } from "react"
import { jsx, Button, Text } from "theme-ui"
import { Modal } from "react-bootstrap"
import { IoIosClose } from 'react-icons/io'
import { GoPackage } from "react-icons/go";
import { navigate } from 'gatsby'

const PackageModal: FC<any> = (props) => {

  const handleViewPackages = () => {

    props.setShowModal(false);
    navigate("/packages");

    if (typeof window !== `undefined` && window.location.search == '?medical') {
      // debugger;
    }
    else {
      navigate("/featured");
    }
  };

  return (
    <React.Fragment>

      <Modal
        show={props.showModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Please select a featured product
            {props.showCloseButton && (
              <IoIosClose sx={styles.modal.close} onClick={() => props.setShowModal(false)} />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.description ? (
            <React.Fragment>
              {props.description}
            </React.Fragment>
          ) : (
            <p>You must first choose one of our featured products before you can continue to checkout.</p>
          )}

          <hr className="bar-indent-center" />
          <Button onClick={() => handleViewPackages()}
            sx={styles.packageButton}
            title="View Products"
          >
            <Text>
              <GoPackage className="product-card-cta" />{" "}
              View Products
            </Text>
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

const styles = {
  modal: {
    close: {
      position: 'absolute',
      right: '3px',
      fontSize: '40px',
      top: '5px',
      cursor: 'pointer'
    },
  },
  packageButton: {
    width: '100%',
    textDecoration: 'unset',
    textTransform: 'uppercase',
    display: 'flex',
    opacity: 1,
    transform: 'scale(1)',
    transition: 'all 0.2s ease',
    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
      backgroundColor: "#E6E6E6",
      borderColor: "#E6E6E6",
      color: "#878788",
      fontWeight: 500
    },
    backgroundColor: '#00d4c5',
    fontWeight: 'normal',
    fontSize: '13px',
    color: 'white',
    borderRadius: '10px',
    padding: '15px 20px 15px 20px',
    '@media only screen and (min-width: 768px) and (min-width: 1400px)': {
      minWidth: '0!important',
      marginTop: '0'

    },
    minWidth: '100%!important',
    minHeight: '40px!important',
    svg: {
      margin: 0,
      width: '23px',
      height: '23px',
      marginTop: '-8px',
    },
    link: {
      lineHeight: '40px',
      margin: '0 auto'
    }
  },
}

export default PackageModal
