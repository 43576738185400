import axios from "axios";
import { removeJwtTokens, setJwtTokens, validateJwtToken } from './auth-service';
import { removeLocalStateItem, setLocalState } from '../helper/local-storage';
import { SendError } from "./error-log-service";
import { navigate } from "gatsby";

const PAYMENT_INTENT_ID = 'paymentIntentId';

/**
   * @description Logs user in and sets JWT cookies
   * @returns {String} If error API response message, success sets JwtToken cookies and logs the user in
 */
export const Login = async (data: any) => {
    return await axios.post(`user/login/`, data).then(function (response: any) {
        setJwtTokens('token', response.data.token, 120);
        setJwtTokens('refresh-token', response.data.refreshToken, 120);
        if (validateJwtToken('token')) {
            let href = '/account/orders';
            var urlSearchParams = new URLSearchParams(window.location.search);
            if (urlSearchParams.has('checkout')) href = '/checkout';
            if (urlSearchParams.has('medical')) 
            {
                removeLocalStateItem("smoker");
                removeLocalStateItem("medical");
                href = '/checkout?medical';
            }
            navigate(href);
        }        
    }).catch(function (error: any) {
        SendError(error);
        return error.response?.data.message;
    });
}

/**
   * @description Register user and sends email confirmation
   * @returns {String} If API response message success it sets JwtToken cookies and logs the user in
 */
export const Register = async (data: any) => {
    return await axios.post(`user/register/`, data).then(function (response: any) {
        setJwtTokens('token', response.data.token, 120);
        setJwtTokens('refresh-token', response.data.refreshToken, 120);
        if (validateJwtToken('token')) navigate('/account/orders');
        removeLocalStateItem("medical");
    }).catch(function (error: any) {
        SendError(error);
        return error.response?.data.message;
    });
}

/**
   * @description Register guest user with no access rights
   * @returns {String} 200 response if successful 
 */
 export const RegisterGuestUser = async (data: any) => {
    return await axios.post(`user/guest/`, data).then(function (response: any) {
        setJwtTokens('guest-token', response.data.token, 1);
        return response;
    }).catch(function (error: any) {
        SendError(error);
        return error.response?.data.message;
    });
}

/**
   * @description Update authenticated user email address
   * @returns {String} API response message
 */
export const UpdateUserEmail = async (data: any) => {
    return await axios.post(`user/email/`, data).then(function (response: any) {
        return response.data;
    }).catch(function (error: any) {
        return error.response?.data.message;
    });
}

/**
   * @description Update authenticated user password
   * @returns {String} API response message
 */
export const UpdateUserPassword = async (data: any) => {
    return await axios.post(`user/password/`, data).then(function (response: any) {
        return response.data;
    }).catch(function (error: any) {
        return error.response?.data.message;
    });
}

/**
   * @description Schedules user user for deletion and removes cookie tokens
 */
export const DeleteAccount = async (id: any) => {
    return await axios.put(`user/delete/${id}`).then(function (response: any) {
        if (response.data !== 0) {
            removeJwtTokens('/login');
        }
    });
}

/**
   * @param {number} id
   * @description Log authenticated user out by user id remove cookie tokens
 */
export const Logout = async (id: any) => {    
    return await axios.put(`user/logout/${id}`).then(function (response: any) {
        if (response.data !== 0) {
            setLocalState("isSignedUp", false)
            removeJwtTokens('/login');            
        }
    });
}

/**
   * @param {String} email
   * @description Send reset email password to geniune user holders
 */
export const RequestAccountResetPassword = async (data: any) => {
    return await axios.post(`user/requestpassword/`, data).then(function (response: any) {
        return response;
    });
}

/**
   * @description Reset accont holder password
 */
export const ResetAccountResetPassword = async (data: any) => {
    return await axios.post(`user/resetpassword/`, data).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
        return error.response;
    });
}

/**
* @param {String} token
* @description Validates the encrypted data in the reset password query string
*/
export const ValidateResetPasswordToken = async (data: any) => {
    return await axios.post(`user/validatetoken/`, data).then(function (response: any) {
        return response;
    });
}

/**
   * @description Get authenticated user details by user Id
   * @returns {any} object of user
 */
 export const GetUserDetails = async (id: any) => {
    return await axios.get(`user/${id}`).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
       return error;
    });
}
