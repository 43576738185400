import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { getShortDateDashSeperated } from "../../helper/datetime-helper";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      dateFormat={'dd/MM/yyyy'}
      onChange={val => {
        props.dateChangeCallback(val);
        props.setFieldTouchedCallback(field.name);
        setFieldValue(field.name, val);
      }}
      minDate={new Date(props.minDate) }
      filterDate={props.filterDates}
    />
  );
};

export default DatePickerField;
