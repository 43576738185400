const styles = {
	wrapper: {
		display: 'inline-block',
		margin: '0 auto',
		padding: 10
	},
	logo: {
		maxWidth: '355px',
		margin: '0 auto',
		height: 'inherit',
		'@media only screen and (max-width: 992px)': {
			maxWidth: '220px',
			marginLeft: '-32px',
		}
	}
};

export default styles;
