const styles = {
	fieldWrapper: {
		alignItems: 'center',
		position: 'relative',
		width: '100%',
		input: {
			width: '100%',
			fontSize: 1,
			fontWeight: '400',
			borderRadius: 10,
			padding: '14px 25px 14px 0',
			backgroundColor: 'white',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: 'muted',
			boxShadow: 'none',
			transition: 'border-color 0.2s ease',
			textIndent: '20px',
			'&:focus': {
				outline: 'none',
				boxShadow: 'none',
				borderColor: 'darkgray',
			},
		},
		svg: {
			fill: 'text',
			position: 'absolute',
			'&.search-icon': {
				right: 25,
				pointerEvents: 'none',
			},
			'&.mobile-search-icon': {
				top: '19px',
				right: 25,
				pointerEvents: 'none',
			}
		}
	},
	searchResult: {
		h1: {
			textAlign: 'center',
			'@media only screen and (max-width: 992px)': {
				display: 'none'
			},
		},
		position: 'fixed',
		marginTop: '90px',
		height: 'calc(100vh - 79px)',
		overflow: 'auto',
		zIndex: 10,
		width: '100vw',
		left: 0,
		backgroundColor: 'white',
		minHeight: '100vh',
		paddingX: 40,
		paddingTop: 40,
		paddingBottom: 230,
		borderTopWidth: '1px',
		borderTopStyle: 'solid',
		borderTopColor: 'muted',

		'@media only screen and (max-width: 1050px)': {
			marginTop: '17px',
		},
		'@media only screen and (max-width: 1360px)': {
			paddingX: 30,
			paddingTop: 30,
		},
		'@media only screen and (max-width: 480px)': {
			paddingX: 20,
			paddingTop: 20,
			paddingBottom: 150,
		},
		'&.notFound': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'>div': {
			padding: 0,
			marginBottom: 0,
			backgroundColor: 'transparent',
			'>h3': {
				fontSize: [3, 4],
				fontFamily: 'body',
				marginBottom: 30,
			},
		},
		banner: {
			backgroundColor: 'floralwhite',
			color: '#3a6201',
			border: '.5px solid #e4e4e4',
			borderTopLeftRadius: '100% 100%',
			borderTopRightRadius: '100% 100%',
			borderBottom: 'none',
			padding: 20,
			textAlign: 'center'
		},
		titleIcon: {
			display: 'block',
			margin: '0 auto',
			paddingBottom: '8px',
			'@media only screen and (max-width: 992px)': {
				display: 'none'
			}
		}
	},
	notFoundCard: {
		textAlign: 'center',
		img: {
			marginBottom: 30,
		},
		h2: {
			fontSize: 2,
			color: 'primary',
			marginBottom: [12, 15],
		},
	},
	bar: {
		width: 150,
		marginBottom: '40px!important'
	},
	filter: {
		option: {
			backgroundColor: '#dae8db',
			borderRadius: '20px',
			color: '#685f52',
			minWidth: '0!important',
			fontWeight: 'normal',

			'&:hover': {
				backgroundColor: '#00d4c5',
				color: 'white'

			},
			'&.active': {
				backgroundColor: '#00d4c5',
				color: 'white'
			},
		},
	},
	searchButton: {
		fontWeight: '400',
		backgroundColor: '#00d4c5',
		fontSize: '14px',
		right: '4px',
		top: '4px',
		bottom: '4px',
		height: 'unset',
		padding: '10px 24px',
		width: 'unset',
		border: 'none',
		color: 'white',
		position: 'absolute',
		borderRadius: 30,
		'@media only screen and (max-width: 992px)': {
			display: 'none'
		}
	},
	clearButton: {
		fontWeight: '400',
		backgroundColor: '#B3B5B4',
		fontSize: '14px',
		right: '4px',
		top: '4px',
		bottom: '4px',
		height: 'unset',
		padding: '10px 24px',
		width: 'unset',
		border: 'none',
		color: 'white',
		position: 'absolute',
		borderRadius: 10,
		'@media only screen and (max-width: 992px)': {
			padding: '0 15px',
		}
	},
	searchDivider: {
		'@media only screen and (max-width: 992px)': {
			display: 'none'
		}
	}
}

export default styles
