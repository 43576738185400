import { getSessionItem, setSessionItem } from '../helper/session-storage';
import { SendErrorMessage } from "../services/error-log-service";
import axios from 'axios';

export const getCountry = async () => {
  var countryCode = getSessionItem("countryCode");

  if (countryCode === undefined) {
    var res = await axios.get("/geo");
    countryCode = res.data.success ? res.data.countryCode : "GB";
    if (!res.data.success){
      SendErrorMessage("Could not find country code")
    }
    setSessionItem("countryCode", countryCode);
    
    return countryCode;
  }

  return undefined;
}