const styles = {
	socialLinks: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: -10,
		marginRight: -10,
		a: {
			display: 'flex',
			alignItems: 'center',
			fontSize:'2em !important',
			marginLeft: 10,
			marginRight: 10,
			textDecoration: 'none',
			padding: '5px',
			backgroundColor: '#00d4c5',
			width: '30px',
			height: '30px',
			borderRadius: '50%',
			color: '#685f52',
			margin: 1,
			':hover': {
				color: 'primary',
			},
			'&.facebook': {
				color: 'facebook',
				':hover': {
					color: 'facebook',
				},
			},
			'&.twitter': {
				color: 'twitter',
				':hover': {
					color: 'twitter',
				},
			},
			'&.youtube': {
				color: 'youtube',
				':hover': {
					color: 'youtube',
				},
			},
			'&.github': {
				color: 'github',
				':hover': {
					color: 'github',
				},
			},
			'&.instagram': {
				color: 'instagram',
				':hover': {
					color: 'instagram',
				},
			},
			'&.linkedin': {
				color: 'linkedin',
				':hover': {
					color: 'linkedin',
				},
			},
			'&.tiktok': {
				color: 'tiktok',
				':hover': {
					color: 'tiktok',
				},
			}
		}
	}
};

export default styles;
