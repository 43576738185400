import { Date } from "prismic-reactjs";
import { format } from "date-fns-tz";

export const getMonthYearFormat = (date: any) => {
    const blog = date.replaceAll(" ", "-").replaceAll(",", "");
    return blog.substring(blog.indexOf("-") + 1).toLowerCase();
};

export const GetAllowedQueryParams = (params: any) => {
    const filteredBy = [];
    if (params.date) {
        filteredBy.push("date");
    }
    if (params.category) {
        filteredBy.push("category");
    }
    if (params.tag) {
        filteredBy.push("tag");
    }
    return filteredBy;
};

export const filterBlogsByQueryParams = (blogs: any, params: any) => {
    blogs.data = formatBlogDate(blogs.data, false);

    if (
        params.date &&
        params.date.toLowerCase() !== getMonthYearFormat(blogs.data.date)
    ) {
        return false;
    }
    if (
        params.tag &&
        !blogs.data.tags
            .map(({ blog_tags }: any) =>
                blog_tags.document.data.name.toLowerCase().replaceAll(" ", "-")
            )
            .includes(params.tag.toLowerCase())
    ) {
        return false;
    }
    if (
        params.search &&
        !blogs.data.title.toLowerCase().includes(params.search.toLowerCase())
    ) {
        return false;
    }
    if (
        params.category &&
        !blogs.data.tags
            .map(({ blog_tags }: any) =>
                blog_tags.document.data.name.toLowerCase().replaceAll(" ", "-")
            )
            .includes(params.category.toLowerCase())
    ) {
        return false;
    }
    return true;
};

export const getUniqueCategories = (blogData: any) => {
    let uniqueCategories = [];
    blogData.allPrismicBlog.nodes.filter((item) => {
        let i = uniqueCategories.findIndex(
            (x) => x.data.category === item.data.category
        );
        if (i <= -1) {
            uniqueCategories.push(item);
        }
        return null;
    });
    return uniqueCategories;
};

export const getUniqueMonths = (blogData: any) => {
    let uniqueMonths = [];
    blogData.allPrismicBlog.nodes.filter((item) => {
        item.data = formatBlogDate(item.data, false);
        const itemDate = item.data.date.substring(item.data.date.indexOf(" ") + 1);
        let i = uniqueMonths.findIndex((x) => x.date === itemDate);
        if (i <= -1) {
            uniqueMonths.push({ date: itemDate, count: 1 });
        } else {
            const tag = uniqueMonths[i];
            tag.count += 1;
        }
        return null;
    });
    return uniqueMonths;
};

export const getUniqueTags = (blogData: any) => {
    let uniqueTags: any[] = [];
    blogData.allPrismicBlog.nodes
        .flatMap((item: any) => item.data.tags)
        .filter(({ blog_tags }: any) => {
            let i = uniqueTags.findIndex(
                (x) => x.tag.replace(" ", "-") === blog_tags.document.data.name.replace(" ", "-")
            );
            if (i <= -1) {
                uniqueTags.push({ tag: blog_tags.document.data.name, count: 1 });
            } else {
                const tag = uniqueTags[i];
                tag.count += 1;
            }

            return null;
        });
    return uniqueTags;
};

export const getLatestRandomPosts = (blogData: any) => {
    var counter = blogData.length, temp, index;

    while (counter--) {
        index = (Math.random() * counter) | 0;
        temp = blogData[counter];
        blogData[counter] = blogData[index];
        blogData[index] = temp;
    }

    return blogData;
}

export const formatBlogDate = (post: any, hasComma: boolean = true) => {
    const publishedDate = Date(post.date);
    post.date = format(publishedDate, (hasComma ? "dd MMMM, yyyy" : "dd MMMM yyyy"));

    if (post.updated_date) {
        const publishedDate = Date(post.updated_date);
        post.date = format(publishedDate, (hasComma ? "dd MMMM, yyyy" : "dd MMMM yyyy"));
    }
    return post;
};
