exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-details-tsx": () => import("./../../../src/pages/account/details.tsx" /* webpackChunkName: "component---src-pages-account-details-tsx" */),
  "component---src-pages-account-email-tsx": () => import("./../../../src/pages/account/email.tsx" /* webpackChunkName: "component---src-pages-account-email-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-password-tsx": () => import("./../../../src/pages/account/password.tsx" /* webpackChunkName: "component---src-pages-account-password-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-tsx": () => import("./../../../src/pages/lab.tsx" /* webpackChunkName: "component---src-pages-lab-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-our-press-tsx": () => import("./../../../src/pages/our-press.tsx" /* webpackChunkName: "component---src-pages-our-press-tsx" */),
  "component---src-pages-password-request-tsx": () => import("./../../../src/pages/password-request.tsx" /* webpackChunkName: "component---src-pages-password-request-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-why-do-it-tsx": () => import("./../../../src/pages/why-do-it.tsx" /* webpackChunkName: "component---src-pages-why-do-it-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-minimal-category-page-tsx": () => import("./../../../src/templates/minimal-category-page.tsx" /* webpackChunkName: "component---src-templates-minimal-category-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

