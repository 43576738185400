import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Search from "./search";

const searchStaticQuery = graphql`
  {
    allPrismicProduct {
      nodes {
        id
        tags
        uid
        data {
          images {
            image {
              gatsbyImageData
            }
          }
          description {
            text
            html
            richText
          }
          title1
          variants {
            available_for_sale
            price1
            sale_price
            vat_rate
          }
          options {
            is_an_international_product
            is_product_available_for_smokers
            show_hospital_location_selection
            is_product_a_package
            is_this_product_a_gift_voucher
            is_product_visible_on_site
          }
          twin_options {
            show_twins_option
            twin_price
          }
          extras {
            title
          }
          method_of_pills {
            title
          }
        }
      }
    }
    allPrismicCategory {
      nodes {
        data {
          title {
            text
          }
          products {
            product {
              document {
                ... on PrismicProduct {
                  id
                  uid
                  tags
                  data {
                    title1
                    description {
                      text
                      html
                      richText
                    }
                    variants {
                      available_for_sale
                      price1
                      sale_price
                      vat_rate
                    }
                    options {
                      is_an_international_product
                      is_product_available_for_smokers
                      show_hospital_location_selection
                      is_product_a_package
                      is_this_product_a_gift_voucher
                      is_product_visible_on_site
                    }
                    images {
                      image {
                        alt
                        copyright
                        url
                        gatsbyImageData
                      }
                    }
                    twin_options {
                      show_twins_option
                      twin_price
                    }
                    extras {
                      title
                    }
                    method_of_pills {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (props: any) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${searchStaticQuery}`}
    render={({ allPrismicProduct, allPrismicCategory }) => {
      return <Search clear={props.clear} allProducts={allPrismicProduct} allCategories={allPrismicCategory} isMobile={true} />;
    }}
  />
);
