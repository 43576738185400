/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Heading, jsx, Text } from "theme-ui";
import { Link } from "gatsby";
import ProductCard from "../product-card/product-card";
import styles from "./product-grid.style";
import searchStyles from "../search/search.style";
import Fuse from "fuse.js";
import { RichText } from "prismic-reactjs";
import Select from "react-select";
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel
  } from "react-accessible-accordion";
  // Demo styles, see 'Styles' section below for some notes on use.
  import "react-accessible-accordion/dist/fancy-example.css";

type PropsType = {
  id?: string;
  type: string;
  close?: Function;
  isCategoryProduct?: boolean;
  gridTitle?: string;
  products: any;
  withLink?: boolean;
  search?: boolean;
  categoryfilter?: boolean;
  description?: any;
  smokersProducts?: boolean;
  showProductPreview?: boolean;
  showFilter?: boolean;
};

const fuseOptions = {
  isCaseSensitive: false,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  useExtendedSearch: false,
  minMatchCharLength: 3,
  shouldSort: true,
  threshold: 0.4, // lower threshold value means more accurate results
  location: 0,
  distance: 100,
  keys: ["item.document.data.title1", "product.document.data.title1"],
};

const ProductGrid: React.FC<PropsType> = ({
  id,
  type,
  close,
  gridTitle,
  products,
  isCategoryProduct = false,
  withLink = true,
  search = false,
  categoryfilter = false,
  description,
  smokersProducts,
  showProductPreview,
  showFilter
}) => {
  const [value, setValue] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const fuse = new Fuse(products, fuseOptions);
  const [filterSelectValue, setFilterSelectValue] = useState("");

  const standardFilterOptions = () => {
    return [
      { value: 'lowToHigh', label: 'Price: Low to High' },
      { value: 'highToLow', label: 'Price: High to Low' }
    ];

  }
  const standardFilters = standardFilterOptions();

  const handleFilterSelectValue = (selected: any) => {
    setFilterSelectValue(selected.value);

    if (selected.value === "lowToHigh") {
      products = products.sort(function (a: any, b: any) {
        return a.product.document.data.variants[0].price1 > b.product.document.data.variants[0].price1 ? 1 : -1;
      })
    }
    if (selected.value === "highToLow") {
      products = products.sort(function (a: any, b: any) {
        return a.product.document.data.variants[0].price1 < b.product.document.data.variants[0].price1 ? 1 : -1;
      })
    }
  };

  const handleClear = () => {
    setValue("");
  };

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    let searchData: any = [];
    const fuseData = fuse.search(value);
    if (fuseData && fuseData.length && value != "") {
      fuseData.forEach((data) => {
        searchData.push(data.item);
      });
      setFilteredProducts(searchData);
    } else {
      setFilteredProducts(searchData);
    }

    if (value === "") {
      setFilteredProducts(products);
    }
  }, [value]);

  return (


    <Box id={id} sx={styles.wrapper}>
      <Box sx={styles.header}>
        {gridTitle && (
          <div sx={styles.header.h2}>
            <Heading
              as="h2"
              className="title-center-bar"
              style={{ textAlign: "center" }}
            >
              {gridTitle}
            </Heading>
          </div>
        )}
        <div className="col-md-12">
          <div className="row">
            {description?.text && (
              <div
                className={search ? "col-md-7" : "col-md-12"}
                sx={styles.productFilter}
              >
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {description?.text?.substring(0, 80)}.. <a className='invert'>read more</a>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p sx={styles.accordionDescription}>{RichText.asText(description.richText)}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
            {search && (
              <div
                className={
                  !categoryfilter
                    ? description !== undefined
                      ? "offset-md-1 col-md-4"
                      : "offset-md-8 col-md-4"
                    : " col-md-4"
                }
              >
                <Box as="label" sx={styles.fieldWrapper} className="search">
                  <svg
                    className="grid-search-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
                      transform="translate(-4 -1.525)"
                      fillRule="evenodd"
                    />
                  </svg>
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Search products..."
                    autoComplete="off"
                  />

                  {value === "" ? (
                    <button sx={styles.searchButton}>Search</button>
                  ) : (
                    <button sx={styles.clearButton} onClick={handleClear}>
                      Clear
                    </button>
                  )}
                </Box>
              </div>
            )}
          </div>
        </div>
      </Box>

      {filteredProducts?.length && showFilter === true ? (
          <div  sx={styles.filterSelect} className="col-md-3" >
            <Select name="filter"
              id="filter"
              classNamePrefix="react-select"
              options={standardFilters}
              onChange={(x) => handleFilterSelectValue(x)}
            /><br/>
          </div>) : (<div></div>)}

      {filteredProducts?.length ? (
        <Grid sx={styles.productGrid}>
          {filteredProducts.map((product: any, index: number) => {
            const productData = isCategoryProduct
              ? product.product.document
              : product;
                            
            const id = productData.id;
            const uid = productData.uid
            const title = productData.data.title1;
            const variants = productData.data.variants;
            const options = productData.data.options;
            const vouchers = productData.data.vouchers;
            const firstImage = productData.data.images[0]?.image;
            const description = productData.data.description;

            const twinOptionsValues = productData.data.twin_options?.length > 0 ? productData.data.twin_options[0] : null;
            const showTwinsOption = twinOptionsValues?.show_twins_option;
            const hasExtras = productData.data.extras?.length > 0 && productData.data.extras[0].title !== null;
            const redirectToProduct = hasExtras || showTwinsOption;

            return (
              <React.Fragment key={`${id}_${index}`}>
                <ProductCard
                  title={title}
                  path={`/product/${uid}`}
                  cmsId={uid}
                  thumbnail={firstImage?.gatsbyImageData}
                  imageSrc={firstImage?.gatsbyImageData.images.fallback.src}
                  variants={variants}
                  withLink={withLink}
                  description={description}
                  options={options}
                  vouchers={vouchers}
                  smokersProduct={smokersProducts}
                  showProductPreview={showProductPreview}
                  redirectToProduct={redirectToProduct}
                />
              </React.Fragment>
            );
          })}
          
        </Grid>
      ) : (
        <Box sx={searchStyles.notFoundCard}>
          <Text as="h2">No {type} products available</Text>
          <Text as="p">
            Can't find the item you're looking for?{" "}
            <Link to="/contact">Get in touch</Link>
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ProductGrid;
