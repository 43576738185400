import React, { Fragment } from 'react';
import { Global } from '@emotion/react';
import { Helmet } from 'react-helmet';
import CartProvider from '../../provider/cart-provider';
import LocalCartProvider from '../../provider/local-cart-provider';
import SearchProvider from '../../provider/search-provider';
import styles from './layout.style';

const Layout: React.FunctionComponent = ({ children }) => {
	return (
			<LocalCartProvider>
				<CartProvider>
					<SearchProvider>
						<React.Fragment>
							<Helmet>
								<meta
									name="viewport"
									content="width=device-width, initial-scale=1 maximum-scale=1"
								/>
							</Helmet>
							<Global styles={styles} />
							{children}
						</React.Fragment>
					</SearchProvider>
				</CartProvider>
			</LocalCartProvider>
	);
};

export default Layout;
