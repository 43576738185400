/** @jsx jsx */
import Drawer from 'rc-drawer';
import { useContext, useRef, useState } from 'react';
import { RiShoppingBag2Line } from 'react-icons/ri';
import { IoIosClose } from 'react-icons/io';
import { Box, Button, Flex, Heading, jsx, Text } from 'theme-ui';
import { formatPrice } from '../../../../../helper/product-helper';
import useOnClickOutside from '../../../../../hooks/useOutsideClick';
import { LocalCartContext } from '../../../../../provider/local-cart-provider';
import Container from '../../../../container/container';
import DrawerCart from '../../../../drawer-cart/drawer-cart';
import Logo from '../../../../logo/logo';
import drawerStyle from '../topbar/topbar.style';
import MainMenu from './main-menu';
import styles from './navbar.style';

const Navbar: React.FC<{
	fluid?: boolean;
	homeLink?: string;
	pathPrefix?: string;
	sticky?: boolean
}> = ({ fluid, homeLink, pathPrefix, sticky }) => {
	const [visible, setVisible] = useState(false);
	const searchRef = useRef(null!);
	const { products, totalPrice } = useContext(LocalCartContext);
	const [cartVisible, setCartVisible] = useState(false);
	useOnClickOutside(searchRef, () => setVisible(false));

	return (
		<Box as="nav" sx={styles.navbar}>
			<Container fluid={fluid} className={visible ? 'activeSearch' : ''}>
				<Logo path={homeLink} nav={true} />
				<MainMenu pathPrefix={pathPrefix} />

				<Box sx={drawerStyle.icons} className="nav-cart">
					<Button
						variant="text"
						aria-label="Cart"
						onClick={() => setCartVisible(true)}
					>
						<RiShoppingBag2Line />
						<Text sx={drawerStyle.badge}>{products.length}</Text>
					</Button>
					<Text sx={drawerStyle.totalPrice} onClick={() => setCartVisible(true)}>{totalPrice !== 0 ? formatPrice(totalPrice) : formatPrice(0)}</Text>
				</Box>

				<Drawer
					level={null}
					width="100%"
					open={visible}
					handler={false}
					placement="left"
					onClose={() => setVisible(false)}
				>
					<Flex sx={drawerStyle.drawerTitle}>
						<Heading as="h2">Categories</Heading>
						<Button
							variant="text"
							onClick={() => setVisible(false)}
							aria-label="Close menu"
						>
							<IoIosClose />
						</Button>
					</Flex>
				</Drawer>
				<DrawerCart
					open={cartVisible}
					onClick={() => setCartVisible(false)}
					onClose={() => setCartVisible(false)}
					products={products}
				/>
			</Container>
		</Box>
	);
};

export default Navbar;
