/** @jsx jsx */
import { useLocation } from "@reach/router";
import { graphql, Link, StaticQuery } from "gatsby";
import get from "lodash/get";
import React from "react";
import { AiTwotoneShop } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import { Box, Button, jsx, Text } from "theme-ui";
import styles from "./footer.styles";
import SocialLinks, {
  SocialLink,
} from "../../../../components/social-links/social-links";
import { nsbp } from "../../../../helper/htmlSerializer";

const getSocialData = (links: any[]): SocialLink[] => {
  const data: SocialLink[] = [];
  links.forEach((link) => {
    data.push({ type: link.social_type, link: link.social_link.url });
  });

  return data;
};

const Footer: React.FC<{}> = () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${staticQuery}`}
    render={(data) => {
      const common = get(data, "allPrismicCommon.nodes[0].data");
      const copyright = common.copyright_text?.text;
      const socialData = getSocialData(common.social_links);
      const contactEmail = common.contact_email?.text;
      const telphoneNumber = common.contact_telephone?.text;
      const openingHours = common.office_open_times?.text;
      const addressee = common.business_address[0]?.addressee;
      const addressLine1 = common.business_address[0]?.line1;
      const addressLine2 = common.business_address[0]?.line2;
      const addressLine3 = common.business_address[0]?.line3;
      const addressLine4 = common.business_address[0]?.line4;
      const postcode = common.business_address[0]?.postcode;
      const location = useLocation();
      var footerMargin = location.pathname.includes("contact") ? "0" : "100px";

      let categories = get(data, "allPrismicCategory.nodes").sort(
        ({ data: a }: any, { data: b }: any) => {
          return a.title.text.localeCompare(b.title.text);
        }
      );

      return (
        <div>
          <Box
            as="footer"
            sx={styles.footer}
            style={{ position: "relative", marginTop: footerMargin }}
          >
            <div className="container">
              <div sx={styles.footer.row} className="row">
                <div sx={styles.footer.section} className="col-md-3">
                  <h2>Our Location</h2>
                  <Text as="p" sx={styles.footer.location}>
                    {addressee}
                  </Text>
                  <Text as="p" sx={styles.footer.location}>
                    {addressLine1}, {addressLine2}
                  </Text>
                  <Text as="p" sx={styles.footer.location}>
                    {addressLine3}
                  </Text>
                  <Text as="p" sx={styles.footer.location}>
                    {addressLine4}
                  </Text>
                  <Text as="p" sx={styles.footer.location}>
                    {postcode}
                  </Text>

                  <hr />

                  <ul sx={styles.footer.location.info}>
                    <li>
                      <IoMailOutline sx={styles.iconWrapper} />
                      <a href={"mailto:" + contactEmail}>{contactEmail}</a>
                    </li>
                    <li>
                      <MdPhone sx={styles.iconWrapper} />
                      <a href={"tel:" + telphoneNumber.replace(/\s+/g, "")}>
                        {telphoneNumber}
                      </a>
                    </li>
                    <li>
                      <AiTwotoneShop sx={styles.iconWrapper} />
                      <Link to="/about-us">{nsbp(openingHours)}</Link>
                    </li>
                  </ul>
                </div>
                <div sx={styles.footer.section} className="col-md-3">
                  <h2>Main Menu</h2>

                  <ul sx={styles.footer.infomation}>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>

                    <li>
                      <Link to={"/about-us"}>About Us</Link>
                    </li>

                    <li>
                      <Link to={"/faq"}>FAQ's</Link>
                    </li>

                    <li>
                      <Link to={"/testimonials"}>Testimonials</Link>
                    </li>

                    <li>
                      <Link to={"/shop"}>Shop</Link>
                    </li>
                  </ul>

                  <ul sx={styles.footer.infomation}>
                    <hr />
                    <li>
                      <Link to={"/terms-and-conditions"}>
                        Terms and Conditions
                      </Link>
                    </li>

                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>

                <div sx={styles.footer.section} className="col-md-3">
                  <h2>Discover</h2>

                  <ul sx={styles.footer.infomation}>
                    <li>
                      <Link to={"/our-press"}>Our Press</Link>
                    </li>

                    <li>
                      <Link to={"/blog"}>Our Blog</Link>
                    </li>

                    <li>
                      <Link to={"/contact"}>Contact Us</Link>
                    </li>
                    <hr />

                    <li>
                      <Link to={"/login"}>My Account</Link>
                    </li>

                    <li>
                      <Link to={"/register"}>Book Now</Link>
                    </li>
                    <li>
                      <Link to={"/password-request"}>Reset Password</Link>
                    </li>
                  </ul>
                </div>
                <div
                  sx={styles.footer.section}
                  className="col-md-3"
                  style={{ borderStyle: "unset" }}
                >
                  <h2>Our Remedies</h2>

                  <ul sx={styles.footer.infomation}>
                    {categories.map((item: any, index: number) => (
                      <li key={index}>
                        <Link to={`/${item.uid}`}>{item.data.title.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Box>
          <div sx={styles.bottomBar}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Text as="p" sx={styles.bottomBar.copyright}>
                    &#xA9; {new Date().getFullYear()} {copyright}
                  </Text>
                </div>
                <div className="col-md-6">
                  <Box sx={styles.socialWrapper}>
                    <SocialLinks items={socialData} />
                  </Box>
                </div>
              </div>
            </div>
          </div>
          <div sx={styles.outible}>
            <p sx={styles.outible.url}>
              Developed by {" "}
              <a href="https://outible.com">
                Web Design Liverpool
              </a>
            </p>
          </div>
        </div>
      );
    }}
  />
);

const staticQuery = graphql`
  {
    allPrismicCommon {
      nodes {
        data {
          copyright_text {
            text
          }
          social_links {
            social_link {
              url
            }
            social_type
          }
          newsletter_header {
            text
          }
          contact_email {
            text
          }
          contact_telephone {
            richText
            text
          }
          office_open_times {
            text
          }
          business_address {
            addressee
            line1
            line2
            line3
            line4
            postcode
          }
        }
      }
    }
    allPrismicCategory {
      nodes {
        data {
          title {
            text
          }
        }
        uid
      }
    }
  }
`;

export default Footer;
