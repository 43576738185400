/**
   * @description Returns the given text with the first letter capitalized
 */
 export const capitalizeFirstLetter = (text: string) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}

/**
 * @description Checks the given string is within the specified length of characters
*/
export const checkMinimumLength = (value: string | undefined, length: number): boolean => {
  return value !== undefined ? value?.length >= length : false;
}

/**
 * @description Checks the given string is within the specified length of characters
*/
export const checkMaximumLength = (val: string | undefined, length: number): boolean => {
  return val !== undefined ? val?.length <= length : false;
}

/**
 * @description Returns a dash seperated string
*/
export const dashSeperated = (text: string): string => {
return text.replace(/\s+/g, '-').toLowerCase();
}