const styles = {
	wrapper: {
		backgroundSize: "cover!important",
		backgroundRepeat: 'no-repeat!important',
		backgroundPosition: 'center center!important',
	},
	header: {
		'@media only screen and (max-width: 530px)': {
			fontSize: '31px!important',
			textAlign: 'unset!important'
		},
	},
	bannerContent: {
		zIndex: 1,
		fontSize: '20px',
		color: '#685f52',
		position: 'relative',
		h1: {
			textTransform: 'capitalize',
			fontSize: '40px',
			fontWeight: '900',
			marginBottom: '5px',
			lineHeight: 1.1,
			position: 'relative',
			color: '#685f52',
			textAlign: 'center'
		},
		padding: '40px 30px 40px 30px',
		// '@media only screen and (max-width: 767px)': {
		// 	padding: '40px 30px 40px 30px',
        // },
	},
	breadcrumbStyles: {
		breadcrumbs: {
			'.breadcrumb-item a': {
				textDecoration: 'none',
				fontSize: '15px',
				color: '#685f52',
				fontWeight: 500,
			},
			'.breadcrumb-item+.breadcrumb-item::before':
			{
				color: '#685f52',
				fontSize: '15px',
				lineHeight: '33px'
			},
			'.disabled': {
				color: '#656766!important',
				fontWeight: 'unset!important'
			}
		}
	}
};

export default styles;
