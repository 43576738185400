/** @jsx jsx */
import { useContext, useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { Box, jsx } from 'theme-ui';
import useFreezeBodyScroll from '../../../../hooks/useFreezeBodyScroll';
import { SearchContext } from '../../../../provider/search-provider';
import styles from './header.style';
import Navbar from './navbar/navbar';
import Topbar from './topbar/topbar';

const Header: React.FC<{
	fluid?: boolean;
	homeLink?: string;
	showNoticeBar?: boolean;
	pathPrefix?: string;
}> = ({ fluid, homeLink, pathPrefix, showNoticeBar }) => {
	const [notice, setNotice] = useState(false);
	const { isSearched } = useContext<any>(SearchContext);
	useFreezeBodyScroll(isSearched);
	const addClass = [];
	if (isSearched) {
		addClass.push('isSearchedActive');
	}
	return (
		<Box as="header" sx={styles.header}>
			<Box sx={styles.showOnlyDesktop} className={addClass.join(' ')}>
				<Topbar fluid={fluid} homeLink={homeLink} />
			</Box>
			<Box sx={styles.showOnlyTab}>
				<Sticky activeClass="active" innerZ={9}>
					<Topbar fluid={fluid} homeLink={homeLink} />
				</Sticky>
			</Box>
			<Box sx={styles.showOnlyDesktop}>
				<Sticky activeClass="active" innerZ={9}>
					<Navbar fluid={fluid} homeLink={homeLink} pathPrefix={pathPrefix} />
				</Sticky>
			</Box>
		</Box>
	);
};

export default Header;
