/** @jsx jsx */
import { Box, jsx } from 'theme-ui';

const Wrapper = ({ ...props }) => {
	return (
		<Box
			sx={{
				width: '100%',
				marginLeft: 'auto',
				marginRight: 'auto'				
			}}
			{...props}
		/>
	)
}

export default Wrapper;
