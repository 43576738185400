/** @jsx jsx */
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import React, { Fragment, useContext, useState } from 'react';
import { RiShoppingBag2Line } from 'react-icons/ri';
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import { Box, Button, Flex, Grid, jsx, Text } from 'theme-ui';
import { formatPrice } from '../../../../../helper/product-helper';
import { LocalCartContext } from '../../../../../provider/local-cart-provider';
import DrawerCart from '../../../../drawer-cart/drawer-cart';
import Logo from '../../../../logo/logo';
import Search from '../../../../search';
import MainMenu from '../navbar/main-menu';
import TopMenu from '../navbar/top-menu';
import styles from './topbar.style';

const Topbar: React.FC<{ fluid?: boolean; homeLink?: string }> = ({
	homeLink,
}) => {
	const [visible, setVisible] = useState(false);
	const { products, totalPrice } = useContext(LocalCartContext);
	const [cartVisible, setCartVisible] = useState(false);
	const [clearSearch, setClearSearch] = useState(false);

	const handleClose = () => {
		setClearSearch(true);
		setVisible(false);
	};

	const handleOnClick = (visible: boolean) => {
		setCartVisible(visible)
	};

	return (
		<React.Fragment>
			<TopMenu />
			<Box sx={styles.topbar}>
				<div sx={styles.container} className="container">
					<Grid className="grid" sx={styles.grid}>
						<Flex>
							<Button
								variant="text"
								sx={styles.hamburgBtn}
								onClick={() => setVisible(true)}
								aria-label="Hamburg menu"
							>
								<IoIosMenu />
							</Button>

							<Box sx={styles.search}>
								<Search />
							</Box>
						</Flex>

						<Logo path={homeLink} />

						<Box sx={styles.icons} onClick={() => setCartVisible(true)}>
							<Button
								variant="text"
								aria-label="Cart"
								onClick={() => setCartVisible(true)}
							>
								<RiShoppingBag2Line />
								<Text sx={styles.badge}>{products.length}</Text>
							</Button>
							<Text sx={styles.totalPrice} onClick={() => setCartVisible(true)}>{totalPrice !== 0 ? formatPrice(totalPrice) : formatPrice(0)}</Text>
						</Box>
					</Grid>
				</div>

				<Drawer
					level={null}
					width="100%"
					open={visible}
					handler={false}
					placement="left"
				>
					<Box sx={styles.drawerTitle}>
						<Button sx={styles.drawerTitle.close}
							variant="text"
							onClick={() => handleClose()}
							aria-label="Close menu"
						>
							<IoIosClose />
						</Button>
						<div>
							<Logo path={homeLink} />
							<hr className="bar-indent-center" />
							<Search clear={clearSearch} />
						</div>
					<MainMenu onClick={() => setVisible(false)} showAccountControl={true} />
					</Box>

				</Drawer>

				<DrawerCart
					open={cartVisible}
					onClick={() => handleOnClick(false)}
					onClose={() => setCartVisible(false)}
					products={products}
				/>
			</Box>
		</React.Fragment>
	);
}

export default Topbar;
