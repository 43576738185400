import { isValidPostcode } from './address-helper'

export const getPostcodeTimeSlot = (input: string, postcodeTimeSlots: any) => {
    const postcode = input.replace(' ', '').toUpperCase();
    return postcodeTimeSlots.find((x: any) => (postcode.startsWith(x?.postcode.toUpperCase())));;
}

const checkDeliveryToPostcode = (input: string, postcodeTimeSlots: any) => {    
    const postcode = input.toUpperCase();
    return postcodeTimeSlots.find((x: any) => (postcode.split(" ")[0] === x?.postcode.toUpperCase())) != null;
}

export const checkDeliverToPostcode = (input: string, postcodeTimeSlots: any) => {
    var validPostcode = isValidPostcode(input.trimEnd());
    var validDeliverySlot = checkDeliveryToPostcode(input, postcodeTimeSlots);
    
    return validPostcode && validDeliverySlot;
}

 // users can not have products in basket without a package product the exception being gift cards
export const validateBasket = (products: any) : boolean => {
    const packagesInBasket = products.filter(x => x.isPackageProduct).length > 0;
    const giftVoucherInBasket = products.filter(x => x.isGiftVoucher || x.isCustomGiftVoucher).length > 0;
    const standardProductInBasket = products.filter(x => !x.isCustomGiftVoucher && !x.isGiftVoucher && !x.isPackageProduct).length > 0;

    let valid = true;

    if (products.length > 0) {
        if (!packagesInBasket) {
            if (standardProductInBasket && giftVoucherInBasket) {
                valid = false;
            }
            else if (giftVoucherInBasket || giftVoucherInBasket) {
                valid = true;
            }
            else {
                valid = false;
            }
        }
    }
    else {
        valid = true;
    }
    return valid;
}

export const buyingGiftVouchersOnly = (products: any) : boolean => {
    const packagesInBasket = products.filter(x => x.isPackageProduct).length > 0;
    const giftVoucherInBasket = products.filter(x => x.isGiftVoucher || x.isCustomGiftVoucher).length > 0;
    const standardProductInBasket = products.filter(x => !x.isCustomGiftVoucher && !x.isGiftVoucher && !x.isPackageProduct).length > 0;

    return giftVoucherInBasket && !packagesInBasket && !standardProductInBasket;
}