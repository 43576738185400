/** @jsx jsx */
import { Link, navigate } from 'gatsby';
import { useContext, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { Box, Button, Flex, Heading, jsx, Text } from 'theme-ui';
import { formatPrice } from '../../helper/product-helper';
import { LocalCartContext } from '../../provider/local-cart-provider';
import styles from './cart.style';
import PackageModal from '../../components/modal/package-modal';
import { validateBasket, buyingGiftVouchersOnly } from "../../helper/checkout-helper";
import { removeLocalStateItem } from '../../helper/local-storage';

const Cart: React.FunctionComponent<{ onClick?: () => void }> = ({
	onClick,
	children
}: any) => {
	const { products, totalPrice, totalVatPrice } = useContext(
		LocalCartContext
	);
	const [showModal, setShowModal] = useState(false);

	const handleCheckoutButton = () => {
		const valid = validateBasket(products);
		setShowModal(!valid);
		if (!valid) {
			onClick(false)
		}

		if (valid) {
			if (typeof window !== `undefined`
				&& window.location.pathname.includes('checkout')) {
				onClick(false)
			}
			else {
				const giftVoucherInBasketOnly = buyingGiftVouchersOnly(products);

				if (giftVoucherInBasketOnly) {
					navigate("/checkout");
				}
				else {
					removeLocalStateItem("smoker");
					removeLocalStateItem("medical");
					navigate("/checkout?medical");
				}
			}
		}
	}

	return (
		<Box sx={styles.wrapper}>
			<Heading as="h3" sx={styles.title}>
				Shopping Basket
				<Button title="Close" variant="text" onClick={onClick}>
					<IoIosClose />
				</Button>
			</Heading>
			<hr />
			<Box sx={styles.cartItems}>{children}</Box>

			<Flex sx={styles.totalPrice}>
				<Box>
					<Text>Total Amount:</Text>
					<Heading as="h4">{formatPrice(totalPrice)}</Heading>
				</Box>
				<Box sx={styles.totalVat}>
					<Text>VAT (Included):</Text>
					<Box as="p">{formatPrice(totalVatPrice)}</Box>
				</Box>
			</Flex>
			<hr style={{ height: '.5px' }} />

			<Box sx={styles.buttonStyle}>
				{products.length === 0 ? (
					<Button onClick={onClick} sx={styles.checkout.button}>
						Back to shop
					</Button>
				) : (
					<span>
						<Button sx={styles.checkout.button}
							onClick={() => handleCheckoutButton()}>
							Checkout
						</Button>
						<Link to="/basket" sx={styles.addToCart}>
							<Button sx={styles.addToCart.button}>
								View Basket
							</Button>
						</Link>
					</span>
				)}
			</Box>
			<PackageModal showModal={showModal} setShowModal={setShowModal} showCloseButton={true} />
		</Box>
	);
};

export default Cart;
