/** @jsx jsx */
import { jsx } from "theme-ui";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";

const LocationInput = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query: any) => {
    setIsLoading(true);
    if (props.addresses.data != undefined && props.addresses.data.length > 0) {
      const data = props.addresses.data.map((hospital: any) => {
        var addressLines = [
          hospital.address.addressLine1,
          hospital.address.addressLine2,
          hospital.address.addressLine3,
          hospital.address.county,
          hospital.address.city,
          hospital.address.postalCode,
        ].filter((line) => !!line);
        const addressDisplayName = addressLines.join(", ");
        return { name: addressDisplayName, postcode: hospital.id, charge: hospital.deliveryCharge.price + hospital.deliveryCharge.vat };
      });

      setOptions(data);
      setIsLoading(false);
    }
  };

  return (
    <section id="location-selection">
      <div className="location-input-container">
        <div className="justify-content-around align-items-center">
          <AsyncTypeahead
            id="courier-price"
            isLoading={isLoading}
            labelKey="name"
            minLength={2}
            onSearch={handleSearch}
            options={options}
            placeholder="Enter Postcode or Location of Hospital"
            onChange={(selected) => {
              props.onChange(selected, props.formik);
            }}
            defaultInputValue={props.defaultInputValue}
          />
          <br />
        </div>
      </div>
    </section>
  );
};
export default LocationInput;
