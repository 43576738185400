/** @jsx jsx */
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Box, Heading, jsx, Text } from "theme-ui";
import Breadcrumbs, { Breadcrumb } from "../breadcrumbs/breadcrumbs";
import styles from "./breadcrumb-banner.style";

const staticQuery = graphql`
  {
    allPrismicCommon {
      nodes {
        data {
          breadcrumb_banner {
            image {
              url
            }
          }
        }
      }
    }
  }
`;

type PropsType = {
  title?: string;
  subTitle?: string;
  breadcrumbs?: Breadcrumb[];
  bgImage?: any;
};

const BreadcrumbBanner: React.FC<PropsType> = ({
  title,
  subTitle,
  breadcrumbs,
  bgImage,
}: any) => {
  return (
    <StaticQuery<GatsbyTypes.Query>
      query={`${staticQuery}`}  
      render={({ allPrismicCommon }) => {
        const node = allPrismicCommon.nodes[0].data;
        const image = node?.breadcrumb_banner[0].image.url;

        return (
          <Box
            as="section"
            sx={styles.wrapper}
            style={{
              background: `url(${image !== undefined ? image : bgImage})`,
            }}
          >
            <div className="container">
              <Box as="header" sx={styles.bannerContent}>
                <Heading sx={styles.header} as="h1">{title.replace(/-/g, " ")}</Heading>
                {subTitle && <Text as="p">{subTitle}</Text>}
                {breadcrumbs && (
                  <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    styles={styles.breadcrumbStyles}
                  />
                )}
              </Box>
            </div>
          </Box>
        );
      }}
    />
  );
};

export default BreadcrumbBanner;
