import axios from "axios";
import { setJwtTokens } from '../services/auth-service'

/**
   * @description Refresh token cookies
   * @returns {String} JwtToken and random generated refresh token
 */
export const Refresh = async (data: any) => {
    return await axios.post(`token/refresh/`, data).then(function (response) {
        setJwtTokens('token', response.data.token, 30);
        setJwtTokens('refresh-token', response.data.refreshToken, 30);
        return response;
    })
}